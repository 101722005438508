import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import Storage from "@/app/core/util/Storage";
import { CnpjMask } from "@/app/core/util/helpers/ContactHelper";
import React, { useEffect, useState } from "react";
import CompanyOrgansModal from "./CompanyOrgansModal";
import { GeneralHelper } from "@/app/core/util/helpers/GeneralHelper";

export default function RegistrationStatusCard() {
  const [userData, setUserData] = useState(Storage.getUserData());
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [done, setDone] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    if (userData && userData.registrationStatus == true) {
      loadData();
    }
  }, []);

  function loadData() {
    CompanyController.ListOperator((res) => setData(res.filter(x => x.company !== undefined)));
    CompanyController.ListDoneOperator((res) => setDone(res.filter(x => x.company !== undefined)));
  }

  function openCompany(item) {
    setCompanyId(item.id);
    setShowModal(true);
  }

  return (
    <>
      {userData &&
      userData.registrationStatus == true &&
      GeneralHelper.IsDevelopment() ? (
        <div className="block block-rounded block-transparent bg-light-25">
          <div className="block-header">
            <div className="block-title">
              {data && data.length} Consulta de situação cadastral
            </div>
            <div className="block-tools">
              <button
                className="btn btn-sm btn-dual"
                onClick={() => loadData()}
              >
                <i className="si si-refresh"></i>
              </button>
              <button
                className="btn btn-sm btn-dual"
                onClick={() => setOpen(!open)}
              >
                <i
                  className={`si si-arrow-${open === true ? "up" : "down"}`}
                ></i>
              </button>
            </div>
          </div>
          {open ? (
            <div className="block-content">
              <span className="font-w700">Pendentes</span>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Cód. de cliente</th>
                    <th>Cliente</th>
                    <th>Empresa</th>
                    <th>CNPJ</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item) => (
                      <tr>
                        <td>{item.customerCode}</td>
                        <td>{item.name}</td>
                        <td>{item.company}</td>
                        <td>{CnpjMask(item.doc)}</td>
                        <td>
                          <button
                            className="btn btn-dual"
                            onClick={() => openCompany(item)}
                          >
                            <i className="fa fa-eye" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <span className="font-w700 mt-2">Concluídos</span>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Cód. de cliente</th>
                    <th>Cliente</th>
                    <th>Empresa</th>
                    <th>CNPJ</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {done &&
                    done.map((item) => (
                      <tr>
                        <td>{item.customerCode}</td>
                        <td>{item.name}</td>
                        <td>{item.company}</td>
                        <td>{CnpjMask(item.doc)}</td>
                        <td>
                          <button
                            className="btn btn-dual"
                            onClick={() => openCompany(item)}
                          >
                            <i className="fa fa-eye" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <CompanyOrgansModal
        show={showModal}
        onHide={() => setShowModal(false) | setCompanyId(null) | loadData()}
        companyId={companyId}
      />
    </>
  );
}
