import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function ServiceChargeController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `charge/insert`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `charge/update`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Delete(chargeId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `charge/delete/${chargeId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Get(chargeId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `charge/${chargeId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function GetByKey(chargeKey, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `charge/by-key/${chargeKey}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function ListByCustomer(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `charge/customer/${customerId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function ListByDate(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `charge/bydate?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Closure(startDate, endDate, reference, dueDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `charge/closure?startDate=${startDate}&endDate=${endDate}&reference=${reference}&dueDate=${dueDate}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function ClosureByCustomer(customerId, dueDate, description, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `charge/closure/customer/${customerId}?dueDate=${dueDate}&description=${description}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function CustomPayment(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `charge/custom-payment`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function ClosureByCustomerByDate(customerId, startDate, endDate, dueDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `charge/closure/customer/dates/${customerId}?startDate=${startDate}&endDate=${endDate}&dueDate=${dueDate}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Resume(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `charge/resume`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function ChargeInsertService(chargeId, serviceId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `charge/insert/service/${chargeId}/${serviceId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function ChargeDeleteService(chargeServiceId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `charge/delete/service/${chargeServiceId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  return {
    Insert,
    Update,
    Delete,
    Get,
    ListByCustomer,
    ChargeInsertService,
    ChargeDeleteService,
    ListByDate,
    Closure,
    Resume,
    ClosureByCustomer,
    ClosureByCustomerByDate,
    CustomPayment,
    GetByKey,
  };
}
