import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { AddOnlyDate, OnlyDateTimeNow } from "@/app/core/util/DateTranslater";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function PayAllModal({ show, onHide, total, customerId }) {
    const [data, setData] = useState({});
    const [price, setPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const financialHelper = FinancialHelper();
    const cashFlow = CashFlowController();

    useEffect(() => {
        if (show) {
            let base = {};
            base.dueDate = OnlyDateTimeNow();
            base.price = total;
            base.description = "Pagamento Fatura Hasa";
            base.customerId = parseInt(customerId);

            setData(base);
            setPrice(total)
        } else {
            setData({});
        }
    }, [show]);

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    async function save() {
        setLoading(true);
        if (window.confirm("Deseja realmente gerar o boleto para quitar o saldo?")) {
            let newData = data;
            newData.price = Math.abs(data.price);
            await ServiceChargeController().CustomPayment(newData, res => {
                console.log("Boleto", res);
                toast.success("Boleto gerado com sucesso, você pode acessar a guia de Faturas ou conferir no seu email");
                onHide()
            })
        }
        setLoading(false);
    }

    function handleChange(evt) {
        setData(OnDataChange(data, evt))
    }

    return (
        <Modal
            title="Antecipar fatura"
            show={show}
            size={"md"}
            isLoading={false}
            onHide={onHide}
            button="Gerar Boleto"
            onSave={() => save()}
            onSubmit={() => save()}
        >
            <div className="form-group text-center">
                <span>Saldo em aberto</span>
                <h3>{MoneyMask(total, 2)}</h3>
                {/* <h5>Maio de 2024</h5> */}
            </div>

            <div className="">
                <h3 className="content-heading">Sobre a transação</h3>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th style={{ width: "50%" }}></th>
                            <th style={{ width: "50%" }}></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Pagar Quando</td>
                        <td>
                            <input type="date" className="form-control" value={data && data.dueDate} min={OnlyDateTimeNow()} max={AddOnlyDate(30)} name="dueDate" onChange={evt => handleChange(evt)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Valor total</td>
                        <td className="row">
                            {edit == false ?
                                <span className="col-8 py-2">
                                    {data && MoneyMask(data.price, 2)}
                                </span>
                                : <input value={Math.abs(data.price)} className="form-control col-8" type="number" max={Math.abs(data.price)} min={100} onChange={evt => setData({ ...data, price: evt.target.value })} />}
                            <button className="btn btn-dual text-warning col-4" onClick={() => setEdit(!edit)}><i className={edit == false ? "far fa-edit" : "si si-check"} /></button>
                        </td>
                    </tr>
                    {/* <tr>
                        <td>
                            <div className="form-group">
                                <span>Descrição</span>
                                {edit == false ? <div><br />{data && data.description}</div> : <input type="text" className="form-control" placeholder="Descrição" value={data.description} name="description" onChange={evt => handleChange(evt)} />}
                            </div>
                        </td>
                        <td>
                        </td>
                    </tr> */}
                </table>
            </div>
        </Modal>
    );
}
