import React from "react";

export default function CustomerHelper() {
  const StatusList = [
    { name: "Não definido", value: 0, color: "secondary" },
    { name: "Pendente", value: 1, color: "primary" },
    { name: "Pendente Hasa", value: 2, color: "warning" },
    { name: "Ativo", value: 3, color: "success" },
    { name: "Bloqueado (Financeiro)", value: 4, color: "danger" },
    { name: "Ativo (Política Bloqueada)", value: 5, color: "success" },
    { name: "Bloqueado (Relacionamento)", value: 6, color: "danger" },
  ];

  function PlanName (data){
    if(data && data.plan && data.plan.name.includes("Basic")){
      return <span className="text-muted font-siza-sm"> Hasa Pro <i className="fa fa-user-tie text-danger"/> {data.plan.name}</span>
    } else if(data && data.plan && data.plan.name.includes("Exclusive")){
      return <span className="text-muted font-siza-sm"> Hasa Pro <i className="si si-rocket text-danger"/> {data.plan.name}</span>;
    }else if(data && data.plan && data.plan.name.includes("Elite")){
      return <span className="text-muted font-siza-sm"> Hasa Pro <i className="si si-diamond text-danger"/> {data.plan.name}</span>
    }
  }

  function ShowFinancialStatus(i) {
    if (i === 2) {
      return <span className="badge badge-danger ml-1 mr-1">Serasa</span>;
    }
  }

  const Status = {
    Undefined: 0,
    Pending: 1,
    PendingHasa: 2,
    Active: 3,
    Blocked: 4,
    ActivePolitcBlocked: 5,
    BlockedRelacionamento: 6,
  };

  const ReportType = {
    total: 0,
    actives: 1,
    inactives: 2,
    blocked: 3,
    oportunities: 4,
    conversions: 5,
    partners: 6,
    idle: 7,
    pending: 8,
    oportunitiesRessurgence: 9,
  };

  const Type = {
    Undefined: 0,
    Physical: 1,
    LegalPerson: 2,
  };

  const Stage = {
    Lead: 0,
    Qualificado: 1,
    Convertido: 2,
    Perdido: 3,
    Reativado: 4,
  };

  const StageList = [
    { name: "Retenção", value: 0 },
    { name: "Compra inicial", value: 1 },
    { name: "Faturamento", value: 2 },
    { name: "Política de desconto", value: 3 },
    { name: "Reativados", value: 4 },
  ];

  const StageNameList = [
    { name: "Lead", value: 0 },
    { name: "Qualificado", value: 1 },
    { name: "Convertido", value: 2 },
    { name: "Perdido", value: 3 },
  ];

  function StageTranslate(i) {
    let res = StageList.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  function StageNameTranslate(i) {
    let res = StageNameList.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  function StatusTranslate(i) {
    let res = StatusList.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  function ShowStatus(i) {
    if (i === 0) {
      return <span class="badge badge-secondary ml-1 mr-1">Indefinido</span>;
    }
    if (i === 1) {
      return <span class="badge badge-info ml-1 mr-1">Pendente</span>;
    }
    if (i === 2) {
      return <span class="badge badge-warning ml-1 mr-1">Pendente Hasa</span>;
    }
    if (i === 3) {
      return <span class="badge badge-success ml-1 mr-1">Ativo</span>;
    }
    if (i === 4) {
      return <span class="badge badge-danger ml-1 mr-1">Bloqueado (Financeiro)</span>;
    }
    if (i === 5) {
      return <span class="badge badge-success ml-1 mr-1">Ativo (Política Bloqueada)</span>;
    }
    if (i === 6) {
      return <span class="badge badge-danger ml-1 mr-1">Bloqueado (Relacionamento)</span>;
    }
  }

  function ShowLevel(i, className, text) {
    if (i === 0) {
      return <span></span>;
    } else if (i === 1) {
      return (
        <span className={text}>
          <i
            title="VIP"
            className={"fa fa-handshake fa-2x text-info mr-1 ml-1 " + className}
          />{" "}
          VIP{" "}
        </span>
      );
    } else if (i === 2) {
      return (
        <span className={text}>
          <i
            title="Exclusive"
            className={"si si-rocket fa-2x text-danger mr-1 ml-1 " + className}
          />{" "}
          Exclusive
        </span>
      );
    } else if (i === 4) {
      return (
        <span className={text}>
          <i
            title="Basic"
            className={
              "fa fa-user-tie fa-2x text-danger mr-1 ml-1 " + className
            }
          />{" "}
          Basic
        </span>
      );
    } else if (i === 3) {
      return (
        <span className={text}>
          <i
            title="Elite"
            className={"si si-diamond fa-2x text-danger mr-1 ml-1 " + className}
          />{" "}
          Elite
        </span>
      );
    }
  }

  function ShowSupervisorLevel(i, className, text) {
    if (i === 1) {
      return (
        <span className={text}>
          <i
            title="VIP"
            className={"fa fa-handshake text-info mr-1 ml-1 " + className}
          />{" "}
          VIP{" "}
        </span>
      );
    } else if (i === 2) {
      return (
        <span className={text}>
          <i
            title="Exclusive"
            className={"si si-rocket text-danger mr-1 ml-1 " + className}
          />{" "}
          Exclusive
        </span>
      );
    } 
    // else if (i === 4) {
    //   return (
    //     <span className={text}>
    //       <i
    //         title="Basic"
    //         className={
    //           "fa fa-user-tie text-danger mr-1 ml-1 " + className
    //         }
    //       />{" "}
    //       Basic
    //     </span>
    //   );
    // } 
    else if (i === 3) {
      return (
        <span className={text}>
          <i
            title="Elite"
            className={"si si-diamond text-danger mr-1 ml-1 " + className}
          />{" "}
          Elite
        </span>
      );
    }else{
      console.log("Não achou", i);
      return (
        <span className={text}>
          <i
            title="Basic"
            className={
              "fa fa-user-tie text-danger mr-1 ml-1 " + className
            }
          />{" "}
          Basic
        </span>
      );
    }
  }

  const CustomerLevel = {
    Normal: 0,
    Vip: 1,
    Exclusive: 2,
  };

  function CustomerLevelTranslate(i) {
    let res = CustomerLevelList.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "Normal";
    }
  }

  const CustomerLevelList = [
    { name: "Normal", value: 0 },
    { name: "Vip", value: 1 },
    { name: "Exclusive", value: 2 },
  ];

  const CompanyStatus = {
    NotConsulted: 0,
    Consulting: 1,
    Done: 2,
  };

  const SocietyType = {
    Undefined: 0,
    SociedadeLimitada: 1,
    SociedadeUnipessoal: 2,
    EmpresarioIndividual: 3,
  };

  const PartnerTypeChange = {
    EntradaESaida: 0,
    ApenasEntrada: 1,
    ApenasSaida: 2,
    Transfer: 3,
  };

  const AdministationType = {
    Undefined: 0,
    IsoladamenteEEmConjunto: 1,
    ApenasIsoladamente: 2,
    ApenasEmConjunto: 3,
  };

  const TaxCode = {
    Undefined: 0,
    ME: 1,
    EPP: 2,
    Normal: 3,
  };

  const ProComponents = {
    Regularity: 0,
    Reminder: 1,
    Elaborator: 2,
    Tasks: 3,
    Manager: 4,
  };
  const ProComponentList = [
    {name: "Regularidade", value:0},
    {name: "Lembretes", value:1},
    {name: "Elaborador", value:2},
    {name: "Tarefas", value:3},
    {name: "Gerente de conta", value:4},
  ];
  
    const FrequencyType = {
      Monthly: 0,
      Custom: 1,
    };
  

    function CheckAvailability(type, data){
      let response = true;
      if(!data.plan){
        response = false;
      }else{
        if(type != undefined && data && data.plan && data.plan.items){
          let exist = data.plan.items.filter(x => x.component != undefined && x.component.toString() == type.toString());
          
          if(exist.length > 0){
            response = true
          }else{
            response = false
          }
        }
      }
  
      return response;
    }

    function GetAvatar(customer){
      if (customer && customer.avatarImages && customer.avatarImages.length > 0) {
        return customer.avatarImages[0].url;
      }else{
        return "";
      }
    }
  

  return {
    StatusList,
    Status,
    StatusTranslate,
    ShowSupervisorLevel,
    Type,
    ShowStatus,
    ShowLevel,
    CustomerLevel,
    CustomerLevelTranslate,
    CustomerLevelList,
    ReportType,
    CompanyStatus,
    Stage,
    StageList,
    StageTranslate,
    StageNameList,
    StageNameTranslate,
    SocietyType,
    ShowFinancialStatus,
    AdministationType,
    TaxCode,
    ProComponents,
    ProComponentList,
    FrequencyType,
    PlanName,
    CheckAvailability,
    GetAvatar,
    PartnerTypeChange
  };
}
