import RemunerationController from "@/app/core/base/controllers/RemunerationController";
import Functions from "@/app/core/util/Functions";
import RemunerationHelper from "@/app/core/util/helpers/RemunerationHelper";
import React, { useEffect, useState } from "react";
import RemunerationModal from "./RemunerationModal";
import Table from "@/app/core/components/table/";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import { OrganStatusTranslate } from "@/app/core/util/helpers/OrganListHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { TemplateModelController } from "@/app/core/base/controllers/TemplateModelController";
import TemplateModelModal from "./TemplateModelModal";

export default function TemplateModelCard({ serviceOrganId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const remunerationCols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Nensagem", isSortable: true, name: "message" },
    { header: "Atualizado em", isSortable: true, name: "updatedDate" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  useEffect(() => {
    loadtemplateModels();
  }, [serviceOrganId]);

  async function loadtemplateModels() {
    setLoading(true);
    await TemplateModelController.List((res) => {
      for (let s in res) {
        res[s].createdDate = DateTranslate(res[s].createdDate);
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
      }
      setData(res);
    });
    setLoading(false);
  }

  function viewAction(item) {
    setSelected(item.data);
    setShowModal(true);
  }

  async function removeRemuneration(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await TemplateModelController.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadtemplateModels();
      });
    }
  }

  return (
    <div className="block block-rounded block-bordered">
      <div className="block-header">
        <div className="block-title">Templates de emails</div>
        <div className="block-tools">
          <button
            className="btn btn-dual btn-sm"
            onClick={() => setShowModal(true)}
          >
            Adicionar
          </button>
        </div>
      </div>
      <div className="block-content">
        <Table
          title={" Templates"}
          loading={loading}
          data={data}
          columns={remunerationCols}
          refreshData={() => loadtemplateModels()}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
          viewAction={(data) => viewAction(data)}
          deleteAction={(item) => removeRemuneration(item.data)}
          hideHeader={true}
        />
      </div>
      <TemplateModelModal
        infos={selected}
        show={showModal}
        onHide={() =>
          setShowModal(false) | setSelected({}) | loadtemplateModels()
        }
      />
    </div>
  );
}
