import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import NotesItem from "@/app/core/layouts/main-components/notes/NotesItem";
import { toast } from "react-toastify";
import DropdownElement from "./elements/DropdownElement";
import { Autocomplete } from "../../util/helpers/AutocompleteHelper";
import {
  NoteInsert,
  ProcessNotes,
} from "../../base/controllers/ProcessNotesController";
import { ProcessDates } from "../../util/DateTranslater";
import DocumentsController from "../../base/controllers/DocumentsController";
import DocInput from "./documents/DocInput";
import { Message } from "../../util/messages/GeralMessages";
import OrganListController from "../../base/controllers/OrganListController";

export default function ProcessNotesComponent({
  refresh,
  processId,
  disabled,
  open,
  organListId,
  search,
  notifyStart,
}) {
  const [data, setData] = useState([]);
  const [talk, setTalk] = useState(null);
  const [filter, setFilter] = useState([]);
  const [question, setQuestion] = useState("");
  const [disable, setDisable] = useState(false);
  const [file, setFile] = useState(null);
  const docsCtrl = DocumentsController();
  const organCtrl = OrganListController();
  const userData = Storage.getUserData();

  useEffect(() => {
    loadNotes();
    if (disabled === undefined || disabled === null) {
      setDisable(false);
    } else {
      setDisable(disabled);
    }
  }, [processId, refresh, disabled]);

  function loadNotes() {
    if (processId !== undefined && processId !== null) {
      ProcessNotes(processId, (res) => {
        for (let s in res) {
          res[s].time = new Date(res[s].createdDate).getTime();
        }
        res.sort(function (a, b) {
          return b.time - a.time;
        });

        setData(ProcessDates(res));
      });
    }
  }

  function clearFields() {
    setTalk("");
  }

  async function saveUpdate() {
    if (file === null) {
      let note = {
        observation: talk,
        processId: processId,
        userId: userData.id,
      };

      NoteInsert(note, (res) => {
        toast.info(Message.Saved);
        setTalk("");
        clearFields();
        loadNotes();
      });
    } else {
      var formData = new FormData();
      formData.append("file", file);
      let id = organListId === undefined ? null : organListId;
      await docsCtrl.UploadDocs(processId, formData, userData.id, id, (res) => {
        let note = {
          observation: talk,
          processId: processId,
          fileId: res[0].id,
          id,
        };

        NoteInsert(note, (response) => {
          setFile(null);
          toast.info(Message.Saved);
          setTalk("");
          clearFields();
          loadNotes();
        });
      });
    }

    if ((userData.role > 0 || search === true) && organListId !== undefined) {
      await organCtrl.InternalFeedback(organListId, true, (res) => {});
    }
    if (notifyStart) {
      notifyStart()
    }
  }

  function handleFilterChange(value) {
    setQuestion(value);
    let timeline_to_show = [];

    if (value.length > 0) {
      setFilter([]);
      timeline_to_show = Autocomplete(value, data, "observation");
      setFilter(timeline_to_show);
    } else {
      timeline_to_show = [];
      setFilter([]);
    }
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="block-header">
          <div className="block-title">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i> Observações
            </h2>
          </div>
          <div className="block-tools">
            <DropdownElement
              submit={() => saveUpdate()}
              icon=""
              style={{ width: "100%", height: 280 }}
              title={"Escreva a sua observação :"}
              disabled={disable}
              button="Salvar"
            >
              <div className="form-group">
                <span>Escreva a sua observação : </span>
                <textarea
                  type="text"
                  value={talk}
                  className="form-control"
                  onChange={(evt) => setTalk(evt.target.value)}
                />
              </div>
              <div className="form-group">
                <span>Anexar arquivo </span>
                <br />
                <DocInput
                  multiple={false}
                  disable={disable}
                  uploadFile={(itens) => setFile(itens[0])}
                />
              </div>
            </DropdownElement>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group">
          <div className="input-group">
            <input
              type="text"
              placeholder="Filtrar"
              className="form-control"
              id="search-filter"
              value={question}
              onChange={(evt) => handleFilterChange(evt.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={() => setQuestion("") | setFilter([])}
                title="Limpar campo"
              >
                <i className="si si-close"></i>
              </span>
            </div>
          </div>
        </div>
        {filter.length > 0 ? (
          <div className="col-md-12 py-3">
            {filter &&
              filter.map((item) => (
                <NotesItem
                  data={item}
                  refresh={() => loadNotes()}
                  open={(element) => open(element)}
                />
              ))}
          </div>
        ) : (
          <div className="col-md-12 py-3">
            {data &&
              data.map((item) => (
                <NotesItem
                  data={item}
                  refresh={() => loadNotes()}
                  open={(element) => open(element)}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
