import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const ConsultController = {
  GetJunta : async function (cnpj, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/junta?cnpj=${cnpj}&processId=${processId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  GetReceita : async function (cnpj, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/ReceitaFederal/CNPJ?cnpj=${cnpj}${!processId ? "&" : `&processId=${processId}`}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  GetPrefeitura : async function (cnpj, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/PrefeituraCCM?cnpj=${cnpj}&processId=${processId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  GetSintegra : async function (cnpj, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/Sintegra?cnpj=${cnpj}&processId=${processId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  GetCaixaRegularidade : async function (cnpj, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/Caixa?cnpj=${cnpj}&processId=${processId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  GetProcuradoriaGeral : async function (cnpj, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/Procuradoria?cnpj=${cnpj}&processId=${processId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  GetSefaz : async function (cnpj, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/Sefaz?cnpj=${cnpj}&processId=${processId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  GetPrefeituraCertidaoTributaria : async function (cnpj, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/Certidao/tributaria?cnpj=${cnpj}&processId=${processId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  Run : async function (response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/Run`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  ConsultOne : async function (organId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `infosimples/consult/${organId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
}