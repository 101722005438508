import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";

export default function TaskTutorialModal({ show, onHide }) {
    const [loading] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <CustomModal
            title="Tutorial"
            show={show}
            isLoading={loading}
            onHide={onHide}>
                <strong>O que é a Lista de Tarefas?</strong>
            <p>
            A Lista de Tarefas é uma ferramenta do Hasa Pro desenvolvida para ajudar você a gerenciar suas atividades diárias de maneira simples e eficiente. Com ela, você pode manter o foco e otimizar seu tempo.
            </p>
            
            <strong>Benefícios da Lista de Tarefas:</strong>
            <ul>
                <li>Organização: Centralize todas as suas tarefas em um único lugar, facilitando a gestão do seu dia a dia;</li>
                <li>Prioridades: Classifique suas tarefas por prioridade, garantindo que você sempre trabalhe nas atividades mais relevantes;</li>
                <li>Produtividade: Aumente sua eficiência atuando exatamente nas atividades por ordem de importância;</li>
                <li>Acessibilidade: Acesse suas listas de qualquer dispositivo, a qualquer hora, garantindo que você nunca perca uma tarefa.</li>
            </ul>
            
            <strong>Funcionalidades:</strong>
            <ul>
                <li>Criação de Tarefas: Adicione, edite e exclua tarefas de forma rápida e intuitiva.</li>
                <li>Finalizadas: Tenha um registro de todo o histórico das atividades executadas para conferir sempre que precisar.</li>
            </ul>
            
            <strong>Como Funciona:</strong>
            <ul>
                <div>1. Acesse a Lista de Tarefas: Vá até o Menu Principal do Hasa Pro e clique em "Lista de Tarefas";</div>
                <div>2. Adicione suas Tarefas: Preencha os detalhes da tarefa no campo e clique em "Adicionar Tarefa";</div>
                <div>3. Defina Prioridades: Classifique a tarefa conforme sua urgência e importância;</div>
                <div>4. Acompanhe seu Progresso: À medida que conclui tarefas, marque-as como feitas e visualize suas conquistas no histórico de atividades finalizadas.</div>
            </ul>
            <div className="text-center py-3">
                Experimente a Lista de Tarefas e otimize sua rotina!
                <br />

                Estamos aqui para esclarecer suas dúvidas!

            </div>
        </CustomModal>
    );
}
