import React, { useState } from "react";
import { BudgetStatusTranslate } from "@/app/core/util/StatusTranslater";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import OrganListHelper, {
  GetCurrentOperatoData,
  GetCurrentStatus,
  OrganStatus,
  OrganStatusTranslate,
} from "@/app/core/util/helpers/OrganListHelper";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import { ProcessStatus } from "@/app/core/util/helpers/SelectedServiceHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Storage from "@/app/core/util/Storage";
import TimelineController from "@/app/core/base/controllers/TimelineController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { PreparationStep } from "@/app/core/util/helpers/UserHelper";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";

export default function SearchItem({ data, open, rows, reload }) {
  const [loading, setLoading] = useState(false);
  const organHelper = OrganListHelper();
  const organCtrl = OrganListController();
  const userData = Storage.getUserData();
  const timelineCtrl = TimelineController();

  function goTo(organList) {
    let infos = data;
    if (organList !== undefined) {
      infos.organListId = organList.id;
    }
    open(infos);
  }

  function processBody() {
    if (
      (data.budgetStatus !== BudgetStatus.Acepted ||
        data.budgetStatus === BudgetStatus.Refused) &&
      data.status !== ProcessStatus.Deleted
    ) {
      return (
        <div
          className="block block-bordered block-rounded"
          onClick={() => goTo()}
          style={{ cursor: "pointer" }}
        >
          <table className="table table-hover">
            <tbody>
              <tr>
                <td>Departamento & Status</td>
                <td>Orçamento - {BudgetStatusTranslate(data.budgetStatus)}</td>
              </tr>
              <tr>
                <td>Responsável</td>
                <td>
                  {data &&
                    data.seller &&
                    `${data.seller.firstName} ${data.seller.lastName}`}
                </td>
              </tr>
              <tr>
                <td>Última atualização</td>
                <td>{DateTranslate(data.updatedDate)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else if (
      data.budgetStatus === BudgetStatus.Acepted &&
      data.status !== ProcessStatus.Deleted
    ) {
      let organList = [];

      if (data.organList) {
        organList = data.organList.filter(
          (x) =>
            x.status === OrganStatus.Done || x.status === OrganStatus.Deferred,
        );
      }

      let ongoing = [];

      if (data.organList) {
        ongoing = data.organList.filter(
          (x) =>
            x.stage !== 1 &&
            x.stage !== 10 &&
            x.status !== OrganStatus.Deferred,
        );
      }

      let notStarted = [];

      if (data.organList) {
        notStarted = data.organList.filter((x) => x.stage === 1);
      }
      if (data.status == ProcessStatus.Returned) {
        return (
          <div
            className="block block-bordered block-rounded"
            onClick={() => goTo()}
            style={{ cursor: "pointer" }}
          >
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>
                    <span className={"badge badge-dual"}>
                      <i className={"fa fa-exclamation-circle text-danger"} />{" "}
                      Processo Devolvido
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Última atualização</td>
                  <td>{DateTranslate(data.updatedDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
      if (data.status == ProcessStatus.Deleted) {
        return (
          <div
            className="block block-bordered block-rounded"
            onClick={() => goTo()}
            style={{ cursor: "pointer" }}
          >
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>
                    <span className={"badge badge-danger"}>
                      <i className={"si si-close"} /> Processo excluído
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Última atualização</td>
                  <td>{DateTranslate(data.updatedDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } else if (
        ongoing.length === 0 &&
        notStarted.length > 0 &&
        data.budgetStatus === BudgetStatus.Acepted &&
        data.status !== ProcessStatus.Deleted
      ) {
        console.log("Narnia", data.processName);
        return (
          <div className="block block-bordered block-rounded ribbon ribbon-right ribbon-bookmark ribbon-danger">
            <div class="ribbon-box" style={{ marginTop: 27 }}>
              <small>Aguardando distribuição</small>
            </div>
            <table
              className="table table-hover"
              onClick={() => goTo()}
              style={{ cursor: "pointer" }}
            >
              <tbody className="">
                <tr>
                  <td>Acesso</td>
                  <td>
                    <a
                      className="btn btn-dual"
                      href={`javascript:void(0)`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="far fa-eye" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Aguardando distribuição</td>
                </tr>
                <tr>
                  <td>Última atualização</td>
                  <td>{DateTranslate(data.updatedDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } else if (
        data.organList != undefined &&
        organList.length !== data.organList.length
      ) {
        return (
          <div className="block-rounded block-bordered">
            {data &&
              data.organList &&
              data.organList.map((item) => {
                if (item.stage > 1 && item.status !== OrganStatus.Done) {
                  let operator = GetCurrentOperatoData(item);
                  let isMe = false;

                  if (operator !== undefined && operator.id === userData.id) {
                    isMe = true;
                  }
                  if (operator !== undefined) {
                    return (
                      <div className="block block-bordered block-rounded ribbon ribbon-right ribbon-bookmark ribbon-warning">
                        {isMe === true ? (
                          <div class="ribbon-box" style={{ marginTop: -33 }}>
                            <small>Está com você</small>
                          </div>
                        ) : (
                          ""
                        )}
                        <table
                          className="table table-hover"
                          onClick={() => goTo(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <tbody className="">
                            <tr>
                              <td>Serviço</td>
                              <td>
                                {item.selectedService &&
                                  item.selectedService.serviceName}
                                <br /> Órgão : {item.organ && item.organ.name}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Departamento & Status</td>
                              <td>
                                {DepartmentTranslate(item.stage)}{" "}
                                {item.stage == 3 ? (
                                  <span>
                                    {item.preparationStage ==
                                    PreparationStep.Review
                                      ? " - Conferência"
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                                <br />
                                {organHelper.GetCurrentStatus(item)}
                              </td>
                            </tr>
                            <tr>
                              <td>Última atualização</td>
                              <td>{DateTranslate(item.updatedDate)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  }
                }
              })}
          </div>
        );
      } else if (data.status == ProcessStatus.Done) {
        return (
          <div
            className="block block-bordered block-rounded"
            onClick={() => goTo()}
            style={{ cursor: "pointer" }}
          >
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>
                    <span className={"badge badge-success"}>
                      <i className={"si si-check"} /> Processo Concluído
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Última atualização</td>
                  <td>{DateTranslate(data.updatedDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } else {
        return (
          <div
            className="block block-bordered block-rounded"
            onClick={() => goTo()}
            style={{ cursor: "pointer" }}
          >
            <table className="table table-hover">
              <tbody className="">
                <tr>
                  <td>Status</td>
                  <td>
                    <span className={"badge badge-danger"}>
                      <i className={"si si-close"} /> Processo excluído
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Última atualização</td>
                  <td>{DateTranslate(data.updatedDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
    } else if (data.status == ProcessStatus.Deleted) {
      return (
        <div
          className="block block-bordered block-rounded"
          onClick={() => goTo()}
          style={{ cursor: "pointer" }}
        >
          <table className="table table-hover">
            <tbody>
              <tr>
                <td>Status</td>
                <td>
                  <span className={"badge badge-danger"}>
                    <i className={"si si-close"} /> Processo excluído
                  </span>
                </td>
              </tr>
              <tr>
                <td>Última atualização</td>
                <td>{DateTranslate(data.updatedDate)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }

  function redistribuir() {
    if (data.organList && data.organList.length > 0) {
      for (let s in data.organList) {
        const item = data.organList[s];
        if (item.stage > 0 && item.stage < 6) {
          setLoading(true);
          organCtrl.ReprocessOne(item.id, (res) => {
            setLoading(false);
            reload();
          });
        }
      }
    } else if (
      data.budgetStatus != BudgetStatus.Acepted &&
      data.budgetStatus != BudgetStatus.Refused
    ) {
      AutomationsController().DistributeProcess((res) => {
        setLoading(false);
        reload();
      });
    }
  }

  return (
    <div class={`col-md-${rows != undefined ? rows : "6"}`}>
      <div class="block block-bordered block-rounded block-fx-pop">
        <div className="block-header">
          <div className="block-title">
          <span className="cursor" onClick={() => goTo()}>
            Processo : {data && data.processName}
          </span>
            <div class="font-size-sm text-success mb-1">
              Cliente : {data.customer && data.customer.name} | Cód :{" "}
              {data.customer && data.customer.customerCode}
              <br />
              Id : {data.id}
              <button
                className="btn btn-sm btn-dual"
                title="Reprocessar"
                onClick={() => redistribuir()}
              >
                <i className="si si-refresh" />
              </button>
              {loading === true ? (
                <i className={"fa fa-circle-notch fa-spin ml-3"}></i>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="block-tools">
            {data.customer &&
              userData &&
              userData.level != 5 &&
              CustomerHelper().ShowLevel(data.customer.level, "fa-2x")}
          </div>
        </div>
        <div className="block-content">{processBody()}</div>
      </div>
    </div>
  );
}
