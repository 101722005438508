import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import BalanceEntryController from "@/app/core/base/controllers/BalanceEntryController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Field from "@/app/core/layouts/main-components/Field";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";

export default function CloseBillingModal({ show, onHide, startDate, endDate }) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [show]);

    async function save() {
        if (!data.endDate) {
            toast.error(Message.PendingData);
        }else{
            if (window.confirm(Message.Confirm)) {
                setLoading(true);
                await ServiceChargeController().Closure(startDate, `${endDate}T23:59:59`, `${data.endDate}T23:59:59`, data.dueDate, res => {
                    setLoading(false);
                    console.log("Resultado", res, res.length)
                    toast.success(`Fechada a conta de ${res.length === 0 ? 0 : res.length} clientes. Consulte na guia de cobranças emitidas`);
                })
            }
        }
    }

    function end() {
        toast.success(Message.Saved);
        onHide()
    }

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type))
    }

    return (
        <Modal
            title={`Fechar em qual data?`}
            show={show}
            isLoading={loading}
            onHide={onHide}
            size="sm"
            onSave={() => save()}
            onSubmit={() => save()}>

            <Field value={data && data.endDate} onChange={evt => handleChange(evt)} name={"endDate"} title={"Data de fechamento"} type={FieldTypeObj.Date} />
            <Field value={data && data.dueDate} onChange={evt => handleChange(evt)} name={"dueDate"} title={"Data de Vencimento"} type={FieldTypeObj.Date} />
        </Modal>
    );
}
