import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import Table from "@/app/core/components/table/";
import UserFormModal from "./UserFormModal";
import Storage from "@/app/core/util/Storage";
import {
  CustomerUsers,
  CompanyUsers,
  UserDelete,
} from "@/app/core/base/controllers/UsersController";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import { DeleteConfirm } from "@/app/core/util/messages/GeralMessages";
import Functions from "@/app/core/util/Functions";

export default function UsersCardComponent({ customerId }) {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setEditShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const user = Storage.getUserData();

  const [cols, setCols] = useState([
    // { header: "Id", isSortable: true, name: "id" },
    { header: "Nome", isSortable: true, name: "name" },
    { header: "Email", isSortable: true, name: "email" },
    { header: "Celular", isSortable: true, name: "basePhone" },
    {
      header: "Tipo",
      name: "role",
      isSortable: true,
      switch: {
        0: "Operador",
        1: "Supervisor",
        2: "Administrador",
      },
    },
    { header: "Departamento", isSortable: true, name: "department" },
    {
      header: "Situação cadastral",
      isSortable: true,
      name: "registrationStatus",
      switch: {
        true: "Habilitado",
        false: "Desabilitado",
      },
    },
    { header: "Criado em", isSortable: true, name: "createdDate" },
    { header: "Ultimo acesso", isSortable: true, name: "updatedDate" },
  ]);

  useEffect(() => {
    checkAccount();
    getUsers();
  }, []);

  function checkAccount() {
    if (customerId !== undefined) {
      setCols([
        // { header: "Id", isSortable: true, name: "id" },
        { header: "Nome", isSortable: true, name: "name" },
        { header: "Email", isSortable: true, name: "baseEmail" },
        { header: "Celular", isSortable: true, name: "basePhone" },
        {
          header: "Tipo",
          name: "role",
          isSortable: true,
          switch: {
            0: "Operador",
            1: "Supervisor",
            2: "Administrador",
          },
        },
        { header: "Criado em", isSortable: true, name: "createdDate" },
      ]);
    }
  }

  function getUsers() {
    if (customerId === undefined) {
      CompanyUsers((res) => {
        for (let s in res) {
          res[s].name = `${res[s].firstName} ${res[s].lastName}`;
          res[s].department = DepartmentTranslate(res[s].level);
          if (res[s].accessHistory && res[s].accessHistory.length > 0) {
            res[s].updatedDate =
              res[s].accessHistory[res[s].accessHistory.length - 1].updatedDate;
          }
        }
        if (user.level == 10) {
          setData(ProcessDates(res));
        } else {
          setData(
            ProcessDates(res),
          );
        }
        setTableLoading(false);
      });
    } else {
      CustomerUsers(customerId, (res) => {
        for (let s in res) {
          res[s].name = `${res[s].firstName} ${res[s].lastName}`;
          res[s].department = DepartmentTranslate(res[s].level);
          if(res[s].validPhone == false){
            res[s].basePhone = <span><i className="fa fa-exclamation-circle text-danger"/> {res[s].phone}</span>
          }else{
            res[s].basePhone = res[s].phone;
          }
          if(res[s].validEmail == false){
            res[s].baseEmail = <span><i className="fa fa-exclamation-circle text-danger"/> {res[s].email}</span>
          }else{
            res[s].baseEmail = res[s].email;
          }
        }
        setData(ProcessDates(res));
        setTableLoading(false);
      });
    }
  }

  function editUser(user) {
    setEditShowModal(!showEditModal);
    setUserData(user);
  }

  function disableUser(data) {
    if (user.level !== 10 && user.level !== 5) {
      toast.info(
        "Seu nível de acesso não permite a exclusão de um usuário, solicite ao administrador",
      );
    } else {
      if (window.confirm(DeleteConfirm)) {
        setTableLoading(true);
        UserDelete(data.id, (res) => {
          setTableLoading(false);
          toast.info("Usuário removido com sucesso");
          getUsers();
        });
      }
    }
  }

  return (
    <>
      <Card
        title="Usuários"
        action={() => setEditShowModal(!showModal)}
        icon="plus"
      >
        <div style={{ overflowX: "scroll" }}>
          <Table
            loading={tableLoading || false}
            data={data}
            columns={cols}
            editAction={(user) => editUser(user.data)}
            insertAction={() => setEditShowModal(true)}
            deleteAction={(user) => disableUser(user.data)}
            setFilter={(filter) => Functions.ProcessFilter(filter, data)}
            hideHeader
          />
        </div>
      </Card>

      <UserFormModal
        values={userData}
        show={showEditModal}
        customerId={customerId}
        onHide={() =>
          setEditShowModal(!showEditModal) | setUserData({}) | getUsers()
        }
      />
    </>
  );
}
