import React, { useEffect, useState } from "react";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import PendingCustomers from "../components/PendingCustomers";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import BillOfExchange from "../components/BillOfExchange";
import SinteticAccount from "../components/SinteticAccount";
import DiscountReport from "../components/DiscountReport";
import { Dropdown } from "react-bootstrap";
import { ArrayMes, GetLastDayOfMonth } from "@/app/core/util/DateTranslater";
import Importations from "../components/Importations";
import Exportations from "../components/Exportations";
import Blockeds from "../components/Blockeds";
import Negativeds from "../components/Negativeds";
import DiscountPolitic from "../components/DiscountPolitic";
import WhoPaid from "../components/WhoPaid";
import WhoDidntPay from "../components/WhoDidntPay";
import RecurrentCustomers from "../components/RecurrentCustomers";
import PendingWhithoutMovement from "../components/PendingWhithoutMovement";
import Creditor from "../components/Creditor";
import NegativedReturned from "../components/NegativedReturned";
import DebtorsMovement from "../components/DebtorsMovement";
import Negotiations from "../components/Negotiations";
import Storage from "@/app/core/util/Storage";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import { toast } from "react-toastify";

export default function ReportsMain() {
  const date = new Date();
    const [year, setYear] = useState(date.getFullYear());
    const [month, setMonth] = useState(date.getMonth());
    let screenHeight = window.innerHeight / 1.296;

    const userData = Storage.getUserData();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(year, month)
      .toISOString()
      .substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    GetLastDayOfMonth(year, month)
      .toISOString()
      .substring(0, 10));
  const [refreshData, setRefreshData] = useState(false);
  const financialHelper = FinancialHelper();
  const cashFlow = CashFlowController();

  useEffect(() => {
    if(userData.level !== UserHelper.Level.Administrator &&
      userData.level !== UserHelper.Level.Collector && 
      userData.level !== UserHelper.Level.FinancialAdministrator){
        toast.error("Acesso Proibido");
        setTimeout(()=> {
          window.location = "/";
        },2000)
      }
    loadCashFlow();
  }, [refreshData, endDate]);

  useEffect(() => {
    setStartDate(
      getFirstDayOfMonth(year, month)
        .toISOString()
        .substring(0, 10))

  setEndDate(getLastDayOfMonth(year, month)
      .toISOString()
      .substring(0, 10));
  }, [year, month]);

  function getLastDayOfMonth(year, month) {
      // Definindo o primeiro dia do mês seguinte
    let nextMonth = new Date(year, month + 1, 1);
    
    // Subtraindo um dia para obter o último dia do mês atual
    nextMonth.setDate(nextMonth.getDate() - 1);
    
    return nextMonth;
  }

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  async function loadCashFlow() {
  }

  function handleSelection(month) {
    setStartDate(getFirstDayOfMonth(date.getFullYear(), month)
      .toISOString()
      .substring(0, 10))

    setEndDate(GetLastDayOfMonth(date.getFullYear(), month)
      .toISOString()
      .substring(0, 10))
  }

  return (
    <div className="content">
      <div className="row">
        {/* <div className="col-md-8">
          <div className="block-title">Relatórios</div>
        </div> */}
        <Tabs>
          <div className="col-md-12 block block-rounded" style={{ top: 80, position: "fixed", zIndex: 1030 }}>
            <div
              className="nav nav-tabs nav-tabs-block nav-justified js-tabs-enabled bg-white"
              style={{ padding: 7 }}
            >
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  alt="Descontos"
                >
                  <i className="far fa-sticky-note mr-1"></i> Conta corrente sintética
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="si si-notebook mr-1"></i> Histórico de Cobranças
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="fa fa-chart-bar mr-1"></i> Cobranças futuras
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="fa fa-stop-circle mr-1"></i> Bloqueados
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="fa fa-thumbs-down mr-1"></i> Negativados
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="fa fa-percentage mr-1"></i> Participantes da Política
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="si si-check mr-1"></i> Clientes que pagaram
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="far fa-calendar-times mr-1"></i> Devedores
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="far fa-clock mr-1"></i> Recorrentes
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="fa fa-user-clock mr-1"></i> Devedores sem movimentação
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="far fa-money-bill-alt mr-1"></i> Clientes Credores
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="fa fa-retweet mr-1"></i> Clientes negativados que pagaram
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="far fa-address-book mr-1"></i> Devedores que movimentam
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  data-toggle="layout"
                >
                  <i className="far fa-handshake mr-1"></i> Negociações
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  alt="Descontos"
                >
                  <i className="fa fa-file-import mr-1"></i> Importações (Saldo anterior)
                </button>
              </Tab>
              <Tab>
                <button
                  type="button"
                  className="btn btn-sm btn-dual mr-1"
                  alt="Descontos"
                >
                  <i className="far fa-file-excel mr-1"></i> Exportações
                </button>
              </Tab>

              <div className="d-flex">
                {/* <div className="form-group mr-2">
                  <span>Mês de consulta</span>
                  <select
                    className="form-control form-control-sm"
                    defaultValue={date.getMonth()}
                    onChange={(evt) => handleSelection(evt.target.value)}
                  >
                    <option>Selecione</option>
                    {ArrayMes.map((item, index) => (
                      <option value={index}>{item}</option>
                    ))}
                  </select>
                </div> */}

                <div className="form-group">
                  <span>Mês</span>
                  <select
                    className="form-control form-control-alt ml-2 mr-2"
                    value={month}
                    onChange={(evt) => setMonth(parseInt(evt.target.value))}
                  >
                    <option value={undefined}>Selecione</option>
                    {ArrayMes.map((item, index) => (
                      <option value={index} selected={month === index ? true : false}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <span>Ano</span>
                  <input
                    type="number"
                    className="form-control form-control-alt ml-2 mr-2"
                    value={year}
                    max={date.getFullYear()}
                    min={date.getFullYear() - 1}
                    onChange={(evt) => setYear(parseInt(evt.target.value))}
                  />
                </div>
                <Dropdown>
                  <Dropdown.Toggle className="mt-4 btn-sm">
                    Período personalizado
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="form-group">
                      <span>Data de início</span>
                      <input
                        type={"date"}
                        className="form-control"
                        value={startDate}
                        onChange={(evt) => setStartDate(evt.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <span>Data fim</span>
                      <input
                        type={"date"}
                        className="form-control"
                        value={endDate}
                        onChange={(evt) => setEndDate(evt.target.value)}
                      />
                    </div>
                    <button className="btn btn-hero-info btn-block mt-4" onClick={() => setRefreshData(!refreshData)}>
                      <i className="fa fa-search mr-1" />
                      Buscar
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-8" style={{ overflowY: "scroll", minHeight: screenHeight }}>
            <Panel>
              <SinteticAccount refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <BillOfExchange refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <PendingCustomers refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <Blockeds/>
            </Panel>
            <Panel>
              <Negativeds/>
            </Panel>
            <Panel>
              <DiscountPolitic refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <WhoPaid refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <WhoDidntPay refreshData={refreshData} startDate={startDate} endDate={endDate}/>
            </Panel>
            <Panel>
              <RecurrentCustomers />
            </Panel>
            <Panel>
              <PendingWhithoutMovement refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <Creditor refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <NegativedReturned refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <DebtorsMovement refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <Negotiations refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <Importations refreshData={refreshData} startDate={startDate} endDate={endDate} />
            </Panel>
            <Panel>
              <Exportations startDate={startDate} endDate={endDate} />
            </Panel>
          </div>
        </Tabs>
      </div>
    </div>
  );
}
