import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { NumberMask } from "@/app/core/util/helpers/MoneyHelper";

export default function SalesDescriptionModal({
    showModal,
    onHide,
    data
}) {
    const [budget, setBudget] = useState([]);
    const [metaValues, setMetaValues] = useState({
        meta: 0, superMeta: 0, ultraMeta: 0
    });
    const organHelper = OrganListHelper();


    useEffect(() => {
        if (showModal && data.sales) {
            console.log("Valores", data.sales, data.service)
            setMetaValues(calculateMetaValues((data.sales + data.service) / 2));
        }
    }, [showModal, data]);

    const calculateMetaValues = (sales) => {
        console.log(sales, {
            meta: sales * 0.8,
            superMeta: sales * 0.9,
            ultraMeta: sales * 1.0
        })
        return {
            meta: sales * 0.8,
            superMeta: sales * 0.9,
            ultraMeta: sales * 1.0
        };
    };



    return (
        <Modal
            title={""}
            show={showModal}
            onHide={onHide}>

            {/* <div className="row">
                <div className="col-6 border-right border-2x text-center">
                    <div className="font-w600 mt-2 text-uppercase text-muted">
                        Vendas mês
                    </div>
                    <div className="font-size-h1 font-w300 text-black">
                        {data && data.salesResult}
                    </div>
                </div>
                <div className="col-6">
                    <div className="font-w600 mt-2 text-uppercase text-muted">
                        Vendas para meta
                    </div>
                    <div className="font-size-h2 py-4 font-w300 text-black border-bottom border-2x">
                        {metaValues && metaValues.meta} para Meta
                    </div>
                    <div className="font-size-h2 py-4 font-w300 text-black border-bottom border-2x">
                        {metaValues && metaValues.superMeta} para Super Meta
                    </div>
                    <div className="font-size-h2 py-4 font-w300 text-black">
                        {metaValues && metaValues.ultraMeta} para Ultra Meta
                    </div>

                </div>
            </div> */}

            <div className="row d-flex align-items-center justify-content-between">
                <div className="col-5 text-center">
                    <div className="font-w600 mt-2 text-uppercase text-muted">
                        Vendas mês
                    </div>
                    <div className="font-size-h2 py-4 font-w300 text-success">
                    {data && data.salesResult}
                    </div>
                </div>
                <div className="col-2 text-center">
                    <div className="font-size-h1">/</div>

                </div>
                <div className="col-5 text-center">
                    <div className="font-w600 mt-2 text-uppercase text-muted">
                        Meta estipulada
                    </div>
                    <div className="font-size-h2 py-4 font-w300 text-success">
                        {data.sales}
                    </div>

                </div>
                <div className="col-12 border-top border-2x text-center pt-3 mt-2">
                    <div className="font-size-h1 font-w300 text-black">
                        Já alcançamos <span className="text-success">{data &&
                            data.salesResult &&
                            NumberMask((data.salesResult / data.sales) * 100, 2)}%</span> da meta
                    </div>
                </div>
            </div>

        </Modal>
    );
}
