import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { DateTranslate, ProcessFirstBudget } from "@/app/core/util/DateTranslater";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import React, { useEffect, useState } from "react";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import EntryModal from "@/app/core/layouts/main-components/financial/EntryModal";
import { Message } from "@/app/core/util/messages/GeralMessages";
import ReminderCard from "@/app/core/layouts/main-components/reminders/ReminderCard";
import CollectorHistoryController from "@/app/core/base/controllers/CollectorHistoryController";

export default function FinancialReview({ customerId, customer }) {
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState({});
    const [selectedService, setSelectedService] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showServiceModal, setShowServiceModal] = useState(false);
    const date = new Date();

    const [refreshData, setRefreshData] = useState(false);

    const financialHelper = FinancialHelper();
    const cashFlow = CashFlowController();

    useEffect(() => {
        loadCustomerPayments();
    }, [refreshData]);

    async function loadCustomerPayments() {
        if (customerId) {
            await cashFlow.PendingReview(customerId, async (res) => {
                setData(res);
            });
        } else {
            await cashFlow.PendingReviewOperator((res) => {
                setData(res);
            });
        }
    }

    function deleteRequest(item) {
        if (window.confirm(Message.DeleteConfirm)) {
            cashFlow.DeleteEntry(item.id, async (res) => {
                reportEntry(`Removeu o lançamento de reembolso de revisão "${item.description}"`, item.customerId)
                loadCustomerPayments();
            });
        }
    }

    function reportEntry(message, id) {
        CollectorHistoryController().InsertHistory(message, id);
    }

    return (
        <div className="row">
            <div className="block block-themed col-md-12 block-mode-loading-refresh">
                <div className="block-header">
                    <h3 className="block-title">Revisão de cobrança para serviços excluídos</h3>
                    <div className="block-tools">
                        {/* <button
                            className="btn btn-sm btn-white"
                            onClick={() => setShowModal(true)}
                        >
                            <i className="si si-credit-card"></i> Registrar operação
                        </button> */}


                        <button className="btn btn-dark" onClick={() => loadCustomerPayments()}>
                            <i className="si si-refresh" />
                        </button>
                    </div>
                </div>
                <div className="block-content">
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                {customerId == undefined ?
                                    <td>Cliente</td>
                                    : ""}
                                <td>Descrição</td>
                                <td>Status</td>
                                <td>Valor</td>
                                <td>Data de aprovação</td>
                                <td>Data de exclusão</td>
                                <td>Ações</td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => {
                                if (item.entryType === undefined) {
                                    return <tr>
                                        {customerId == undefined ?
                                            <td>{item.process && item.process.customer && `${item.process.customer.customerCode} - ${item.process.customer.name}`}</td> : ""}
                                        <td>{item.description} | <span className="font-w700">{item.process && item.process.processName}</span></td>
                                        <td>
                                            {financialHelper.PaymentStatusTranslateBadge(item.status)}
                                        </td>
                                        <td>
                                            {item.value > 0 &&
                                                item.type === financialHelper.OperationType.Credit ? (
                                                <span className="text-success">
                                                    {MoneyMask(item.value, 2)}
                                                </span>
                                            ) : (
                                                <span className="text-danger">
                                                    {MoneyMask(item.value, 2)}
                                                </span>
                                            )}
                                        </td>
                                        <td>{item.process && DateTranslate(item.process.endBudgetDate)}</td>
                                        <td>{DateTranslate(item.createdDate)}</td>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-square btn-white m-1"
                                                onClick={() => setShowModal(true) | setSelected(item)}
                                            >
                                                <i className="far fa-edit" /> Editar cobrança
                                            </button>
                                            <button
                                                className="btn btn-sm btn-square btn-white m-1"
                                                onClick={() => setSelectedService({ id: item.processId }) | setShowServiceModal(true)}>
                                                <i className="far fa-eye" /> Ver serviço
                                            </button>
                                            <button
                                                className="btn btn-sm btn-square btn-white m-1"
                                                onClick={() => window.open(`#/customer-profile/${item.customerId}`, "_blank")}>
                                                <i className="si si-share-alt" /> Ver cliente
                                            </button>
                                            <button
                                                className="btn btn-sm btn-square btn-white m-1"
                                                onClick={() => deleteRequest(item)}>
                                                <i className="si si-close text-danger" />
                                            </button>
                                        </td>
                                    </tr>
                                } else {
                                    return <tr>
                                        <td>{item.process && item.process.processName} | {item.serviceName}</td>
                                        <td>
                                            {financialHelper.PaymentStatusTranslateBadge(item.paymentStatus)}
                                        </td>
                                        <td>
                                            {financialHelper.OperationTypeTranslateBadge(item.entryType)}
                                        </td>
                                        <td>
                                            {item.price > 0 &&
                                                <span className="text-success">
                                                    {MoneyMask(item.price, 2)}
                                                </span>
                                            }
                                        </td>
                                        <td>{item && DateTranslate(item.acceptedDate)}</td>
                                        <td>{DateTranslate(item.createdDate)}</td>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-square btn-white m-1"
                                                onClick={() => setSelectedService({ id: item.processId }) | setShowServiceModal(true)}>
                                                <i className="far fa-edit" /> Ver serviço
                                            </button>
                                        </td>
                                    </tr>
                                }
                            })}
                        </tbody>
                    </table>
                </div>
                <EntryModal
                    selected={selected}
                    show={showModal}
                    customerId={parseInt(customerId)}
                    customer={customer}
                    onHide={() =>
                        setShowModal(false) | loadCustomerPayments() | setSelected({})
                    }
                />
            </div>

            {/* {!customerId ? <ReminderCard /> : ""} */}

            <OperationProcessModal
                data={selectedService}
                show={showServiceModal}
                onHide={() =>
                    setShowServiceModal(false) | loadCustomerPayments() | setSelectedService({})
                }
            />
        </div>
    );
}
