import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { TemplateModelController } from "@/app/core/base/controllers/TemplateModelController";

export default function TemplateModelModal({
    infos,
    show,
    onHide,
}) {
    const [data, setData] = useState({});

    useEffect(() => {
        setData(infos);
    }, [infos]);

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    async function save() {
        if (data.message === undefined) {
            toast.error(`Preencha o departamento para poder salvar`);
            return;
        }

        if (data.id !== undefined) {
            await TemplateModelController.Update(
                data,
                (res) => {
                    toast.success(`Salvo com sucesso`);
                    onHide();
                }
            );
        } else {
            await TemplateModelController.Insert(
                data,
                (res) => {
                    toast.success(`Salvo com sucesso`);
                    onHide();
                }
            );
        }
    }

    return (
        <Modal
            title="Modelo"
            show={show}
            isLoading={false}
            onHide={onHide}
            onSave={() => save()}
            onSubmit={() => save()}
        >

            <a class="block block-rounded block-link-shadow border-left border-success border-3x js-appear-enabled animated fadeIn" data-toggle="appear" href="javascript:void(0)">
                <div class="block-content block-content-full block-content-sm bg-body-light">
                    <p>No final de todos os emails será adicionado o link da cobrança</p>
                </div>
            </a>

            <div className="form-group">
                <span>Assunto:</span>
                <input className="form-control" name="name" value={data && data.name} onChange={evt => handleChange(evt)} />
            </div>
            <div className="form-group">
                <span>Template</span>
                <textarea className="form-control" name="message" value={data && data.message} onChange={evt => handleChange(evt)} />
            </div>
        </Modal>
    );
}
