import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function GetCustomerDoc(doc, response, error) {
  BaseService(
    {
      method: "GET",
      url: `customer-document?document=${doc}&`,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      if (err.status === 200) {
        error(err);
      }
      toast.info(HandleErrors(err));
    },
  );
}

export async function SendWelcome(customerId, response, error) {
  await BaseService(
    {
      method: "POST",
      url: `customer/send-welcome/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      if (err.status === 200) {
        error(err);
      }
      toast.info(HandleErrors(err));
    },
  );
}

export async function UpdateCustomerStage(customerId, stage, response, error) {
  await BaseService(
    {
      method: "POST",
      url: `customer/change-stage?customerId=${customerId}&stage=${stage}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      if (err.status === 200) {
        error(err);
      }
      toast.info(HandleErrors(err));
    },
  );
}

export async function CustomerList(response) {
  await BaseService(
    {
      method: "GET",
      url: `customer`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.log("Erro", err);
      toast.error(HandleErrors(err));
    },
  );
}

export function CustomerConsult(cnpj, cpf, type, response) {
  BaseService(
    {
      method: "GET",
      url: `customer/consults?cnpj=${cnpj}&type=${type}&cpf=${cpf}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      if (err && err.status === 200) {
        response(err.data);
      } else {
        toast.error(HandleErrors(err));
      }
    },
  );
}

export async function CustomerInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: "customer/insert",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function CustomerUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: `customer/update`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      if (err.response !== undefined) {
        toast.error(HandleErrors(err));
      }
    },
  );
}

export async function CustomerDelete(id, response) {
  await BaseService(
    {
      method: "DELETE",
      url: `customer/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function CustomerSelect(id, response) {
  await BaseService(
    {
      method: "GET",
      url: `customer/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.error("asdknalksm",err);
      toast.error(HandleErrors(err));
    },
  );
}
export async function CustomerNPS(id, response) {
  await BaseService(
    {
      method: "GET",
      url: `customer/nps/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      // toast.error(HandleErrors(err));
    },
  );
}

export async function CustomerSearch(email, phone, name, response) {
  await BaseService(
    {
      method: "GET",
      url: `customer/search/?email=${email}&phone=${phone}&name=${name}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function CustomerTimeline(customerId, response) {
  await BaseService(
    {
      method: "GET",
      url: `customer/timeline/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function InsertCustomerTimeline(data, response) {
  await BaseService(
    {
      method: "POST",
      url: `customer/timeline/insert`,
      data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function CustomerPicture(customerId, formData, response) {
  await BaseService(
    {
      method: "POST",
      url: `customer/${customerId}/upload-avatar`,
      data: formData,
      contentType: "multipart/form-data",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function CustomerDeletePicture(customerId, response) {
  await BaseService(
    {
      method: "DELETE",
      url: `customer/avatar/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}
export async function CustomerPaymentPending(startDate, endDate, response) {
  await BaseService(
    {
      method: "GET",
      url: `customer/pending?startDate=${startDate}&endDate=${endDate}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    }
  );
}

export async function CustomerAllPayments(startDate, endDate, response) {
  await BaseService(
    {
      method: "GET",
      url: `customer/charges?startDate=${startDate}&endDate=${endDate}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.log("Erro", err);
      toast.error(HandleErrors(err));
    }
  );
}


export const CustomerController = {
  RegisterBlock: async function (customerId, response) {
    await BaseService(
      {
        method: "POST",
        url: `customer/block/${customerId}`,
      },
      (res) => response(res),
      (err) => {
        toast.error(HandleErrors(err));
      }
    );
  },
  RegisterNegative: async function (customerId, negativation, response) {
    await BaseService(
      {
        method: "POST",
        url: `customer/negative/${customerId}?negativation=${negativation}`,
      },
      (res) => response(res),
      (err) => {
        toast.error(HandleErrors(err));
      }
    );
  },
  RemoveBlock: async function (customerId, response) {
    await BaseService(
      {
        method: "POST",
        url: `customer/remove/block/${customerId}`,
      },
      (res) => response(res),
      (err) => {
        toast.error(HandleErrors(err));
      }
    );
  },
  RemoveNegative: async function (customerId, response) {
    await BaseService(
      {
        method: "POST",
        url: `customer/remove/negative/${customerId}`,
      },
      (res) => response(res),
      (err) => {
        toast.error(HandleErrors(err));
      }
    );
  },
  ListBlockeds: async function (response) {
    await BaseService(
      {
        method: "GET",
        url: `customer/blockeds`,
      },
      (res) => response(res),
      (err) => {
        toast.error(HandleErrors(err));
      }
    );
  },
  ListNegativeds: async function (response) {
    await BaseService(
      {
        method: "GET",
        url: `customer/negativeds`,
      },
      (res) => response(res),
      (err) => {
        toast.error(HandleErrors(err));
      }
    );
  },

}
