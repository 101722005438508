import React, { useEffect, useState, useRef } from "react";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { DateTranslate, OnlyDateTranslate, OnlyDateTranslateToInput } from "@/app/core/util/DateTranslater";
import BalanceImportController from "@/app/core/base/controllers/BalanceImportController";
import { toast } from "react-toastify";
import EntryModal from "@/app/core/layouts/main-components/financial/EntryModal";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CashFlowController from "@/app/core/base/controllers/CashFlowController";

export default function Exportations({ startDate, endDate }) {
    const [filter, setFilter] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({});
    const tableRef = useRef(null);

    useEffect(() => {
        loadBillOfExchange()
    }, [startDate, endDate])

    async function loadBillOfExchange() {
        setLoading(true);
        CashFlowController().LastEntries(startDate, endDate, res => {
            setFilter(res.filter(x => x.status != FinancialHelper().PaymentStatus.Revision));
            setLoading(false);
        })
    }


    function openCustomer(entry) {
        if (entry.customer) {
            window.open(`#/customer-profile/${entry.customerId}`)
        } else {
            toast.info("Esse cliente ainda não foi registrado na plataforma")
        }
    }

    return (
        <div>
            <div className="block-header mt-5">
                <div className="block-title">Exportação de lançamentos</div>
                <div className="block-tools">
                    <DownloadTableExcel
                        filename="users table"
                        sheet={startDate && `Lançamentos - ${DateTranslate(startDate)} até ${DateTranslate(endDate)}`}
                        currentTableRef={tableRef.current}
                    >
                        <button className="btn btn-info"><i className="fa fa-download" /> Exportar Excel </button>
                    </DownloadTableExcel>
                </div>
            </div>
            {loading === true ? (
                <div className="block-content text-center">
                    <i className={"fa fa-circle-notch text-info fa-2x fa-spin"}></i>
                </div>
            ) : ""}
            <table className="table table-hover table-striped" ref={tableRef}>
                <thead>
                    <tr>
                        <td>Data de lançamento</td>
                        <td>Cód Cliente</td>
                        <td>Nome do Cliente</td>
                        <td>Descrição</td>
                        <td>Tipo de operação</td>
                        <td>Valor</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {filter.map((item) => (
                        <tr>
                            <td>{item.dueDate && OnlyDateTranslate(item.dueDate)}</td>
                            <td>{item.customer && item.customer.customerCode}</td>
                            <td>{item.customer && item.customer.name}</td>
                            <td>{item.description} {item.process && `- ${item.process.processName}`}</td>
                            <td>{FinancialHelper().OperationTypeTranslateBadge(item.type)}</td>
                            {item.value == 0 ? <td>0</td> :
                                <td>
                                    {item.type === FinancialHelper().OperationType.Credit ||
                                        item.type === FinancialHelper().OperationType.Reimbursement ||
                                        (item.type === FinancialHelper().OperationType.Negocied && item.value > 0) ||
                                        item.type === FinancialHelper().OperationType.Discount ? (
                                        <span className="text-success">
                                            {MoneyMask(item.value, 2)}
                                        </span>
                                    ) : (
                                        <span className="text-danger">
                                            {MoneyMask(item.value, 2)}
                                        </span>
                                    )}
                                </td>
                            }
                            <td>
                                <button
                                    className="btn btn-sm btn-square btn-white m-1"
                                    onClick={() => openCustomer(item)}
                                >
                                    <i className="far fa-eye" /> Ver cliente
                                </button>
                            </td>
                        </tr>
                    )
                    )}
                </tbody>
            </table>

            <EntryModal
                show={showModal}
                onHide={() => setShowModal(false) | loadBillOfExchange()}
                selected={selected}
            />
        </div>
    )
}