import axios from "axios";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import { GeneralHelper } from "../util/helpers/GeneralHelper";

export default async function BaseService(props, res, err) {
  const date = new Date();
  const hour = date.getHours();
  const weekDay = date.getDay();

  const enviroment = process.env.REACT_APP_ENV;
  let envData;
  // const envData = require(
  //   `../../enviroments/enviroment.${enviroment || "production"}`,
  // );

  if(GeneralHelper.IsDevelopment()){
    envData = require(`../../enviroments/enviroment.staging`);
  }else{
    envData = require(`../../enviroments/enviroment.${enviroment || "production"}`);

  }

  const userData = Storage.getUserToken();

  if (userData && userData.expirationTime < getCurrentTimestamp()) {
    // toast.info("Sua sessão expirou, faça login novamente");
    localStorage.removeItem("user_data");
    window.location = "/";
    return;
  }

  const port = enviroment === "local" && props.port ? `:${props.port}` : "";

  const data = props.data
    ? props.method === "GET"
      ? paramBuilder().add(props.data).build()
      : props.data
    : null;

  const interrogration = props.url.includes("?") ? "" : "?";
  const firstCharacters = props.url.substring(0,8);
  const url = firstCharacters.includes("http")
    ? props.url
    : envData.enviroment.baseApi +
      // : `${hour >= 8 && hour < 19 && weekDay != 0 && weekDay != 6 ? envData.enviroment.baseApi : envData.enviroment.baseApiOfHour}` +
      port +
      "/" +
      props.url +
      (props.method === "GET" && data
        ? `${interrogration}${data}`
        : `${interrogration}`);

  try {
    const request = {
      method: props.method,
      url,
      data,
      headers: {
        "content-type": props.contentType
          ? props.contentType
          : "application/json",
      },
    };
    if (userData)
      request["headers"]["Authorization"] =
        "Bearer " + userData.token.accessToken;

    const response = await axios(request);

    if (response.data) {
      res(response.data);
      return response.data;
    } else {
      err(response);
      return null;
    }
  } catch (error) {
    err(error);
    // if (error.response !== undefined && error.response !== null) {
    //   if (error.response.status === 401) {
    //     localStorage.removeItem("user_data");
    //     localStorage.removeItem("exigencyTemplates");
    //     localStorage.removeItem("services");
    //     localStorage.removeItem("organs");
    //     window.location = "/";
    //   }
    // }
    return null;
  }
}

function getCurrentTimestamp() {
  return new Date().getTime();
}

const paramBuilder = () => {
  const ref = {
    add: null,
    build: null,
    params: [],
  };

  const formatParam = (name, rawValue) => {
    let value = encodeURIComponent(rawValue);
    value = value !== "undefined" ? value : "";
    return encodeURIComponent(name) + "=" + value;
  };

  const add = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const val = obj[prop];
        if (typeof val === "object") {
          if (val !== null && val.length) {
            val.forEach((v) => {
              const newVal = {};
              newVal[prop] = v;
              add(newVal);
            });
          } else {
            add(val);
          }
        } else {
          addParam(prop, val);
        }
      }
    }
    return ref;
  };

  const addParam = (name, rawValue) => {
    ref.params.push(formatParam(name, rawValue));
    return ref;
  };

  const build = () => {
    return ref.params.join("&");
  };

  ref.add = add;
  ref.build = build;

  return ref;
};
