import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import CurrencyFormat from "react-currency-format";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CustomerList } from "@/app/core/base/controllers/CustomerController";
import { Typeahead } from "react-bootstrap-typeahead";
import { CpfMask, CnpjMask } from "@/app/core/util/helpers/ContactHelper";

export default function EntryModal({ show, onHide, selected, customerId }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const financialHelper = FinancialHelper();
  const cashFlow = CashFlowController();
  const [customers, setCustomers] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (selected !== undefined && selected.id !== undefined) {
      let baseDate = selected.dueDate.split("T");
      setData({ ...selected, dueDate: baseDate[0] });
      loadCustomers()
    } else {
      const today = new Date().toISOString().split("T")[0]; // Obtém a data de hoje no formato yyyy-mm-dd
    
      setData({
        dueDate: today,
        customerId: customerId,
      });
    }
  }, [selected]);

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function loadCustomers() {
    CustomerList(res => setCustomers(res));
  }

  async function save() {
    setLoading(true);
    let newData = data;
    if(!newData.customerId){
      newData.customerId = customerId;
    }
    if (newData.id === undefined) {
      await cashFlow.InsertEntry(newData, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    } else {
      await cashFlow.UpdateEntry(newData, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    }
  }

  function formatCurrencyToFloat(value) {
    // Remove todos os caracteres que não são números ou vírgulas
    const numericValue = value.replace(/[^\d,]/g, '');

    // Substitui a vírgula por ponto
    const floatValue = numericValue.replace(',', '.');

    // Converte para float
    return parseFloat(floatValue);
  }

  return (
    <Modal
      title="Cobrança"
      show={show}
      isLoading={false}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="form-group">
        <span>Valor</span>
        <CurrencyFormat
          className={`form-control`}
          thousandSeparator={"."}
          decimalSeparator={","}
          allowNegative={false}
          prefix={"R$"}
          decimalScale={2}
          fixedDecimalScale={true}
          value={data.value != "" && data.value != NaN ? data.value : 0}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            handleChange(
              {
                target: {
                  name: "value",
                  value: formatCurrencyToFloat(formattedValue),
                },
              },
              FieldTypeObj.Currency,
            );
          }}
        />
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <span>Data correspondente</span>
            <input
              type={"date"}
              className="form-control"
              name="dueDate"
              value={data.dueDate}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        {!customerId ? 
        <div className="col-md-12">
          <div className="form-group">
            <span>Cliente</span>
            <input
              className="form-control"
              name="customerId"
              value={data.customer}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            />
          </div>
        </div>
        : ""}
        <div className="col-md-12">
          <div className="form-group">
            <span>Descrição</span>
            <input
              className="form-control"
              name="description"
              value={data.description}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Tipo de operação</span>
            <select
              className="form-control"
              name="type"
              value={data.type}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option>Selecione</option>
              {financialHelper.OperationTypeList.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Status</span>
            <select
              className="form-control"
              name="status"
              value={data.status}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option>Selecione</option>
              {financialHelper.PaymentStatusList.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
        {data && data.id ? 
        <div className="col-md-12">
            <div className="form-group">
              <label for="example-masked-time">Transferir para o cliente:</label>
              <Typeahead
                options={customers && customers}
                onSearch={(q) => setQuery(q)}
                id="advanced-filter-state"
                name="clientName"
                placeholder="Selecionar Cliente"
                labelKey={(option) =>
                  `Cod. ${option.customerCode} - Nome: ${option.name} ${option.cnpj !== undefined && option.cnpj !== ""
                    ? ` - CNPJ: ${CnpjMask(option.cnpj)}` : ""
                  }${option.cpf !== undefined && option.cpf !== ""
                    ? ` - CPF: ${CpfMask(option.cpf)}` : ""
                  } - Email: ${option.email}`
                }
                onChange={(selected) => {
                  if (selected[0] != undefined) {
                    setData({ ...data, customerId: selected[0].id });
                  }
                }}
              />
            </div>
          </div>
        : ""}
      </div>
    </Modal>
  );
}
