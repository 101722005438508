import { GeneralHelper } from "../util/helpers/GeneralHelper";

export default function GetBaseUrl() {
  const enviroment = process.env.REACT_APP_ENV;
  let envData = "";
  // const envData = require(
  //   `@/app/enviroments/enviroment.${enviroment || "production"}`,
  // );

  
    if(GeneralHelper.IsDevelopment()){
      envData = require(`../../enviroments/enviroment.staging`);
    }else{
      envData = require(`../../enviroments/enviroment.${enviroment || "production"}`);
  
    }

  return envData.enviroment.baseUrl;
}
