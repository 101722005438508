import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import React, { useEffect, useState } from "react";

export default function ServicesReport({ startDate, endDate, refresh }) {
  const [services, setServices] = useState([]);
  const [hidenServices, setHidenServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const cashFlow = CashFlowController();

  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    loadServices();
  }, [refresh, startDate, endDate]);

  // useEffect(() => {
  //   notifyChange();
  // }, [invoicing, ticket]);

  function notifyChange() {
    setChange(date.getTime());
  }

  async function loadServices() {
    setServices([]);
    setLoading(true);
    cashFlow.ServiceReport(startDate, endDate, (res) => {
      let total = 0,
        ticket = 0,
        count = 0;
      for (let s in res) {
        total = total + res[s].value;
        ticket = ticket + res[s].value / res[s].selectedServices.length;
        count = count + res[s].selectedServices.length;
      }

      for (let s in res) {
        res[s].percentage = (res[s].value / total) * 100;
      }

      res.sort(function (a, b) {
        return b.percentage - a.percentage;
      });

      // setData({
      //   total,
      //   ticket: ticket / res.length,
      //   count,
      // });
      console.log("Mudou", res, res.slice(0, 10));
      setServices(res.slice(0, 10));
      setHidenServices(res.slice(10, res.length - 1));
      setLoading(false);
      notifyChange();

      // processData(res);
    });
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="block block-tranparent">
          <div className="block-header">
            <div className="block-title">Faturamento por serviço</div>
          </div>
          <div className="block-content">
            {loading === true ? 
            <div className="block-content text-center">
            <i
              className={"fa fa-circle-notch fa-spin text-info fa-2x py-3"}
            ></i>
          </div>
            : 
            <div className="row">
              {change && services &&
                services.map((item) => (
                  <div className="col-md-3" key={item.id + "-service"}>
                    <div class="mb-0">
                      <div class="progress mb-1" style={{ height: 6 }}>
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                          role="progressbar"
                          style={{ width: `${parseInt(item.percentage)}%` }}
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="font-size-sm font-w600 mb-3 row">
                        <span class="font-w700 col-md-6">
                          {item.name} - {parseInt(item.percentage)}% | total:{" "}
                          {item.selectedServices &&
                            item.selectedServices.length}
                        </span>
                        <span class="font-w700  col-md-6 text-right">
                          {MoneyMask(item.value, 2)}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
              {change && hidenServices &&
                hidenServices.map((item) => (
                  <div className="col-md-3">
                    <div class="mb-0">
                      <div class="progress mb-1" style={{ height: 6 }}>
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                          role="progressbar"
                          style={{ width: `${parseInt(item.percentage)}%` }}
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="font-size-sm font-w600 mb-3 row">
                        <span class="font-w700 col-md-6">
                          {item.name} - {parseInt(item.percentage)}% | total:{" "}
                          {item.selectedServices &&
                            item.selectedServices.length}
                        </span>
                        <span class="font-w700  col-md-6 text-right">
                          {MoneyMask(item.value, 2)}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
