import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { ArrayMes, DateTranslate, GetLastDayOfMonth, OnlyDateTimeNow, OnlyDateTranslate, OnlyDateTranslateToInput } from "@/app/core/util/DateTranslater";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import EntryModal from "./EntryModal";
import OperationProcessModal from "../OperationProcessModal";
import { Dropdown } from "react-bootstrap";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import FinancialHelpModal from "./elements/FinancialHelpModal";
import Storage from "@/app/core/util/Storage";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import PayAllModal from "./PayAllModal";
import { HandleErrors } from "@/app/core/util/helpers/ErrorHelper";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

export default function FinancialEntries({ customerId, customer }) {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showPayAll, setShowPayAll] = useState(false);
  const [oldBalance, setOldBalance] = useState({});
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const userData = Storage.getUserData();
  const [change, setChange] = useState(date.getTime());
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());
  const tableRef = useRef(null);

  const [dueDate, setDueDate] = useState(addDays(8)
    .toISOString()
    .substring(0, 10));

  function notifyChange() {
    setChange(date.getTime());
  }

  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(year, month)
      .toISOString()
      .substring(0, 10)
  );
  const [endDate, setEndDate] = useState(getLastDayOfMonth(year, month)
    .toISOString()
    .substring(0, 10));

  const [refreshData, setRefreshData] = useState(false);

  const financialHelper = FinancialHelper();
  const cashFlow = CashFlowController();

  useEffect(() => {
    loadCustomerPayments();
  }, [refreshData]);

  useEffect(() => {
    setStartDate(
      getFirstDayOfMonth(year, month)
        .toISOString()
        .substring(0, 10))

    setEndDate(getLastDayOfMonth(year, month)
      .toISOString()
      .substring(0, 10));
  }, [year, month]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function addDays(days) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + days);
    return currentDate;
  }

  function getLastDayOfMonth(year, month) {
    // Definindo o primeiro dia do mês seguinte
    let nextMonth = new Date(year, month + 1, 1);

    // Subtraindo um dia para obter o último dia do mês atual
    nextMonth.setDate(nextMonth.getDate() - 1);

    return nextMonth;
  }


  async function loadCustomerPayments() {
    setLoading(true);
    await cashFlow.CustomerEntriesByDate(customerId, startDate, `${endDate}T23:59:00`, async (res) => {
      let itens = [];
      let credit = 0,
        debit = 0,
        old = 0;

      await cashFlow.BalanceUntilThisDate(customerId, startDate, async (result) => {
        if (result != undefined) {
          setOldBalance({
            description: "Saldo anterior",
            dueDate: "",
            type: "",
            value: result,
          })
          old = result;
        }
      }, error => {
        if (error.data == 0) {
          setOldBalance({
            description: "Saldo anterior",
            dueDate: "",
            type: "",
            value: error.data,
          })
        } else {
          toast.error(HandleErrors(error))
        }
      });

      for (let s in res) {
        if (res[s].type != financialHelper.OperationType.Deleted && res[s].type != financialHelper.OperationType.Fine && res[s].type != financialHelper.OperationType.Fee) {

          if (res[s].type === financialHelper.OperationType.Debit || res[s].type == financialHelper.OperationType.Fee || res[s].type == financialHelper.OperationType.Fine) {
            debit -= res[s].value;
          }
          else if (res[s].type === financialHelper.OperationType.Reimbursement) {
            credit += res[s].value;
          } else {
            credit += res[s].value;
          }
        }
        
        res[s].time = new Date(res[s].dueDate).getTime();
        itens.push(res[s])
      }

      console.log("ldcsklcnklsdjncklsdjnckjnsdjk", itens);
      itens = itens.sort(function (a, b) {
        return a.time - b.time;
      });

      setSubtotal(credit + debit + old);
      setData(itens);
    }, error => console.log("Errooooooo", error));

    cashFlow.Balance(customerId, async (res) => {
      if (res == 0) {

        setTotal("0");
      } else {

        setTotal(res);
      }

      notifyChange();
    }, error => {
      if (error && error.data == 0) {
        setTotal("0");
      }
      if (error && error) {

        console.log("Erro de que", error)
      }
    });


    setLoading(false);
  }

  async function deleteCharge(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await cashFlow.DeleteEntry(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadCustomerPayments();
      });
    }
  }

  function handleTerm(term) {
    var baseDate = new Date();
    baseDate.setDate(date.getDate() - term);

    setStartDate(OnlyDateTranslateToInput(baseDate));
    setEndDate(OnlyDateTimeNow());
    setRefreshData(!refreshData);
  }

  function closeTermBalance() {
    if (total > 0) {
      toast.success("Você não tem saldo devedor")
    } else {
      if (window.confirm("Deseja realmente gerar o boleto para quitar o saldo?")) {
        ServiceChargeController().ClosureByCustomerByDate(customerId, startDate, endDate, dueDate, res => {
          if (res?.status === 400) {
            toast.error(res?.response?.data);
          } else {
            console.log("Motivo", res);
            toast.success("Boleto gerado com sucesso, você pode acessar a guia de Faturas ou conferir no seu email");
            setRefreshData(!refreshData)
          }
        }, error => {
          toast.error("Não foi possível gerar o boleto, verifique no email o motivo")
        })
      }
    }
  }

  function openPayAll() {
    if (total == 0) {
      toast.info("Você não tem saldo pendente");
    } else {
      setShowPayAll(true)
    }
  }

  function handleSelection(month) {
    setStartDate(getFirstDayOfMonth(date.getFullYear(), month)
      .toISOString()
      .substring(0, 10));

    setEndDate(GetLastDayOfMonth(date.getFullYear(), month)
      .toISOString()
      .substring(0, 10));

    setRefreshData(!refreshData);
  }

  return (
    <div className="block">
      <div className="block-header">
        <div className="block-title d-flex">
          {change && total > 0 ? <h1 className="text-success py-2 font-w700">Saldo atual R${(change && total).toLocaleString()}</h1> : <h1 className="text-danger py-2 font-w700">Saldo atual R${(change && total).toLocaleString()}</h1>}
          <span>

            {userData && (userData.level === UserHelper.Level.Administrator || userData.level === UserHelper.Level.Collector || userData.level === UserHelper.Level.FinancialAdministrator) ?
              <button className="btn btn-hero-success ml-5 mt-2" onClick={() => openPayAll()}>Antecipar Fatura</button>
              : ""}
          </span>
        </div>
        <div className="block-tools d-flex">

          {userData && (userData.level === UserHelper.Level.Administrator || userData.level === UserHelper.Level.Collector || userData.level === UserHelper.Level.FinancialAdministrator) ?
            <Dropdown>
              <Dropdown.Toggle className="btn btn-hero-sm btn-hero-warning mt-4" variant="light">
                <i className="si si-check mr-1" /> Fechamento
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="form-group">
                  <span>Data de início</span>
                  <input
                    type={"date"}
                    className="form-control"
                    value={startDate}
                    onChange={(evt) => setStartDate(evt.target.value)}
                  />
                </div>
                <div className="form-group">
                  <span>Data fim</span>
                  <input
                    type={"date"}
                    className="form-control"
                    value={endDate}
                    onChange={(evt) => setEndDate(evt.target.value)}
                  />
                </div>
                <div className="form-group">
                  <span>Data de vencimento</span>
                  <input
                    type={"date"}
                    className="form-control"
                    value={dueDate}
                    min={endDate}
                    onChange={(evt) => setDueDate(evt.target.value)}
                  />
                </div>
                <button className="btn btn-block btn-hero-info mt-4" onClick={() => closeTermBalance()}><i className="si si-check mr-1" /> Fechar conta</button>
              </Dropdown.Menu>
            </Dropdown>
            : ""}

          <div className="form-group">
            <span>Mês</span>
            <select
              className="form-control form-control-alt ml-2 mr-2"
              value={month}
              onChange={(evt) => setMonth(parseInt(evt.target.value))}
            >
              <option value={undefined}>Selecione</option>
              {ArrayMes.map((item, index) => (
                <option value={index} selected={month === index ? true : false}>{item}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <span>Ano</span>
            <input
              type="number"
              className="form-control form-control-alt ml-2 mr-2"
              value={year}
              max={date.getFullYear()}
              min={date.getFullYear() - 1}
              onChange={(evt) => setYear(parseInt(evt.target.value))}
            />
          </div>
          <div>
            <button className="btn btn-info mt-4" onClick={() => loadCustomerPayments()}>Consultar</button>
          </div>
          <Dropdown>
            <Dropdown.Toggle className="mt-4">
              Personalizado
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="form-group">
                <span>Data de início</span>
                <input
                  type={"date"}
                  className="form-control"
                  value={startDate}
                  onChange={(evt) => setStartDate(evt.target.value)}
                />
              </div>
              <div className="form-group">
                <span>Data fim</span>
                <input
                  type={"date"}
                  className="form-control"
                  value={endDate}
                  onChange={(evt) => setEndDate(evt.target.value)}
                />
              </div>
              <button className="btn btn-hero-info btn-block mt-4" onClick={() => setRefreshData(!refreshData)}>
                <i className="fa fa-search mr-1" />
                Buscar
              </button>
            </Dropdown.Menu>
          </Dropdown>

        </div>
      </div>
      <div className="block block-themed block-mode-loading-refresh">
        <div className="block-header">
          <h3 className="block-title">Conta corrente</h3>
          <div className="block-tools">

            {userData && (userData.level === UserHelper.Level.Administrator || userData.level === UserHelper.Level.Collector || userData.level === UserHelper.Level.FinancialAdministrator) ?
              <button
                className="btn btn-sm btn-white mr-2"
                onClick={() => setShowModal(true)}
              >
                <i className="si si-credit-card"></i> Registrar operação
              </button>
              : ""}
            <button
              type="button"
              className="btn btn-sm btn-white"
              onClick={() => setShowSupportModal(true)}
            >
              <i className="si si-question"></i> Falar com Financeiro
            </button>
            <DownloadTableExcel
              filename={`Conta corrente ${customer && customer.customerCode} - ${OnlyDateTranslate(startDate)} até ${OnlyDateTranslate(endDate)}`}
              sheet={startDate && `Conta corrente`}
              currentTableRef={tableRef.current}
            >
              <button className="btn btn-sm btn-info ml-2"><i className="fa fa-download" /> Exportar Excel </button>
            </DownloadTableExcel>
          </div>
        </div>
        <div className="block-content">
          {loading && loading === true ? (
            <div className="text-center">
              <i className="fa fa-4x fa-circle-notch fa-spin text-success" />
            </div>
          ) : ""}
          {loading != true ?
            <table className="table table-hover table-striped" ref={tableRef}>
              <thead>
                <tr>
                  <td>Data de lançamento</td>
                  <td>Descrição</td>
                  <td>Tipo de operação</td>
                  <td className="text-right">Valor</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {oldBalance && oldBalance.value != undefined ?
                  <tr>
                    <td>-</td>
                    <td>{oldBalance.description}</td>
                    <td>-</td>
                    <td className="text-right">{oldBalance.value > 0 ? (
                      <span className="text-success">
                        R${oldBalance.value && (oldBalance.value).toLocaleString()}
                      </span>
                    ) : (
                      <span className="text-danger">
                        R${oldBalance.value && (oldBalance.value).toLocaleString()}
                      </span>
                    )}
                    </td>
                    <td>-</td>
                  </tr>
                  : ""}
                {data.map((item) => (
                  <tr style={item.type === financialHelper.OperationType.Deleted ? { textDecoration: "line-through" } : {}}>
                    <td>{item.dueDate && OnlyDateTranslate(item.dueDate)}</td>
                    <td>{item.description} {item.process && `- ${item.process.processName}`}</td>
                    <td>{FinancialHelper().OperationTypeTranslateBadge(item.type)}</td>
                    {
                      <td className="text-right">
                        {item.type === financialHelper.OperationType.Credit ||
                          item.type === financialHelper.OperationType.Reimbursement ||
                          (item.type === financialHelper.OperationType.Negocied && item.value > 0) ||
                          item.type === financialHelper.OperationType.Discount ? (
                          <span className="text-success">
                            {MoneyMask(item.value, 2)}
                          </span>
                        ) : item.type === financialHelper.OperationType.Notification ? "" : (
                          <span className="text-danger">
                            -{MoneyMask(item.value, 2)}
                          </span>
                        )}
                      </td>
                    }
                    {userData && (userData.level === UserHelper.Level.Administrator || userData.level === UserHelper.Level.Collector || userData.level === UserHelper.Level.FinancialAdministrator) ?
                      <td>
                        <button
                          className="btn btn-sm btn-square btn-white m-1"
                          onClick={() => setShowModal(true) | setSelected(item)}
                        >
                          <i className="far fa-edit" /> Editar cobrança
                        </button>
                        <button
                          className="btn btn-sm btn-square btn-white m-1"
                          onClick={() => deleteCharge(item)}>
                          <i className="si si-close text-danger" /> Excluir
                        </button>
                      </td>
                      : ""}
                  </tr>
                )
                )}

                {data && data.length > 1 ?
                  <tr>
                    <td>Total: </td>
                    <td></td>
                    <td></td>
                    {subtotal > 0 ?
                      <td className="text-right text-success">
                        {MoneyMask(subtotal)}
                      </td>
                      :
                      <td className="text-right text-danger">
                        {MoneyMask(subtotal)}
                      </td>
                    }
                    <td></td>
                  </tr>
                  : ""}
              </tbody>
            </table>
            : ""}
        </div>
        <PayAllModal
          show={showPayAll}
          total={total}
          customerId={parseInt(customerId)}
          onHide={() =>
            setShowPayAll(false) | loadCustomerPayments()
          }
        />
        <EntryModal
          selected={selected}
          show={showModal}
          customerId={parseInt(customerId)}
          customer={customer}
          onHide={() =>
            setShowModal(false) | loadCustomerPayments() | setSelected({})
          }
        />
        <OperationProcessModal
          data={selectedService}
          show={showServiceModal}
          onHide={() =>
            setShowServiceModal(false) | loadCustomerPayments() | setSelectedService({})
          }
        />
        <FinancialHelpModal
          show={showSupportModal}
          customerId={customerId}
          onHide={() =>
            setShowSupportModal(false)
          } />
      </div>
    </div>
  );
}
