import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Logo from "@/assets/img/logo.jpg";
import "react-toastify/dist/ReactToastify.css";
import ObjectiveController from "@/app/core/base/controllers/ObjectiveController";
import { MoneyMask, NumberMask, ToFixed } from "@/app/core/util/helpers/MoneyHelper";
import { Dropdown } from "react-bootstrap";
import { EvaluationListByDate } from "@/app/core/base/controllers/EvaluationController";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import SalesDescriptionModal from "./components/SalesDescriptionModal";
import ProductModal from "./components/ProductModal";
import AtendanceModal from "./components/AtendanceModal";
import { ArrayMes, GetLastDayOfMonth } from "@/app/core/util/DateTranslater";

function DeferredService(props) {
  const date = new Date();
  const [data, setData] = useState({});
  const [bigNumber, setBigNumber] = useState([]);
  const [evaluation, setEvaluation] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showAtendanceModal, setShowAtendanceModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [month, setMonth] = useState(date.getMonth());
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );
  const [year, setYear] = useState(date.getFullYear());

  const objectiveCtrl = ObjectiveController();

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }
  const timer = 5 * 60 * 1000;

  useEffect(() => {
    loadData();
  }, [startDate, endDate, refresh]);


  useEffect(() => {
    setStartDate(
      getFirstDayOfMonth(year, month)
        .toISOString()
        .substring(0, 10))

    setEndDate(GetLastDayOfMonth(year, month)
      .toISOString()
      .substring(0, 10));
  }, [year, month]);

  async function loadData() {
    setLoading(true)
    await objectiveCtrl.List(startDate, `${endDate}T23:59:00`, async (res) => {
      let result = res;
      // result.product = ((res.salesResult / res.sales) * 100);
      console.log(result);
      await ProcessController().ByDate(startDate, `${endDate}T23:59:00`, (sales) => {
        result.product = ((sales.filter(
          (x) => x.price > 60 && x.price != 0,
        ).length / res.sales) * 100);
        res.salesResult = sales.filter(
          (x) => x.price > 60 && x.price != 0,
        ).length;
        setBigNumber(sales);
      });
      await EvaluationListByDate(startDate, `${endDate}T23:59:00`, (baseResult) => {
        const base = [];
        for (let s in baseResult) {
          if (baseResult[s].organListId != undefined) {
            base.push(baseResult[s]);
          }
        }
        let count = base.length;
        let media = sum(base) / base.length;
        let detractors = base.filter((x) => x.answer <= 6).length;
        let neutral = base.filter((x) => x.answer > 6 && x.answer < 9).length;
        let promoters = base.filter((x) => x.answer > 8).length;
        
        result.meta = parseInt(((res.salesResult / res.sales) * 100 + ((media / res.service) * 100)) / 2);
        result.metaResult = NumberMask(((res.salesResult / res.sales) * 100 + ((media / res.service) * 100)) / 2, 2);
        setData(result);
        console.log("2", result);
        setEvaluation({
          count,
          detractors,
          neutral,
          promoters,
          media,
        });
      });
    });
    setLoading(false)

    setTimeout(function () {
      setRefresh(!refresh);
    }, timer);
  }

  function sum(itens) {
    let base = 0;

    for (let s in itens) {
      base += itens[s].answer;
    }
    return base;
  }

  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        marginBottom: 260,
        height: "100vh",
        backgroundColor: "white",
        paddingBottom: 90,
      }}
      className=""
    >
      <div
        className="container mb-5 "
        style={{
          maxWidth: "1000px",
          margin: "0 auto",
          marginTop: "3em",
          paddingBottom: 90,
        }}
      >
        <div className="row">
          <div
            className="col-lg-12"
            align="center"
          // style={{ marginBottom: "1em" }}
          >
            <img src={Logo} width="100px" />
            <br />
            <Dropdown>
              <Dropdown.Toggle variant="light"></Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="font-size-sm text-muted row">
                  <div className="form-group col-md-6">
                    <span>Data de início</span>
                    <input
                      type="date"
                      className="form-control form-control-alt form-control-sm"
                      value={startDate}
                      onChange={(evt) => setStartDate(evt.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <span>Data fim</span>
                    <input
                      type="date"
                      className="form-control form-control-alt form-control-sm"
                      value={endDate}
                      onChange={(evt) => setEndDate(evt.target.value)}
                    />
                  </div>
                  <hr />
                  <div className="col-md-12 form-group">
                    <span>Mês</span>
                    <select
                      className="form-control form-control-alt"
                      value={month}
                      onChange={(evt) => setMonth(parseInt(evt.target.value))}
                    >
                      <option value={undefined}>Selecione</option>
                      {ArrayMes.map((item, index) => (
                        <option value={index} selected={month === index ? true : false}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12 form-group">
                    <span>Ano</span>
                    <input
                      type="number"
                      className="form-control form-control-alt"
                      value={year}
                      max={date.getFullYear()}
                      min={date.getFullYear() - 1}
                      onChange={(evt) => setYear(parseInt(evt.target.value))}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div>
          <div className="block block-fx-shadow">
            <div className="text-center">
              <div className="row gutters-tiny push">
                <div className="text-center col-xl-12">
                  {/* <h1 className="text-muted font-w700">Comercial</h1> */}
                  <a
                    className="block block-bordered block-rounded block-link-pop text-center"
                    href="javascript:void(0)"
                  >
                    <div className="block-content block-content-full  justify-content-center align-items-center" >
                      <div onClick={() => setShowModal(true)}>
                        {loading === true ? (
                          <div className="block-content text-center text-info">
                            <i className={"fa fa-2x fa-circle-notch fa-spin"}></i>
                          </div>
                        ) : ("")}
                        <h1 className="text-muted font-w700">Vendas mês</h1>
                        {/* <div className="font-w600 mt-2 text-uppercase text-muted">
                        </div> */}
                        {/* <div className="font-size-h1 font-w300 text-black">{data && data.salesResult}</div> */}
                        <div className="font-size-h1 font-w300 text-black">
                          {bigNumber &&
                            bigNumber.filter(
                              (x) => x.price > 60 && x.price != 0,
                            ).length}
                        </div>
                        <div className="font-w600 mt-2 font-size-h1 text-uppercase text-muted">
                          {/* {data &&
                            data.salesResult &&
                            NumberMask((data.salesResult / data.sales) * 100, 2)}
                            % da meta */}
                        </div>
                      </div>
                      <div onClick={() => setShowProductModal(true)}>
                        <div class="progress push" style={{ height: 37 }} >
                          <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style={data && { width: `${data.meta}%`, height: 37 }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                            <span class="font-size-sm text-warning font-w600">{data && data.meta}%</span>

                          </div>
                        </div>
                        <div class="progress-bar bg-secondary text-right p-2" role="progressbar" style={data && { width: `100%`, marginTop: -41 }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                          <span class="font-size-sm font-w600 mr-2">Ultra meta</span>
                        </div>
                        <div class="progress-bar bg-black-25 text-right p-2" role="progressbar" style={data && { width: `90%`, marginTop: -36 }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                          <span class="font-size-sm font-w600 mr-2">Super meta</span>
                        </div>
                        <div class="progress-bar bg-black-25 text-right p-2" role="progressbar" style={data && { width: `80%`, marginTop: -38 }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                          <span class="font-size-sm font-w600 mr-2">Meta</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="text-center col-xl-12" onClick={() => setShowAtendanceModal(true)}>
                  <h1 className="text-muted font-w700 mt-5 mb-0">
                    Atendimento
                  </h1>
                  <a
                    className="block block-bordered block-rounded block-link-pop text-center"
                    href="javascript:void(0)"
                  >
                    <div className="block-content block-content-full justify-content-center align-items-center">
                      {/* <div> */}
                      <div className="font-w600 mt-2 text-uppercase text-muted">
                        Média Atual
                      </div>
                      <div className="font-size-h1 font-w300 text-black">
                        {evaluation &&
                          evaluation.media &&
                          ToFixed(evaluation.media, 1)}
                      </div>
                      <div class="row text-center">
                        <div class="col-4">
                          <div class="py-3 border-right">
                            <div class="item item-circle bg-body-light mx-auto">
                              <p class="font-size-h3 font-w300 mt-3">
                                {evaluation && evaluation.detractors}
                              </p>
                            </div>
                            <p class="text-muted mb-0">Detratores</p>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="py-3 border-right">
                            <div class="item item-circle bg-body-light mx-auto">
                              <p class="font-size-h3 font-w300 mt-3">
                                {evaluation && evaluation.neutral}
                              </p>
                            </div>
                            <p class="text-muted mb-0">Neutros</p>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="py-3">
                            <div class="item item-circle bg-body-light mx-auto">
                              <p class="font-size-h3 font-w300 mt-3">
                                {evaluation && evaluation.promoters}
                              </p>
                            </div>
                            <p class="text-muted mb-0">Promotores</p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="font-w600 mt-2 text-uppercase font-size-h1 text-muted">
                        meta = {data && data.service}
                      </div> */}
                      {/* </div> */}
                    </div>
                  </a>
                </div>
                {/* <div className="text-center col-xl-12">
                    <h3 className="text-muted">Fidelização ao trimestre</h3>
                    <a className="block block-bordered block-rounded block-link-pop text-center" href="javascript:void(0)">
                        <div className="block-content block-content-full  d-flex justify-content-center align-items-center">
                            <div>
                                <div className="font-size-h1 font-w300 text-black">{data && data.recurrenceResult}%</div>
                                <div className="font-w600 mt-2 text-uppercase text-muted">meta = {data && data.recurrence}%</div>
                            </div>
                        </div>
                    </a>
                  </div> */}
                <div className="text-center col-xl-12">
                  <h2 className="text-muted font-w700 mt-4 mb-0">
                    BHAG - Deferir {data && NumberMask(data.bhag, 0)} de
                    processos
                  </h2>
                  <a
                    className="block block-bordered block-rounded block-link-pop text-center mt-0 mb-0"
                    href="javascript:void(0)"
                  >
                    <div className="block-content block-content-full mt-0 mb-0 d-flex justify-content-center align-items-center">
                      <div>
                        <div className="font-w300 font-size-h1 text-black">
                          {data && NumberMask(data.bhagResult, 0)}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SalesDescriptionModal
        showModal={showModal}
        data={data}
        onHide={() => setShowModal(false)}
      />
      <AtendanceModal
        showModal={showAtendanceModal}
        data={data}
        evaluation={evaluation}
        onHide={() => setShowAtendanceModal(false)}
      />
      <ProductModal
        showModal={showProductModal}
        data={data}
        bigNumber={bigNumber}
        evaluation={evaluation}
        onHide={() => setShowProductModal(false)}
      />
      <ToastContainer className="toastr" />
    </div>
  );
}

export default DeferredService;
