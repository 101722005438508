import { toast } from "react-toastify";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import React, { useEffect, useState } from "react";
import { AddOnlyDate, DateTranslate, OnlyDateTimeNow } from "@/app/core/util/DateTranslater";
import { Message } from "@/app/core/util/messages/GeralMessages";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function HomeOpenCustomers({ refreshData, startDate, endDate }) {
    const [raw, setRaw] = useState([]);
    const [filter, setFilter] = useState([]);
    const [query, setQuery] = useState("");
    const chargeController = ServiceChargeController();
    const customerHelper = CustomerHelper();
    // código, nome, valor da cobrança, valor pago, saldo em aberto, Status 

    const cols = [
        { header: "Cód.", isSortable: true, name: "customerCode" },
        { header: "Nome", isSortable: true, name: "name" },
        { header: "Valor da cobrança", isSortable: true, name: "price" },
        { header: "Valor pago", isSortable: true, name: "credit" },
        {
            header: "Saldo em aberto", isSortable: true, name: "balance"
        },
        {
            header: "Status",
            isSortable: true,
            name: "status",
            switch: {
                1: <span className="badge badge-warning">Em aberto</span>,
                2: <span className="badge badge-warning">Em negociação</span>,
                3: <span className="badge badge-success">Ativo</span>,
                4: <span className="badge badge-danger">Atrasado</span>,
                5: <span className="badge badge-danger">Revisão</span>,
                6: <span className="badge badge-danger">Atrasado</span>,
                7: <span className="badge badge-dark">Removido</span>,
            },
        },
    ]

    useEffect(() => {
        loadPendingCustomers()
    }, [refreshData, startDate, endDate])

    async function loadPendingCustomers() {
        let start = AddOnlyDate(-30);
        let end = `${OnlyDateTimeNow()}T23:59:59`;

        if (startDate) {
            start = startDate;
            end = endDate;
        }

        await ServiceChargeController().ListByDate(start, end, res => {
            let results = [];

            for (let s in res) {
                res[s].customerCode = res[s].customer.customerCode;
                res[s].name = res[s].customer.name;
                res[s].prices = processValue(res[s]);


                console.log("Valores", res[s].prices);

                res[s].credit = MoneyMask((res[s].prices.credit), 2);

                res[s].balance = MoneyMask((res[s].price - res[s].prices.credit), 2);

                res[s].charges = MoneyMask((res[s].prices.charges), 2);

                res[s].createdDate = DateTranslate(res[s].createdDate);
                res[s].updatedDate = DateTranslate(res[s].updatedDate);
                res[s].price = MoneyMask(res[s].price, 2);


                if(res[s].status == FinancialHelper().PaymentStatus.Paid){
                    res[s].credit = res[s].price;
                }
                if(res[s].balance != 0 && res[s].status !== FinancialHelper().PaymentStatus.Paid){
                    results.push(res[s]);
                    console.log("---foi", res);
                }else{
                    console.log("nao foi", res);

                }
            }
            setFilter(results);
            setRaw(results);
            console.log("Clientes", res);

        }, error => {
            console.log("Error", error);

        })
    }


    function handleChange(value) {
        setQuery(value);
        if (value.length > 0) {
            setFilter([]);
            setFilter(autocomplete(value));
        } else {
            setFilter(raw);
        }
    }

    function autocomplete(value) {
        const inputValue = value.trim().toLowerCase();
        let itens = raw;
        let newResults = [];

        for (let s in itens) {
            const item = itens[s];
            const name =
                item.name !== undefined ? item.name.trim().toLowerCase() : "";
            const customerCode =
                item.customerCode !== undefined ? item.customerCode : "";
            const cpf = item.cpf !== undefined ? item.cpf.toLowerCase() : "";
            const cnpj = item.cnpj !== undefined ? item.cnpj.toLowerCase() : "";
            const phone = item.phone !== undefined ? item.phone : "";
            const email = item.email !== undefined ? item.email : "";
            const status =
                item.status !== undefined
                    ? customerHelper.StatusTranslate(item.status).toLowerCase()
                    : "";
            const createdDate = item.createdDate;
            console.log(customerCode, value);
            if (
                name.includes(inputValue) ||
                customerCode.includes(value) ||
                cpf.includes(inputValue) ||
                phone.includes(inputValue) ||
                email.includes(inputValue) ||
                status.includes(inputValue) ||
                createdDate.includes(inputValue) ||
                cnpj.includes(inputValue)
            ) {
                newResults.push(item);
            }
        }

        return newResults;
    }

    function processValue(customer) {
        let credit = 0;
        let discounts = 0;
        let reimbursement = 0;
        let charges = 0;
        let outcome = 0;

        for (let s in customer.entries) {
            const res = customer.entries[s];
            if (res.type === FinancialHelper().OperationType.Income) {
                credit += res.value;
            } else if (res.type === FinancialHelper().OperationType.Discount) {
                discounts += res.value;
            } else if (res.type === FinancialHelper().OperationType.Reimbursement) {
                reimbursement += res.value;
            } else if (res.type === FinancialHelper().OperationType.Outcome) {
                outcome -= res.value;
            }
        }

        return { credit, discounts, reimbursement, charges, outcome };
    }

    return (
        <div>
            <div className="block-header bg-dark">
                <div className="block-title text-white">
                    {filter && filter.length + " Clientes"}
                </div>
                <div className="block-tools">
                    <button className="btn btn-dark" onClick={() => loadPendingCustomers()}>
                        <i className="si si-refresh" />
                    </button>
                </div>
            </div>
            <div className="col-md-12 py-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(evt) => handleChange(evt.target.value)}
                />
            </div>
            <Table
                title={filter && filter.length + " Clientes"}
                loading={false}
                tableId="pending"
                data={filter}
                columns={cols}
                hideHeader={true}
                refreshData={() => loadPendingCustomers()}
                setFilter={(filtered) => Functions.ProcessFilter(filtered, filter)}
                viewAction={
                    (selected) =>
                        window.open(`#/customer-profile/${selected.data.customerId}`, "_blank")
                }
            />
        </div>
    )
}