import React from "react";
import ServicesCardComponent from "./ServicesCard";
import DailyRatesComponent from "./DailyRatesCard";
import UsersCardComponent from "@/app/core/layouts/main-components/user/UsersCard";
import MotivationalComponent from "./MotivationalCard";
import TemplateDocsCard from "./TemplateDocsCard";
import ShortcutCard from "./ShortcutCard";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import "react-toastify/dist/ReactToastify.css";
import ExigenciesComponent from "./ExigenciesCard";
import OrgansCard from "./OrgansCard";
import TeamsCard from "./TeamsCard";
import FeedbacksCard from "./FeedbacksCard";
import TermsComponent from "@/app/core/layouts/main-components/documents/terms/TermsComponent";
import ResendComponent from "./ResendComponent";
import ObjectiveComponent from "./ObjectiveComponent";
import SenderCard from "./SenderCard";
import Storage from "@/app/core/util/Storage";
import DiscountRulesCard from "./DiscountRulesCard";
import PlansCard from "./PlansCard";
import TemplateModelCard from "./TemplateModelCard";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

function MainSettingsComponent(props) {
  const userData = Storage.getUserData();

  return (
    <>
      <div className="content">
        <h1 className="flex-sm-fill font-size-h2 font-w400 mb-sm-2">
          Configurações
        </h1>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <Tabs>
              <div
                className="nav nav-tabs nav-tabs-block nav-justified js-tabs-enabled"
                style={{ padding: 7 }}
              >
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="fa fa-users"></i> Usuários
                    </button>
                  </Tab>
                  : ""}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="fa fa-chart-bar"></i> Serviços
                    </button>
                  </Tab>
                  : ""}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="fa fa-tasks"></i> Atividades diárias
                    </button>
                  </Tab>
                  : ""}
                {/* {userData && userData.level == 10 ? */}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="fa fa-images"></i> Mensagens
                    </button>
                  </Tab>
                  : ""}
                {/* : ""} */}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="fa fa-folder"></i> Pastas de templates
                    </button>
                  </Tab>
                  : ""}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="fa fa-link"></i> Atalhos
                    </button>
                  </Tab>
                  : ""}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="far fa-file-alt"></i> Modelos de exigências
                    </button>
                  </Tab>
                  : ""}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="far fa-building"></i> Órgãos
                    </button>
                  </Tab>
                  : ""}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="far fa-object-group"></i> Equipes
                    </button>
                  </Tab>
                  : ""}


                {userData && userData.level == 10 ? (
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="si si-question"></i> Feedbacks
                    </button>
                  </Tab>
                ) : (
                  ""
                )}

                {userData && userData.level == 10 ? (
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="fa fa-file-signature"></i> Termos & Políticas
                    </button>
                  </Tab>
                ) : (
                  ""
                )}
                                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0)  ? 
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="fa fa-robot"></i> 2º Aviso Automático
                  </button>
                </Tab>
                : ""}


                {userData && userData.level == 10 ? (
                  <Tab>
                    <button class="btn btn-dual mr-1">
                      <i class="fa fa-chart-line"></i> Objetivos
                    </button>
                  </Tab>
                ) : (
                  ""
                )}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button class="btn btn-dual mr-1">
                      <i class="si si-envelope-open"></i> Remetentes
                    </button>
                  </Tab>
                  : ""}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      className="btn btn-dual mr-1"
                      alt="Descontos"
                    >
                      <i className="si si-tag"></i> Regras de descontos
                    </button>
                  </Tab>
                  : ""}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Tab>
                    <button
                      type="button"
                      className="btn btn-dual mr-1"
                      alt="Descontos"
                    >
                      <i className="far fa-money-bill-alt"></i> Planos
                    </button>
                  </Tab>
                  : ""}


                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1"
                    alt="Descontos"
                  >
                    <i className="si si-envelope-letter"></i> Templates de emails
                  </button>
                </Tab>

              </div>
              <div className="block block-body">
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <UsersCardComponent toast={toast} />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <ServicesCardComponent toast={toast} />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <DailyRatesComponent toast={toast} />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <MotivationalComponent toast={toast} />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <TemplateDocsCard toast={toast} />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <ShortcutCard toast={toast} />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <ExigenciesComponent toast={toast} />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <OrgansCard />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <TeamsCard />
                  </Panel>
                  : ""}


                {userData && userData.level == 10 ? (
                  <Panel>
                    <FeedbacksCard />
                  </Panel>
                ) : (
                  ""
                )}

                {userData && userData.level == 10 ? (
                  <Panel>
                    <TermsComponent />
                  </Panel>
                ) : (
                  ""
                )}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <ResendComponent />
                  </Panel>
                  : ""}

                {userData && userData.level == 10 ? (
                  <Panel>
                    <ObjectiveComponent />
                  </Panel>
                ) : (
                  ""
                )}
                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <SenderCard />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <DiscountRulesCard />
                  </Panel>
                  : ""}

                {userData && (userData.level === UserHelper.Level.Administrator || userData.role > 0) ?
                  <Panel>
                    <PlansCard />
                  </Panel>
                  : ""}


                <Panel>
                  <TemplateModelCard />
                </Panel>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
      <ToastContainer className="toastr" />
    </>
  );
}

export default MainSettingsComponent;
