import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import { CustomerAllPayments } from "@/app/core/base/controllers/CustomerController";
import { SinteticAccountCols } from "@/app/core/layouts/main-components/customer/CustomerPendingCols";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
// import ReactToExcel from "react-html-table-to-excel";

export default function SinteticAccount({ refreshData, startDate, endDate }) {
    const [filter, setFilter] = useState([]);
    const [raw, setRaw] = useState([]);
    const [loading, setLoading] = useState(false);
    const financialHelper = FinancialHelper();
    const [query, setQuery] = useState("");

    useEffect(() => {
        loadPendingCustomers()
    }, [refreshData, startDate, endDate])

    async function loadPendingCustomers() {
        setLoading(true);
        await CustomerAllPayments(startDate, `${endDate}T23:59:59`, res => {
            setLoading(false);
            let result = [];
            for (let s in res) {
                let item = res[s];
                item.prices = processValue(res[s]);
                item.customerName = item.name;

                item.credit = (item.prices.credit).toLocaleString();
                item.creditValue = parseInt(item.prices.credit);

                item.debit = (item.prices.debit).toLocaleString();
                item.debitValue = parseInt(item.prices.debit);
                let calculo = (item.prices.credit + item.prices.debit);
                if (item.balance < 0) {
                    calculo = calculo + item.balance;
                    item.balance = (item.balance).toLocaleString();
                    item.balanceValue = parseInt(item.balance);
                } else {
                    calculo = calculo + item.balance;
                    item.balance = (item.balance).toLocaleString();
                    item.balanceValue = parseInt(item.balance);
                }

                item.calculo = calculo;
                item.result = (calculo).toLocaleString();
                item.resultValue = parseInt(calculo);

                result.push(item);
            }
            console.log("Resultado", result);
            setFilter(result);
            setRaw(result);
        })
    }

    function processValue(customer) {
        let credit = 0;
        let debit = 0;

        // for (let s in customer.charges) {
        //     const item = customer.charges[s];
        //     if (item.status === financialHelper.PaymentStatus.Paid) {
        //         credit += item.price;
        //     }
        // }

        for (let s in customer.entries) {
            const item = customer.entries[s];
            if (item.description == "Taxa Cartório==> Havenk Construção Civil Ltda") {
                console.log("Cccccc", item, item.type === financialHelper.OperationType.Debit)
            }
            if (item.type === financialHelper.OperationType.Debit) {
                debit -= item.value;
            }
            if (item.type === financialHelper.OperationType.Discount || item.type === financialHelper.OperationType.Reimbursement || item.type === financialHelper.OperationType.Credit) {
                credit += item.value;
            }
            if(item.type === financialHelper.OperationType.Negocied && item.value > 0){
                credit += item.value;
            }else if(item.type === financialHelper.OperationType.Negocied && item.value < 0){
                debit -= item.value;
            }
        }

        return { debit, credit };
    }


    function handleChange(value) {
        setQuery(value);
        if (value.length > 0) {
            setFilter([]);
            setFilter(autocomplete(value));
        } else {
            setFilter(raw);
        }
    }

    function autocomplete(value) {
        const inputValue = value.trim().toLowerCase();
        let itens = raw;
        let newResults = [];

        for (let s in itens) {
            const item = itens[s];
            const customerName = item.customerName.toLowerCase();
            const customerCode = item.customerCode.toLowerCase();
            const credit = item.credit;
            const debit = item.debit;
            const result = item.result;

            if (
                (customerName && customerName.includes(inputValue)) ||
                (customerCode && customerCode.includes(inputValue)) ||
                (credit && credit.includes(inputValue)) ||
                (debit && debit.includes(inputValue)) ||
                (result && result.includes(inputValue))
            ) {
                newResults.push(item);
            }
        }
        return newResults;
    }

    return (
        <div>
            <div className="block-header">
                <div className="block-title">
                    {/* {filter && filter.length + " Clientes com saldo em aberto/pendente"} */}
                </div>
                <div className="block-tools">
                    {/* <ReactToExcel
                        className="btn btn-info"
                        table="sintetics"
                        filename={`Relatorio - Conta Sintética - periodo: ${startDate} - ${endDate}`}
                        sheet="sheet 1"
                        buttonText="Exportar"
                    /> */}
                </div>
            </div>
            <div className="col-md-12 py-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(evt) => handleChange(evt.target.value)}
                />
            </div>
            {loading === true ? (
          <div className="block-content text-center">
            <i className={"fa fa-circle-notch fa-spin fa-2x text-info"}></i>
          </div>
        ) : (
            <Table
                title={filter && filter.length + " Clientes"}
                loading={false}
                tableId="sintetics"
                data={filter}
                columns={SinteticAccountCols}
                hideHeader={true}
                refreshData={() => loadPendingCustomers()}
                setFilter={(filtered) => Functions.ProcessFilter(filtered, filter)}
                viewAction={
                    (selected) =>
                        window.open(`#/customer-profile/${selected.data.id}`, "_blank")
                }
            />)}
        </div>
    )
}