import React, { useEffect, useState, useRef } from "react";
import { OnlyDateTranslate } from "@/app/core/util/DateTranslater";
import ReportController from "@/app/core/base/controllers/ReportController";
import CustomerListCard from "../../oportunities/main/CustomerListCard";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function DebtorsMovement({ startDate, endDate }) {
    const [filter, setFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);

    const colsDiscount = [
        { header: "Cód.", isSortable: true, name: "customerCode" },
        {
          header: "Nome",
          isSortable: true,
          name: "name",
          style: {
            maxWidth: "220px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        },
        { header: "Valor", isSortable: true, name: "balance" },
        { header: "Data de vencimento", isSortable: true, name: "createdDate" },
        { header: "Último movimento", isSortable: true, name: "updatedDate" },
      ];
    
    useEffect(() => {
        reloadDiscount()
    }, [startDate, endDate])

    async function reloadDiscount() {
        setLoading(true);
        await ReportController().DebtorsMovement(res => {
            console.log(res)
            for(let s of res){
                s.balance = MoneyMask(s.balance);
                s.createdDate = OnlyDateTranslate(s.createdDate);
                // s.updatedDate = DateTranslate(s.updatedDate);
                console.log(s);
                
            }

            setFilter(res)
    });
        setLoading(false);
    }

    return (
        <div>
            <div className="block-header mt-5">
                <div className="block-tools d-flex">
                </div>
            </div>
            {loading === true ? (
                <div className="block-content text-center">
                    <i className={"fa fa-circle-notch text-info fa-2x fa-spin"}></i>
                </div>
            ) : ""
        }
        <CustomerListCard
                title={"Devedores que movimentam"}
                infos={filter && filter}
                cols={colsDiscount}
                isOpen={true}
                reload={() => reloadDiscount()}
              />
        </div>
    )
}