import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";

export default function ReminderTutorialModal({ show, onHide }) {
    const [loading] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <CustomModal
            title="Tutorial"
            show={show}
            isLoading={loading}
            onHide={onHide}>
                <strong>Introdução</strong>
            <p>
            Facilite suas cobranças e melhore a comunicação com seus clientes! Com a nossa ferramenta, você pode enviar e agendar lembretes diretamente pela plataforma, garantindo que tudo fique em dia.
            </p>
            
            <strong>Principais Funcionalidades:</strong>
                <div>1. Lembretes Personalizados</div>
            <ul>
                <li>Crie lembretes para compromissos, aniversários, prazos de trabalho e muito mais, adaptando cada notificação às suas necessidades.</li>
            </ul>
                <div><br/>2. Interface Intuitiva</div>
                            <ul>
                <li>Design simples e fácil de usar, permitindo que qualquer pessoa, independentemente de habilidades tecnológicas, utilize a ferramenta sem dificuldades</li>
                            </ul>
                <div><br/>3. Recorrência de Lembretes</div>
                            <ul>
                <li>Configure a frequência de envio dos lembretes (a cada 7, 15 ou 30 dias) para garantir que nenhuma tarefa fique esquecida.</li>
                        </ul>
            <strong>Benefícios:</strong>
            <ul>
                <li>Eficiência Operacional: Facilite sua operação enviando lembretes para envio de Documentos e Valores ou simples avisos para seus clientes.</li>
                <li>Aumentoda Produtividade: Com lembretes eficazes, você pode se concentrar em suas atividades, sabendo que não vai esquecer de nada importante.</li>
                <li>Redução do Estresse: Com esta ferramenta suas tarefas e cobranças ficam mais organizadas para você ficar mais tranquilo e preparado.</li>
                <li>Flexibilidade: Utilize a ferramenta em qualquer dispositivo: computador, tablet ou smartphone.</li>
            </ul>
            
            <strong>Passo a Passo:</strong>
            <ul>
                <div>1. Clique em "Novo Lembrete": Inicie a criação de um novo lembrete.</div>
                <br/>
                <div>2. Defina o tipo de lembrete:Personalize o lembrete conforme necessário, podendo solicitar documentos, comprovantes de pagamento ou enviar lembretes simples.</div>
                <br/>
                <div>3. Adicione um contato: Preencha o nome, e-mail e telefone do contato que receberá o lembrete.</div>
                <br/>
                <div>4. Preencha a empresa responsável: Caso aplicável, registre a empresa relacionada ao lembrete.</div>
                <br/>
                <div>5. Título e Mensagem: Defina o título do lembrete que aparecerá no menu inicial e a mensagem que será enviada ao contato. Utilize frases sugeridas para facilitar.</div>
                <br/>
                <div>6. Upload obrigatório: Se o lembrete requer um anexo, defina essa opção para que o contato precise enviar um arquivo como resposta.</div>
                <br/>
                <div>7. Frequência de envio e início: Determine a frequência dos lembretes (7, 15 ou 30 dias) e defina a data de início dos envios.</div>
            </ul>
            <strong>Cuidados Recomendados:</strong>
            <p>Antes de utilizar a agenda de lembretes, considere as seguintes recomendações:</p>
                <div>1. Verificação de Dados</div>
            <ul>
                <li>Confirme que todas as informações da empresa e dos contatos estão corretas e atualizadas para garantir o encaminhamento adequado dos lembretes.</li>
            </ul>
                <div>2.Privacidade de Dados.</div>
            <ul>
                <li>Não registramos os dados dos seus clientes, e não aparecemos nos lembretes enviados.</li>
            </ul>
                <div>3. Acompanhamento das Respostas.</div>
            <ul>
                <li>Fique atento para monitorar as respostas aos lembretes enviados.</li>
            </ul>
            <strong>Conclusão:</strong>
            
            <p>Agora você está pronto para utilizar nossa ferramenta de lembretes de forma eficaz! Organize suas cobranças e melhore a comunicação com seus clientes com apenas alguns cliques. Se precisar de ajuda, nossa equipe de suporte está à disposição!</p>
        </CustomModal>
    );
}
