import React from "react";

export function BigNumbers({ title, value, hint }) {

    return (
        <a
            className="border-info border-left border-3x block block-rounded block-link-pop" href="javascript:void(0)">
            <div className="block-header">
                <div className="block-title">{title}</div>
                <div className="block-tools">
                    {hint != undefined ?
                        <button className="btn btn-dual" title={hint}>
                            <i className="far fa-question-circle" />
                        </button>
                        : ""}
                </div>
            </div>
            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                <div className="ml-3 text-center">
                    <p className="font-size-h3 font-w300 mb-0">
                        {value}
                    </p>
                    <p className="text-muted mb-0 text-success">-</p>
                    <p className="text-muted mb-0"></p>
                </div>
                {/* <div className="ml-3 text-center">
                    <p className="font-size-h3 font-w300 mb-0">
                        {current}
                    </p>
                    <p className="text-muted mb-0 text-success">{currentDiference}%</p>
                    <p className="text-muted mb-0">Atual</p>
                </div> */}
            </div>
        </a>
    )
}