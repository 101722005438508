import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import { BillHistoryCols } from "@/app/core/layouts/main-components/customer/CustomerPendingCols";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { ArrayMes, DateTranslate, OnlyDateTranslateToInput } from "@/app/core/util/DateTranslater";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import BillModal from "./BillModal";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function BillOfExchange({ refreshData, startDate, endDate }) {
    const [raw, setRaw] = useState([]);
    const [filter, setFilter] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState({});
    const [query, setQuery] = useState("");

    const chargeController = ServiceChargeController();

    useEffect(() => {
        loadBillOfExchange()
    }, [refreshData, startDate, endDate])

    async function loadBillOfExchange() {
        await chargeController.Resume(res => {
            console.log("Clientes", res);
            for (let s in res) {
                console.log("Clientes", ArrayMes[res[s].month-1], res[s]);
                res[s].month = `${ArrayMes[res[s].month-1]}/${res[s].year}`;
                let total = 0;
                let pending = 0;
                let done = 0;
                for (let i in res[s].chargeList) {
                    const item = res[s].chargeList[i];
                    total += item.price;
                    if (item.status == FinancialHelper().PaymentStatus.Paid) {
                        done += item.price;
                    } else {
                        pending += item.price;
                    }

                    res[s].chargeList[i].customerCode = `${item.customer.customerCode}`;
                    res[s].chargeList[i].customerName = `${item.customer.name}`;
                    res[s].chargeList[i].billet = `S.I.`;
                    res[s].chargeList[i].ticket = `S.I.`;
                    res[s].chargeList[i].charges = `${MoneyMask(item.price, 2)}`;
                    res[s].chargeList[i].dueDate = DateTranslate(item.dueDate);
                    res[s].chargeList[i].createdDate = DateTranslate(item.createdDate);
                    res[s].chargeList[i].updatedDate = DateTranslate(item.updatedDate);
                    res[s].chargeList[i].customerStatus = 0;


                    let discounts = 0;
                    let payments = 0;

                    for (let d in item.entries) {
                        const theEntry = item.entries[d];
                        if (theEntry.type == FinancialHelper().OperationType.Discount) {
                            discounts += theEntry.value;
                        }
                        if (theEntry.type == FinancialHelper().OperationType.Credit) {
                            payments += theEntry.value;
                        }
                    }
                    res[s].chargeList[i].open = `${MoneyMask(item.price + payments, 2)}`;
                    res[s].chargeList[i].discount = `${MoneyMask(discounts, 2)}`;
                    if (item.customer.status == CustomerHelper().Status.Blocked) {
                        res[s].chargeList[i].customerStatus = CustomerHelper().Status.Blocked;
                    }
                    if (payments > total) {
                        res[s].chargeList[i].customerStatus = CustomerHelper().Status.Active;
                    }
                }

                res[s].total = `${(total).toLocaleString()}`;
                res[s].done = `${(done).toLocaleString()}`;
                res[s].pending = `${(pending).toLocaleString()}`;
            }
            setFilter(res);
        });
    }

    function openMonth(item) {
        // setSelected(item.data);
        // setShowModal(true);
        window.open(`#/collector/?startDate=${OnlyDateTranslateToInput(item.data.startDate)}&endDate=${OnlyDateTranslateToInput(item.data.endDate)}`)
    }

    function handleChange(value) {
        setQuery(value);
        if (value.length > 0) {
            setFilter([]);
            setFilter(autocomplete(value));
        } else {
            setFilter(raw);
        }
    }


    function autocomplete(value) {
        const inputValue = value.trim().toLowerCase();
        let itens = raw;
        let newResults = [];

        for (let s in itens) {
            const item = itens[s];
            const customerCode = item.customerCode.trim().toLowerCase();
            const customerName = item.customerName.trim().toLowerCase();
            const billet = item.billet.trim().toLowerCase();
            const ticket = item.ticket.trim().toLowerCase();
            const charges = item.charges.trim().toLowerCase();
            const dueDate = item.dueDate.trim().toLowerCase();
            const createdDate = item.createdDate.trim().toLowerCase();
            const updatedDate = item.updatedDate.trim().toLowerCase();

            if (
                customerCode.includes(inputValue) ||
                customerName.includes(inputValue) ||
                billet.includes(inputValue) ||
                ticket.includes(inputValue) ||
                charges.includes(inputValue) ||
                dueDate.includes(inputValue) ||
                createdDate.includes(inputValue) ||
                updatedDate.includes(inputValue)
            ) {
                newResults.push(item);
            }
        }

        return newResults;
    }


    return (
        <div>
            <div className="block-header">
                <div className="block-tools">
                    {/* <ReactToExcel
                        className="btn btn-info"
                        table="billOfExhange"
                        filename={`Relatorio - Cobranças emitidas - periodo: ${startDate} - ${endDate}`}
                        sheet="sheet 1"
                        buttonText="Exportar"
                    /> */}
                </div>
            </div>
            <div className="col-md-12 py-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(evt) => handleChange(evt.target.value)}
                />
            </div>
            <Table
                title={filter && filter.length + " Clientes"}
                tableId="billOfExhange"
                loading={false}
                data={filter}
                columns={BillHistoryCols}
                hideHeader={true}
                refreshData={() => loadBillOfExchange()}
                setFilter={(filtered) => Functions.ProcessFilter(filtered, filter)}
                viewAction={
                    (selected) =>
                        openMonth(selected)
                } />

            <BillModal
                show={showModal}
                onHide={() => setShowModal(false)}
                selected={selected}
            />
        </div>
    )
}