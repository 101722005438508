import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { MoneyMask, NumberMask, ToFixed } from "@/app/core/util/helpers/MoneyHelper";

export default function AtendanceModal({
    showModal,
    onHide,
    data,
    evaluation,
}) {
    const [budget, setBudget] = useState([]);
    const organHelper = OrganListHelper();

    useEffect(() => {
    }, []);

    return (
        <Modal
            title={""}
            show={showModal}
            onHide={onHide}>

            <div className="row d-flex align-items-center justify-content-between">
                <div className="col-5 text-center">
                    <div className="font-w600 mt-2 text-uppercase text-muted">
                        Média atual
                    </div>
                    <div className="font-size-h2 py-4 font-w300 text-success">
                        {evaluation && evaluation.media && ToFixed(evaluation.media, 1)}
                    </div>
                </div>
                <div className="col-2 text-center">
                    <div className="font-size-h1">/</div>

                </div>
                <div className="col-5 text-center">
                    <div className="font-w600 mt-2 text-uppercase text-muted">
                        Meta estipulada
                    </div>
                    <div className="font-size-h2 py-4 font-w300 text-success">
                        {data.service}
                    </div>

                </div>
                <div className="col-12 border-top border-2x text-center pt-3 mt-2">
                    <div className="font-size-h1 font-w300 text-black">
                        Já alcançamos <span className="text-success">{evaluation && evaluation.media && `${ToFixed(evaluation.media / data.service * 100)}`}%</span> da meta de atendimento
                    </div>
                </div>
            </div>

        </Modal>
    );
}
