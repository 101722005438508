import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import HomeOpenCustomers from "@/app/core/layouts/main-components/financial/HomeOpenCustomers";
import DebtorCard from "@/app/core/layouts/main-components/financial/DebtorCard";
import { ArrayMes, CompleteDateToPast, GetLastDayOfMonth, OnlyDateTranslate } from "@/app/core/util/DateTranslater";
import { Dropdown } from "react-bootstrap";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import WhatsAppController from "@/app/core/base/controllers/WhatsAppController";
import CopyToClipboard from "react-copy-to-clipboard";
import AccountController from "@/app/core/base/controllers/UsersController";
import { CustomerList } from "@/app/core/base/controllers/CustomerController";
import { TemplateModelController } from "@/app/core/base/controllers/TemplateModelController";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import Storage from "@/app/core/util/Storage";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

export default function MainCustomer(props) {
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const userData = Storage.getUserData();
  const [change, setChange] = useState(date.getTime());
  const [filter, setFilter] = useState({
    id: false,
    nossoNumero: false,
    customerName: false,
    chargeName: false,
    value: false,
    discount: false,
    dueDate: false,
    status: false,
    lastSend: false,
    desc: true,
  });

  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    GetLastDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10));
  const [refreshData, setRefreshData] = useState(false);
  const [data, setData] = useState([]);
  const [infos, setInfos] = useState({templateId: 0, chargeId: 0});
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if(userData.level === UserHelper.Level.Customer){
      window.location = "/";
    }
    loadCharges()
    loadTemplates()
  }, [startDate, endDate])
  
  function loadTemplates(){
    if(templates.length === 0){
      TemplateModelController.List(res => setTemplates(res));
    }
  }

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function handleChange(evt, type){
    setInfos(OnDataChange(infos, evt, type))
  }

  function loadCharges() {
    setLoading(true);
    ServiceChargeController().ListByDate(startDate, endDate, res => {
      for(let s of res){
        s.checked = false;
        s.discount = Math.abs(s.discount);
        s.price = Math.abs(s.price);
        s.customerName = `${s.customer.customerCode} - ${s.customer.name}`;
        s.name = `Fatura - ${new Date(s.createdDate).getMonth()+1}/${new Date(s.createdDate).getFullYear()}`;
      }
      setLoading(false);
      let late = res.filter(x => x.status === FinancialHelper().PaymentStatus.Late);
      let pending = res.filter(x => x.status === FinancialHelper().PaymentStatus.Pending);
      let paid = res.filter(x => x.status === FinancialHelper().PaymentStatus.Paid);
      let others = res.filter(x => x.status !== FinancialHelper().PaymentStatus.Paid && x.status !== FinancialHelper().PaymentStatus.Pending  && x.status !== FinancialHelper().PaymentStatus.Late);

      setData(
        [
          ...late,
          ...pending,
          ...paid,
          ...others,
        ]
      )
    })
  }

  function handleSelection(month) {
    setStartDate(getFirstDayOfMonth(date.getFullYear(), month)
      .toISOString()
      .substring(0, 10))

    setEndDate(GetLastDayOfMonth(date.getFullYear(), month)
      .toISOString()
      .substring(0, 10))
  }


  function notifyChange() {
    setChange(date.getTime());
  }

  function viewCustomer(item) {
    window.open(`#/customer-profile/${item.customerId}`, "blank")
  }
  function onFilter(newFilter) {
    newFilter.desc = !filter.desc;
    setFilter(newFilter);

    let newData = data;

    if (newFilter.id) {
        newData = newData.sort((a, b) => newFilter.desc ? b.id - a.id : a.id - b.id);
    }
    if (newFilter.nossoNumero) {
        newData = newData.sort((a, b) => newFilter.desc ? b.nossoNumero - a.nossoNumero : a.nossoNumero - b.nossoNumero);
    }
    if (newFilter.customerName) {
        newData = newData.sort((a, b) => newFilter.desc ? b.customerName : a.customerName);
    }
    if (newFilter.chargeName) {
        newData = newData.sort((a, b) => newFilter.desc ? b.chargeName: a.chargeName);
    }
    if (newFilter.value) {
        newData = newData.sort((a, b) => newFilter.desc ? b.price - a.price : a.price - b.price);
    }
    if (newFilter.discount) {
        newData = newData.sort((a, b) => newFilter.desc ? b.discount - a.discount : a.discount - b.discount);
    }
    if (newFilter.dueDate) {
        newData = newData.sort((a, b) => newFilter.desc ? new Date(b.dueDate) - new Date(a.dueDate) : new Date(a.dueDate) - new Date(b.dueDate));
    }
    if (newFilter.status) {
        newData = newData.sort((a, b) => newFilter.desc ? b.status - a.status : a.status - b.status);
    }
    if (newFilter.lastSend) {
        newData = newData.sort((a, b) => newFilter.desc ? new Date(b.updatedDate) - new Date(a.updatedDate) : new Date(a.updatedDate) - new Date(b.updatedDate));
    }

    setData(newData);
    notifyChange();
}

  async function deleteCharge(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      setLoading(true);

      await ServiceChargeController().Delete(item.id, res => {
        setLoading(false);
        toast.success(Message.DeleteSuccess);
        loadCharges();
      })
    }
  }

  async function deleteAll() {
    if (window.confirm(Message.DeleteConfirm)) {
      setLoading(true);
      try {
        await Promise.all(
          data.map(s => ServiceChargeController().Delete(s.id, () => { }))
        );
      } catch (error) {
        setLoading(false);
        loadCharges();
      } finally {
        setLoading(false);
        loadCharges();
      }
    }
  }


  async function sendByWhats(item) {
    if (window.confirm(Message.Confirm)) {
      setLoading(true);
      await WhatsAppController().SendCharge([item.id], res => {
        toast.success(Message.Saved);
        ServiceChargeController().Update(item, res => {
          setLoading(false);
          loadCharges()
        });
      });
    }
  }

  async function sendByEmail(item) {
    if (window.confirm(Message.Confirm)) {
      setLoading(true);
      await WhatsAppController().SendEmailCharge([item.id], res => {
        toast.success(Message.Saved);
        ServiceChargeController().Update(item, res => {
          setLoading(false);
          loadCharges()
        });
      });
    }
  }

  async function sendCustomTemplate(item) {
    if(infos.templateId === 0){
      toast.error(Message.PendingData);
    }else if (window.confirm(Message.Confirm)) {
      setLoading(true);
      await WhatsAppController().SendCustomEmailCharge([item.id], infos.templateId, res => {
        toast.success(Message.Saved);
        ServiceChargeController().Update(item, res => {
          setLoading(false);
          loadCharges()
        });
      });
    }
  }

  function handleChecked(checked, index) {
    let newData = data;
    newData[index].checked = checked;

    setData(newData);
    notifyChange();
  }

  function onSeleceAll() {
    let hasChecked = data.filter(x => x.checked == true);
    let newData = data;
    if (hasChecked.length > 0) {
      for (let s of newData) {
        s.checked = false;
      }
    } else {
      for (let s of newData) {
        s.checked = true;
      }
    }
    setData(newData);
    notifyChange();
  }

  async function sendListByWhats() {
    if (window.confirm(Message.Confirm)) {
      let selecteds = data.filter(x => x.checked == true);
      let ids = [];
      if (selecteds.length == 0) {
        toast.error("Selecione os clientes para enviar");
        return;
      }
      for (let s of selecteds) {
        ids.push(s.id);
      }
      setLoading(true);
      
      await WhatsAppController().SendCharge(ids, async res => {
        toast.success(Message.Saved);
        for (let s of selecteds) {
          setLoading(false);
          await ServiceChargeController().Update(s, res => {
            loadCharges()
          });
        }
        onSeleceAll();
      });
    }
  }
  async function sendListByEmail() {
    if (window.confirm(Message.Confirm)) {
      let selecteds = data.filter(x => x.checked == true);
      let ids = [];
      if (selecteds.length == 0) {
        toast.error("Selecione os clientes para enviar");
        return;
      }
      for (let s of selecteds) {
        ids.push(s.id);
      }
      setLoading(true);

      await WhatsAppController().ScheduleSend(ids, async res => {
        toast.success(Message.Saved);
        setLoading(false);
        for (let s of selecteds) {
          await ServiceChargeController().Update(s, res => {
            loadCharges()
          });
        }
        onSeleceAll();
      });
    }
  }

  return (
    <div className="ml-1 mr-1">
      <Loading loading={loading} />
      <div className="row">
        <div className="col-md-6">
          <div className="content-heading">Boletos lançados</div>
        </div>
        <div className="col-md-6 text-right">
          <div className="d-flex">
            <div className="form-group mr-2">
              <span>Mês de consulta</span>
              <select
                className="form-control"
                defaultValue={date.getMonth()}
                onChange={(evt) => handleSelection(evt.target.value)}
              >
                <option>Selecione</option>
                {ArrayMes.map((item, index) => (
                  <option value={index}>{item}</option>
                ))}
              </select>
            </div>
            <Dropdown>
              <Dropdown.Toggle className="mt-4">
                Período personalizado
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="form-group">
                  <span>Data de início</span>
                  <input
                    type={"date"}
                    className="form-control"
                    value={startDate}
                    onChange={(evt) => setStartDate(evt.target.value)}
                  />
                </div>
                <div className="form-group">
                  <span>Data fim</span>
                  <input
                    type={"date"}
                    className="form-control"
                    value={endDate}
                    onChange={(evt) => setEndDate(evt.target.value)}
                  />
                </div>
                <button className="btn btn-hero-info btn-block mt-4" onClick={() => setRefreshData(!refreshData)}>
                  <i className="fa fa-search mr-1" />
                  Buscar
                </button>
              </Dropdown.Menu>
            </Dropdown>
            <span>
              <button className="mt-4 btn btn-danger" onClick={() => deleteAll()}><i className="fa fa-exclamation-triangle" /> Excluir todos do período</button>
            </span>
          </div>
          <div className="d-flex py-3">
            <span>({data && data.filter(x => x.checked == true).length}) Selecionados</span>
            <button className="ml-3 mr-3 btn btn-info" onClick={() => onSeleceAll()}>{data && data.filter(x => x.checked == true).length === 0 ? "Selecionar todos" : "Desselecionar todos"}</button>
            <Dropdown>
              <Dropdown.Toggle variant="light">
                <i className="si si-envelope-letter" /> Enviar {data && data.filter(x => x.checked == true).length} Selecionados por
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="text-center">
                  <button
                    type="button"
                    // disabled={disabledWhats}
                    className="btn btn-warning btn-block"
                    onClick={() => sendListByWhats()}
                  >
                    <i className="fab fa-whatsapp" />
                    <span className="ml-1 d-none d-sm-inline-block"></span> WhatsApp
                  </button>
                  <button
                    type="button"
                    className="btn btn-info btn-block"
                    onClick={() => sendListByEmail()}
                  >
                    <i className="fa fa-fw fa-envelope" />
                    <span className="ml-1 d-none d-sm-inline-block"></span> Email
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <table className="table table-hover table-bordered" >
          <thead>
            <th className="cursor" onClick={()=> onFilter({id:true, desc: !filter.desc})}>
             {filter && filter.id === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.id === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Id
            </th>
            <th className="cursor" onClick={()=> onFilter({nossoNumero:true, desc: !filter.desc})}>
             {filter && filter.nossoNumero === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.nossoNumero === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Nosso número
            </th>
            <th className="cursor" onClick={()=> onFilter({customerName:true, desc: !filter.desc})}>
             {filter && filter.customerName === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.customerName === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Cliente
            </th>
            <th className="cursor" onClick={()=> onFilter({chargeName:true, desc: !filter.desc})}>
             {filter && filter.chargeName === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.chargeName === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Nome
            </th>
            <th className="cursor" onClick={()=> onFilter({value:true, desc: !filter.desc})}>
             {filter && filter.value === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.value === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Valor
            </th>
            <th className="cursor" onClick={()=> onFilter({discount:true, desc: !filter.desc})}>
             {filter && filter.discount === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.discount === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Desconto
            </th>
            <th className="cursor" onClick={()=> onFilter({dueDate:true, desc: !filter.desc})}>
             {filter && filter.dueDate === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.dueDate === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Vencimento
            </th>
            <th className="cursor" onClick={()=> onFilter({status:true, desc: !filter.desc})}>
             {filter && filter.status === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.status === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Status
            </th>
            <th className="cursor" onClick={()=> onFilter({lastSend:true, desc: !filter.desc})}>
             {filter && filter.lastSend === true && filter.desc === true? 
             <i className="si si-arrow-down"/> 
             : filter.lastSend === true && filter.desc === false ? <i className="si si-arrow-up"/> : ""
             } Último envio
            </th>
            <th></th>
          </thead>
          <tbody>
            {change && data && data.map((item, index) => {
              let color = "";
              let success = [];
              let failed = [];
              
              for(let s of item.users){
                if(s.validPhone === true || s.sendedWhats === true && s.receivedWhats === true){
                  success.push(s);
                }else if(s.sendedWhats === true && s.receivedWhats !== true){
                  failed.push(s);
                }
              }

              if(success.length > 0){
                color = "success text-white";
              }
              if(failed.length > 0){
                color = "danger text-white";
              }
              console.log("Cor", color);
              
              return <tr  className={`font-size-sm border border-3x bg-${color}`}>
                <td>
                  <div class="custom-control custom-checkbox custom-control-lg custom-control-inline">
                    <input type="checkbox" className="custom-control-input"
                      id={`boleto-${item.id}`}
                      name={`boleto-${item.id}`}
                      checked={item.checked}
                      onChange={evt => handleChecked(evt.target.checked, index)} />
                    <label class="custom-control-label" for={`boleto-${item.id}`}>{item.id}</label>
                  </div>
                </td>
                <td>{item.nossoNumero}</td>
                <td>{item.customerName}

                  {success.length > 0 ? 
                  <div>
                    {success.map(item => (
                      <span className="badge badge-success">Recebido: {item.firstName} - {item.phone}</span>
                    ))}
                  </div>
                  : ""}
                  {failed.length > 0 ? 
                  <div>
                    {failed.map(item => (
                      <span className="badge badge-danger">Falha: {item.firstName} - {item.phone}</span>
                    ))}
                  </div>
                  : ""}
                </td>
                <td>{item.name}</td>
                <td>{MoneyMask(item.price, 2)}</td>
                <td>{MoneyMask(item.discount)}</td>
                <td>{OnlyDateTranslate(item.dueDate)}</td>
                <td>{FinancialHelper().PaymentStatusTranslateBadge(item.status)}</td>
                <td>{item.createdDate != item.updatedDate && CompleteDateToPast(item.updatedDate)}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" className="btn btn-dual btn-sm">
                      <i className="si si-envelope-letter" /> Enviar por
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="text-center">
                        <button
                          type="button"
                          // disabled={disabledWhats}
                          className="btn btn-sm btn-dual btn-block"
                          onClick={() => sendByWhats(item)}
                        >
                          <i className="fab fa-whatsapp" />
                          <span className="ml-1 d-none d-sm-inline-block"></span> WhatsApp
                        </button>
                        <hr/>

                        <button
                          type="button"
                          className="btn btn-sm btn-dual btn-block"
                          onClick={() => sendByEmail(item)}
                        >
                          <i className="fa fa-fw fa-envelope" />
                          <span className="ml-1 d-none d-sm-inline-block"></span> Email
                        </button>
                        <hr/>

                        <CopyToClipboard
                          text={`https://app.hasa.com.br/#/bill/${item.id}`}
                          onCopy={() => toast.info("Copiado para a área de transferência")}
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-dual btn-block"
                            title="copiar"
                          >
                            <i className="far fa-copy" /> Copiar link
                          </button>
                        </CopyToClipboard>
                        <hr/>
                        <div className="block block-bordered block-info">
                          <div className="block-header">
                            <div className="block-title">Enviar template personalizado:</div>
                          </div>
                            <div className="form-group">
                              <span>Selecionar template</span>
                              <select className="form-control" name="templateId" onChange={evt => handleChange(evt, FieldTypeObj.Number)}>
                                <option>Selecione</option>
                                {templates && templates.map(template => (
                                  <option value={template.id}>{template.name}</option>
                                ))}
                              </select>

                              <button className="btn btn-info btn-block" onClick={() => sendCustomTemplate(item)}>Enviar Template</button>
                            </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button className="btn btn-sm btn-dual" onClick={() => viewCustomer(item)}><i className="far fa-eye" /></button>
                  <button className="btn btn-sm btn-dual" onClick={() => deleteCharge(item)}><i className="si si-close text-danger" /></button>
                </td>
              </tr>

})}
          </tbody>
        </table>
      </div>
    </div>
  );
}
