import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { NumberMask, ToFixed } from "@/app/core/util/helpers/MoneyHelper";

export default function ProductModal({
    showModal,
    onHide,
    data,
    bigNumber,
    evaluation
}) {
   

    useEffect(() => {
        console.log("Chamou", showModal)
    }, [showModal]);

    const calculateMetaValues = (sales) => {
        console.log(sales, {
            meta: sales * 0.8,
            superMeta: sales * 0.9,
            ultraMeta: sales * 1.0
        })
        return {
            meta: sales * 0.8,
            superMeta: sales * 0.9,
            ultraMeta: sales * 1.0
        };
    };



    return (
        <Modal
            title={""}
            show={showModal}
            onHide={onHide}>

            <div className="row d-flex align-items-center justify-content-between">
                <div className="col-3 text-center">
                    <div className="font-size-h3 font-w300 text-black">
                        <small className="">

                        Meta: <span className="text-success">{data && data.sales} </span>
                        </small>
                        {/* <br/> */}
                        <br/>
                        <small className="">

                        Vendas Mês: <span className="text-success">{bigNumber &&
                            bigNumber.filter(
                                (x) => x.price > 60 && x.price != 0,
                            ).length}</span>
                            <br/>
                            <br/>
                        </small>
                        Resultado: <span className="text-success">{data &&
                                                    data.product &&
                                                    NumberMask(data.product , 2)}%</span>
                    </div>
                </div>
                <div className="col-1 text-center">
                    <i className="fa fa-plus fa-2x "/>
                </div>
                <div className="col-3 text-center">
                    <div className="font-size-h3 py-4 font-w300 text-black">
                        <small className="">Meta: <span className="text-success">{data && (data.service)}</span></small>
                        {/* <br/> */}
                        <br/>
                        <small className="">Média Atual: <span className="text-success">{evaluation && (evaluation.media)?.toFixed(2)}</span></small>
                        <br/>
                        <br/>
                        Resultado <span className="text-success">{evaluation && evaluation.media && NumberMask((evaluation.media / data.service) * 100, 2)}%</span>
                    </div>
                </div>
                <div className="col-1 text-center py-3 font-size-h1">
                / 2
                </div>
                <div className="col-3 text-center py-3">
                    <div className="font-size-h2 font-w300">
                       = <span className="text-success">{data && data.metaResult}%</span>
                    </div>
                </div>
                <div className="col-12 text-center py-5 font-size-h1">
                    Já alcançamos <span className="text-success">{data && data.metaResult}%</span> da meta geral
                </div>
            </div>

        </Modal>
    );
}
