import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";

export default function CompaniesTutorialModal({ show, onHide }) {
    const [loading] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <CustomModal
            title=""
            show={show}
            isLoading={loading}
            onHide={onHide}>

            <h3>O que é a Consulta de Regularidade Cadastral?</h3>
            <p>
                Apresentamos a Consulta de Regularidade Cadastral, uma ferramenta poderosa desenvolvida exclusivamente para o Hasa Pro. Esta funcionalidade tem como objetivo garantir que todos os seus clientes estejam em total conformidade com registros e cadastros exigidos pelos órgãos públicos, de acordo com suas atividades específicas.
                <br />Algumas consultas são automatizadas pela plataforma e terão resultado imediato, já outras, nosso time realizará uma verificação mais completa.
                <br />
                <br />
                Nossa ferramenta cobre os seguintes órgãos e registros:
            </p>
            <ul className="mb-3 pb-3">

                <li>
                    Junta Comercial
                </li>
                <li>
                    Receita Federal
                </li>
                <li>
                    Prefeitura
                </li>
                <li>
                    Secretaria da Fazenda
                </li>
                <li>
                    Alvarás de Funcionamento
                </li>
                <li>
                    Registros de Conselhos (Atividades regulamentadas)
                </li>
                <li>
                    Certidões diversas como: FTS, Dívida Ativa, Débitos Tributários e Débitos Municipais;
                </li>
            </ul>

            <p>
                Com o Hasa Pro, você terá uma análise proativa que operação dos seus clientes está alinhada com a legislação vigente, minimizando riscos e promovendo a transparência nas atividades. Mantenha seus clientes sempre atualizados e em dia com suas obrigações legais, facilitando a gestão e fortalecendo a credibilidade do seu negócio.

            </p>

            <h3>Funcionalidades:</h3>
            <p>
                Verificação Abrangente: Nosso sistema realiza uma análise detalhada da regularidade em diversos órgãos, incluindo:
            </p>
            <ul className="mb-3 pb-3">
                <li>
                    Junta Comercial
                </li>
                <li>
                    Receita Federal
                </li>
                <li>
                    Prefeitura
                </li>
                <li>
                    Secretaria da Fazenda
                </li>
                <li>
                    Alvarás de Funcionamento
                </li>
                <li>
                    Registros de Conselhos (Atividades regulamentadas)
                </li>
                <li>
                    Certidões diversas como: FTS, Dívida Ativa, Débitos Tributários e Débitos Municipais;
                </li>
            </ul>

            <p>
            Relatórios Claros e Objetivos: Acesse relatórios completos sobre cada CNPJ, facilitando a compreensão da situação cadastral de cada cliente e permitindo a tomada de ações corretivas quando necessário.
            </p>

            <h3>Benefícios:</h3>
            <ul className="mb-3 pb-3">
                <li>
                    <span className="font-w700">Conformidade Legal:</span> Minimize riscos e evite problemas com a fiscalização, garantindo que todas as empresas estejam em conformidade.
                </li>
                <li>
                    <span className="font-w700">Transparência e Credibilidade:</span> Ao manter seus clientes regulares, você fortalece a confiança e a credibilidade da sua empresa.
                </li>
                <li>
                    <span className="font-w700">Gestão Eficiente:</span> Facilite a administração e a tomada de decisões estratégicas com informações precisas sobre a regularidade cadastral.
                </li>
            </ul>

            <h3>Conclusão</h3>
            <p>A Consulta de Regularidade Cadastral é uma ferramenta essencial para escritórios que desejam garantir a conformidade legal de seus clientes. Com uma abordagem proativa, você pode proteger seus negócios e proporcionar segurança e tranquilidade a todos os envolvidos. Experimente e veja como podemos ajudar a fortalecer a sua operação!</p>
        </CustomModal>
    );
}
