import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { DateToPast, DateTranslate } from "@/app/core/util/DateTranslater";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import React, { useEffect, useState } from "react";
import EntryModal from "./EntryModal";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

export default function RecentEntries(){
    const date = new Date();
    const cashFlow = CashFlowController();
    const financialHelper = FinancialHelper();
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState({});
    const userData = Storage.getUserData();

    const [startDate, setStartDate] = useState(
      date
        .toISOString()
        .substring(0, 10)
    );
    const [endDate, setEndDate] = useState(getLastDayOfMonth(date.getFullYear(), date.getMonth())
    .toISOString()
    .substring(0, 10));
    
    function getLastDayOfMonth(year, month) {
        return new Date(year, month, month === 1 ? 28 : 30);
    }

    useEffect(()=>{
        if(userData && (userData.level === UserHelper.Level.Administrator || userData.level === UserHelper.Level.Collector || userData.level === UserHelper.Level.FinancialAdministrator)){
            loadInfos()
        }
    },[startDate, endDate])
    
    async function loadInfos(){
        cashFlow.LastEntries(startDate, endDate, res => {
            console.log("Resultados", res);
            setData(res);
        })
    }

    async function removeItem(item){
        if(window.confirm(Message.DeleteConfirm)){
            await cashFlow.DeleteEntry(item.id, res => {
                toast.success(Message.DeleteSuccess);
                loadInfos();
            })
        }
    }

    function openEntry(item){
        setShowModal(true);
        setSelected(item);
    }

    return(
        <div className="ml-1 mr-1 mt-1 mb-1 row">
            <div className="col-md-6">
                <div className="form-group">
                    <span>Data de início</span>
                    <input
                        type={"date"}
                        className="form-control"
                        value={startDate}
                        onChange={(evt) => setStartDate(evt.target.value)}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <span>Data fim</span>
                    <input
                        type={"date"}
                        className="form-control"
                        value={endDate}
                        onChange={(evt) => setEndDate(evt.target.value)}
                    />
                </div>
            </div>
            <div className="col-md-12">
                {data && data.map(item => (
                    <div className="block block-bordered block-rounded">
                        <div className="block-header mb-0 pb-0">
                            <div className="block-title text-sm">
                                {item && item.customer && `${item.customer.customerCode} - ${item.customer.name}`}
                            </div>
                            <div className="block-tools">
                                <a className="btn btn-dual btn-sm" href={`#/customer-profile/${item.customerId}`} target="_blank" title="Ver cliente"><i className="si si-share-alt"></i></a>
                                <button className="btn btn-dual btn-sm" onClick={()=> openEntry(item)} title="Abrir"><i className="far fa-eye"></i></button>
                                <button className="btn btn-dual btn-sm text-danger" onClick={()=> removeItem(item)} title="Excluir"><i className="si si-close"></i></button>
                            </div>
                        </div>
                        <div className="ml-1 mr-1 py-2 row">
                            <div className="col-12 text-muted">
                                Descrição: {item.description}
                            </div>
                            <div className="col-12">
                                Tipo: {item && financialHelper.OperationTypeTranslateBadge(item.type)}
                            </div>
                        </div>
                        <div className="ml-1 mr-1 row">
                            <div className="col-6">
                                {DateTranslate(item.createdDate)}
                                {item && financialHelper.PaymentStatusTranslateBadge(item.status)}
                            </div>
                            <div className="col-6">
                                {item && `${MoneyMask(item.value,2)}`}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <EntryModal
                show={showModal}
                selected={selected}
                customerId={selected && selected.customerId && selected.customerId}
                onHide={()=> setShowModal(false) | setSelected({}) | loadInfos()}/>
        </div>
    )
}