import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import BalanceEntryController from "@/app/core/base/controllers/BalanceEntryController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Field from "@/app/core/layouts/main-components/Field";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";

export default function BalanceEntryModal({ show, onHide, selected }) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setData(selected);
    }, [selected, show]);

    async function save() {
        if (
            !data.customerCode || !data.description || !data.value
        ) {
            toast.error(Message.PendingData);
            return;
        }
        let newData = data;
        if(newData.status === "undefined"){
            newData.status = FinancialHelper().PaymentStatus.Waiting;
        }
        if (!data.id) {
            await BalanceEntryController().Insert(newData, res => {
                end()
            })
        } else {
            await BalanceEntryController().Update(newData, res => {
                end()
            })
        }
    }

    function end() {
        toast.success(Message.Saved);
        onHide()
    }

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type))
    }

    return (
        <Modal
            title={data && `Saldo anterior`}
            show={show}
            isLoading={false}
            onHide={onHide}
            size="lg"
            onSave={() => save()}
            onSubmit={() => save()}>

            <Field value={data && data.customerCode} onChange={evt => handleChange(evt)} name={"customerCode"} title={"Código de cliente"} type={FieldTypeObj.Text} />
            <Field value={data && data.description} onChange={evt => handleChange(evt)} name={"description"} title={"Descrição"} type={FieldTypeObj.Text} />
            <Field value={data && data.value} onChange={evt => handleChange(evt, FieldTypeObj.Currency)} name={"value"} title={"Valor"} type={FieldTypeObj.Currency} />
            <Field value={data && data.dueDate} onChange={evt => handleChange(evt, FieldTypeObj.Date)} name={"dueDate"} title={"Data"} type={FieldTypeObj.Date} />
        </Modal>
    );
}
