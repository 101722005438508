import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import SignInForm from "./SignInForm";
import Logo from "@/assets/img/logo.jpg";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountController from "@/app/core/base/controllers/UsersController";
import { DevolutionStepUser } from "@/app/core/base/controllers/DevolutionStepController";
import { HandleAuthErrors } from "@/app/core/util/helpers/ErrorHelper";
import { ConversionNotify } from "@/app/core/base/controllers/GlobalController";
import PreparationStepsController from "@/app/core/base/controllers/PreparationStepsController";
import { ExigencyTemplateList } from "@/app/core/base/controllers/ExigencyTemplateController";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { ServiceList } from "@/app/core/base/controllers/ServicesController";
import { MotivationalList } from "@/app/core/base/controllers/MotivationalController";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

export default function SignIn(props) {
  const [authenticate, setAuthenticate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationToken, setConfirmationToken] = useState("");
  const [ip, setIp] = useState("");
  const [data, setData] = useState({});
  const [tokenPanel, setTokenPanel] = useState(false);
  const accountCtrl = AccountController();
  const preparationStep = PreparationStepsController();
  const organListController = OrganListController();

  const enviroment = process.env.REACT_APP_ENV;

  async function onSubmit(form) {
    if (form.values.password === "@HasaDocumentos01") {
      alert(
        "Bem vindo a Hasa Documentos. Você esta usando uma senha provisória e será direcionado ao processo de definição de senha",
      );
      window.location = "#/recover";
    } else {
      setLoading(true);
      setData(form.values);
      await accountCtrl.Login(
        form.values,
        (res) => {
          if (res.message || res.user == undefined || res.user.level != UserHelper.Level.Customer) {
            if (res.message != undefined) {
              toast.info(res.message);
            }

            // if(res.user.level != UserHelper.Level.Customer){
            //   res.userId = res.user.id;
            //   Storage.setUserData(res);
            //   getUserData(res.token);
            // }

            setTokenPanel(true);
          } else {
            CustomerSelect(res.user.customerId, customerResult => {
              res.user.customer = customerResult;
              Storage.setUserData(res);
              reportAccess();
              setLoading(false);
              toast.info("Bem vindo...");
              window.location = `#/customer-profile/${res.user.customerId}`;
            })
          }
        },
        (err) => {
          toast.error(HandleAuthErrors(err));
          console.log("Qual", err);

          setLoading(false);
        },
      );
    }
  }

  function tryTokenSubmit(key) {
    if (key === "Enter") {
      submitToken();
    }
  }

  function reportAccess() {
    accountCtrl.ReportAccess({ ip }, (res) => {});
  }

  function checkIpAddressO() {
    const fetchIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        if (data.ip) {
          setIp(data.ip);
        }
      } catch (error) {}
    };
    fetchIp();
  }

  function submitToken() {
    if (confirmationToken.length > 0) {
      accountCtrl.SendToken(
        {
          email: data.email,
          token: confirmationToken,
          tokenProvider: "Email",
        },
        (res) => {
          if (res && res.user && (res.user.level == 8 || res.user.level == 7)) {
            toast.error(
              "Esse painel é dedicado apenas aos clientes ou operacional",
            );
            return;
          }
          res.token = {
            accessToken: res.accessToken,
          };
          Storage.setUserData(res);
          getUserData(res);
        },
        (error) => toast.error("Token inválido, tente novamente."),
      );
    }
  }

  function getUserData(base) {
    accountCtrl.Get(
      base.userId,
      (res) => {
        let templates = Storage.getTemplates();

        if (
          res.level != 5 &&
          (templates == null || templates == undefined || templates.length == 0)
        ) {
          ExigencyTemplateList((baseRes) => {
            for (let s in baseRes) {
              baseRes[s].name =
                `${baseRes[s].code} - ${baseRes[s].description}`;
            }
            Storage.setTemplates(baseRes);
          });
          organListController.OrgansSelect((baseRes) =>
            Storage.setOrgans(baseRes),
          );
          ServiceList((BaseRes) => Storage.setServices(BaseRes));
          MotivationalList((result) => Storage.setMotivational(result));
        }

        if (res && res.level === 4) {
          DevolutionStepUser(res.id, (response) => {
            if (response.length === 0) {
              Storage.setUserData({
                token: base,
                user: { ...res, devolutionSteps: response },
              });
              setAuthenticate(true);
              toast.info("Bem vindo...");
            } else {
              Storage.setUserData({
                token: base,
                user: { ...res, devolutionSteps: response },
              });

              setLoading(false);
              setAuthenticate(true);
              toast.info("Bem vindo...");
            }
          });
        } else if (res && res.level === 3) {
          preparationStep.Get(res.id, (response) => {
            if (response.length == 0) {
              Storage.setUserData({
                token: base,
                user: { ...res, preparationSteps: response },
              });
              setAuthenticate(true);
              toast.info("Bem vindo...");
            } else {
              Storage.setUserData({
                token: base,
                user: { ...res, preparationSteps: response },
              });

              setLoading(false);
              setAuthenticate(true);
              toast.info("Bem vindo...");
            }
          });
        } else {
          Storage.setUserData({
            token: base,
            user: { ...res },
          });
          setLoading(false);
          setAuthenticate(true);
          toast.info("Bem vindo...");
        }
      },
      (error) => {}
    );
  }

  useEffect(() => {
    checkIpAddressO();
  }, []);

  if (authenticate) return <Redirect to="/app" />;
  return (
    <div id="page-container">
      <main id="main-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: "url('assets/media/photos/photo22@2x.jpg')",
          }}
        >
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a
                    className="link-fx font-w700 font-size-h1"
                    href="index.html"
                  >
                    <img src={Logo} style={{ maxWidth: "150px" }} />
                  </a>
                  <p className="text-uppercase font-w700 font-size-sm text-muted">
                    Entrar
                  </p>
                </div>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-8">
                    {/* <div className="block block-fx-pop block-bordered border-info mt-5">
                      <div className="block-header">
                        <div className="block-title text-center">
                          <i className="fa fa-exclamation-circle text-info mr-2" /> ATENÇÃO
                          <br />
                          A plataforma está passando por uma atualização, por favor tente novamente mais tarde
                          <br />
                          Em casos de emergência entre em contato com nosso time agora mesmo
                          <br />Pedimos desculpas pelo inconveniente.
                        </div>
                        <div className="block-tools">
                        </div>
                      </div>
                    </div> */}

                    {tokenPanel == false ? (
                      <SignInForm
                        submit={(form) => onSubmit(form)}
                        loading={loading}
                      />
                    ) : (
                      <div className="block-content block-content-full px-lg-5 py-md-5 py-lg-4">
                        <div className="mb-2 text-center">
                          <p className="text-uppercase font-w700 font-size-sm text-muted">
                            Código de confirmação
                          </p>
                        </div>
                        <div className="js-validation-signin">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control form-control-lg form-control-alt"
                              name="token"
                              onChange={(evt) =>
                                setConfirmationToken(evt.target.value)
                              }
                              onKeyDown={(evt) => tryTokenSubmit(evt.key)}
                              placeholder="token"
                              value={confirmationToken}
                            />
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-block btn-hero-warning"
                              onClick={() => submitToken()}
                            >
                              <i className="fa fa-fw fa-sign-in-alt mr-1"></i>{" "}
                              Enviar código
                            </button>
                          </div>
                          <div className="text-center">
                            <button
                              className="btn btn-link"
                              onClick={() => setTokenPanel(false)}
                            >
                              <i className="fa fa-arrow-left"></i> Voltar ao
                              login
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                  Sua experiência está sendo atualizada!
                  <br />
                  Para sempre ;)
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Copyright <b>Hasa</b> &copy;{" "}
                  <span className="js-year-copy">
                    {new Date().getFullYear()}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
}
