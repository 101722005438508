export const GeneralHelper = {
    LegibleName: function (name, value) {
        if (name.length > value) {
            let newName = name.substr(0, value) + "...";
            return newName;
        } else {
            return name;
        }
    },
    IsDevelopment: function () {
        const url = window.location.href;
        return url.includes("staging") //|| url.includes("localhost");
    }
}