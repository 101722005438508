import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import { cols } from "./ColumnHelper";
import { colsOperation } from "./ColumnOperation";
import Storage from "@/app/core/util/Storage";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DevolutionPanel from "../panels/DevolutionPanel";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { organizeTasks, processObservations } from "@/app/core/util/helpers/TasksHelper";
import { GetUserSelectedServices } from "@/app/core/base/controllers/ServicesController";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import Functions from "@/app/core/util/Functions";
import FinancialPanel from "../panels/FinancialPanel";
import AdministratorPanel from "../panels/AdministratorPanel";
import { PreparationStep, UserHelper } from "@/app/core/util/helpers/UserHelper";
import MyProcessList from "@/app/core/layouts/main-components/process/MyProcessList";
import CustomerListFollow from "@/app/core/layouts/main-components/customer/CustomerListFollow";
import RegistrationStatusCard from "@/app/core/layouts/main-components/organs/RegistrationStatusCard";
import { ReavaliationController } from "@/app/core/base/controllers/ReavaliationController";
import CollectorPanel from "../panels/CollectorPanel";
import { DateTranslate } from "@/app/core/util/DateTranslater";

export default function SearchComponent(
  { setShowModal, setShowOperationModal, refresh },
  props,
) {
  const userData = Storage.getUserData();
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [openProcess, setOpenProcess] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDone, setOpenDone] = useState(false);
  const [reavaliations, setReavaliations] = useState([]);
  const [tasks, setTasks] = useState({
    open: [],
    ongoing: [],
    closed: [],
    review: [],
  });
  const [operationTasks, setOperationTasks] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const organListHelper = OrganListHelper();
  const processCtrl = ProcessController();

  useEffect(() => {
    loadProcess();
  }, [refreshData, refresh, props.refresh]);

  async function loadProcess() {
    setTableLoading(true);
    if (userData.level === 1) {
      await processCtrl.Tasks(userData.id, (res) => {
        if (userData.level === 1 && res.ongoing === undefined) {
          res.ongoing = [];
        }
        if (userData.level === 1 && res.open === undefined) {
          res.open = [];
        }
        if (userData.level === 1 && res.closed === undefined) {
          res.closed = [];
        }
        processData(res);
      });
    } else if (userData.level === 6) {
      await processCtrl.OperatorTasks(userData.id, (res) => {
        if (userData.level === 1 && res.ongoing === undefined) {
          res.ongoing = [];
        }
        if (userData.level === 1 && res.open === undefined) {
          res.open = [];
        }
        if (userData.level === 1 && res.closed === undefined) {
          res.closed = [];
        }
        processData(res);
      });
    } else if (
      userData.level === 2 ||
      userData.level === 3 ||
      userData.level === 4
    ) {
      GetUserSelectedServices(userData.id, (res) => {
        processData(res);
      });
    }
    if(userData && userData.reavaliationPanel === true){
      await ReavaliationController.Team(userData.id, res => {
        for(let s of res){
          s.processName = s.process.processName;
          s.serviceName = s.selectedService.serviceName;
          s.organName = s.organ?.name;
          s.observations = processObservations(s, userData.level);
          s.updatedDate = DateTranslate(s.updatedDate);
        }
        setReavaliations(organizeTasks(res, userData.level))
      });
    }
    setTableLoading(false);
  }

  function processData(infos) {
    let taskList = organizeTasks(infos, userData.level);
    if (userData.level !== 6) {
      setTasks(taskList);
    } else {
      setOperationTasks(taskList);
    }
  }

  function viewAction(recv) {
    if (userData.level === 1) {
      setShowModal(recv);
    } else if (userData.level === 2) {
      window.location = `#/analysis/${recv.data.id}`;
    } else if (userData.level === 3) {
      window.location = `#/preparation/${recv.data.id}`;
    } else if (userData.level === 6) {
      setShowOperationModal(recv);
    } else {
      setRedirect(recv.data.id);
    }
  }

  function viewOperationBudgetAction(recv) {
    setShowModal(recv);
  }

  function deleteBudget(item) {
    if (
      item.data.budgetStatus !== 0 &&
      window.confirm(
        "Este orçamento já foi iniciado quer exclui-lo da mesma forma e notificar o responsável?",
      )
    ) {
      processCtrl.Delete(item.data.id, (res) => {
        toast.success("Removido com sucesso");
        setRefreshData(!refreshData);
      });
    } else if (
      window.confirm(
        "Este orçamento ainda não foi iniciado, tem certeza que deseja excluí-lo? Esta ação será irreversível",
      )
    ) {
      let motive = window.prompt("Descreva o motivo da exclusão:");
      if (motive === "" || motive === null) {
        toast.error("Você precisa descrever o motivo");
        return;
      }
      processCtrl.Delete(item.data.id, motive, (res) => {
        toast.success("Removido com sucesso");
        setRefreshData(!refreshData);
      });
    }
  }

  if (redirect) return <Redirect to={`/process/${redirect}`} />;

  function loadPresentation() {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="block block-rounded block-bordered block-transparent bg-gd-sun-op">
            <div className="block-header">
              <div
                className="block-title h2 text-center font-w700 cursor"
                onClick={() => setOpenProcess(!openProcess)}
              >
                Processos que sigo
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setRefreshData(!refreshData)}
                >
                  <i className="si si-refresh"></i>
                </button>
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setOpenProcess(!openProcess)}
                >
                  <i
                    className={`si si-arrow-${openProcess === true ? "up" : "down"}`}
                  ></i>
                </button>
              </div>
            </div>

            {openProcess === true ? <MyProcessList /> : ""}
          </div>
        </div>

        <div className="col-md-6">
          <div className="block block-rounded block-bordered block-transparent bg-gd-sea-op">
            <div className="block-header">
              <div
                className="block-title h2 text-center font-w700 cursor"
                onClick={() => setCustomerOpen(!customerOpen)}
              >
                Clientes que sigo
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setRefreshData(!refreshData)}
                >
                  <i className="si si-refresh"></i>
                </button>
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setCustomerOpen(!customerOpen)}
                >
                  <i
                    className={`si si-arrow-${customerOpen === true ? "up" : "down"}`}
                  ></i>
                </button>
              </div>
            </div>

            {customerOpen === true ? <CustomerListFollow /> : ""}
          </div>
        </div>
      </div>
    );
  }

  function loadPanel() {
    if (userData && userData.level === UserHelper.Level.Comercial) {
      return (
        <div className="col-md-12">
          {userData && loadPresentation()}
          <div className="block block-rounded block-transparent bg-light-25">
            <div className="block-header">
              <div className="block-title">
                {open === true ? tasks.open.length : ""} Não iniciados
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setRefreshData(!refreshData)}
                >
                  <i className="si si-refresh"></i>
                </button>
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setOpen(!open)}
                >
                  <i
                    className={`si si-arrow-${open === true ? "up" : "down"}`}
                  ></i>
                </button>
              </div>
            </div>

            {open === true ? (
              <Table
                title={tasks.open && tasks.open.length + " Não iniciados"}
                loading={tableLoading || false}
                data={tasks.open}
                columns={cols}
                refreshData={() => setRefreshData(!refreshData)}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, tasks.open)
                }
                viewAction={(data) => viewAction(data)}
                hideHeader={true}
              />
            ) : (
              ""
            )}
          </div>
          <Table
            title={tasks.ongoing && tasks.ongoing.length + " Em andamento"}
            loading={tableLoading || false}
            data={tasks.ongoing}
            columns={cols}
            refreshData={() => setRefreshData(!refreshData)}
            setFilter={(filter) =>
              Functions.ProcessFilter(filter, tasks.ongoing)
            }
            viewAction={(data) => viewAction(data)}
          />

          <div className="block block-rounded block-transparent bg-light-25">
            <div className="block-header">
              <div className="block-title">
                {openDone === true ? tasks.closed.length : ""} Encerrados
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setRefreshData(!refreshData)}
                >
                  <i className="si si-refresh"></i>
                </button>
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setOpenDone(!openDone)}
                >
                  <i
                    className={`si si-arrow-${
                      openDone === true ? "up" : "down"
                    }`}
                  ></i>
                </button>
              </div>
            </div>
            {openDone === true ? (
              <Table
                title={tasks.closed && tasks.closed.length + " Encerrados"}
                loading={tableLoading || false}
                data={tasks.closed}
                columns={cols}
                refreshData={() => setRefreshData(!refreshData)}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, tasks.closed)
                }
                viewAction={(data) => viewAction(data)}
                hideHeader={true}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (userData && userData.level === UserHelper.Level.Analyst) {
      return (
        <div className="col-md-12">
          {userData && loadPresentation()}
          <div className="block block-rounded block-transparent bg-light-25">
            <div className="block-header">
              <div className="block-title">
                {open === true ? tasks.open.length : ""} Não iniciados
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setRefreshData(!refreshData)}
                >
                  <i className="si si-refresh"></i>
                </button>
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setOpen(!open)}
                >
                  <i
                    className={`si si-arrow-${open === true ? "up" : "down"}`}
                  ></i>
                </button>
              </div>
            </div>

            {open === true ? (
              <Table
                title={tasks.open.length + " Não iniciados"}
                loading={tableLoading || false}
                data={tasks.open}
                columns={organListHelper.AnalysisCols}
                refreshData={() => setRefreshData(!refreshData)}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, tasks.open)
                }
                viewAction={(data) => viewAction(data)}
                hideHeader={true}
              />
            ) : (
              ""
            )}
          </div>

          <RegistrationStatusCard />

          <Table
            title={tasks.ongoing.length + " Em andamento"}
            loading={tableLoading || false}
            data={tasks.ongoing}
            columns={organListHelper.AnalysisCols}
            refreshData={() => setRefreshData(!refreshData)}
            setFilter={(filter) =>
              Functions.ProcessFilter(filter, tasks.ongoing)
            }
            viewAction={(data) => viewAction(data)}
          />
          <div className="block block-rounded block-transparent bg-light-25">
            <div className="block-header">
              <div className="block-title">
                {openDone === true ? tasks.closed.length : ""} Encerrados
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setRefreshData(!refreshData)}
                >
                  <i className="si si-refresh"></i>
                </button>
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setOpenDone(!openDone)}
                >
                  <i
                    className={`si si-arrow-${
                      openDone === true ? "up" : "down"
                    }`}
                  ></i>
                </button>
              </div>
            </div>
            {openDone === true ? (
              <Table
                title={tasks.closed.length + " Encerrados"}
                loading={tableLoading || false}
                data={tasks.closed}
                columns={organListHelper.AnalysisCols}
                refreshData={() => setRefreshData(!refreshData)}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, tasks.closed)
                }
                viewAction={(data) => viewAction(data)}
                hideHeader={true}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (userData && userData.level === UserHelper.Level.Preparer) {
      return (
        <div className="col-md-12">
          {userData && loadPresentation()}
          <div className="block block-rounded block-transparent bg-light-25">
            <div className="block-header">
              <div className="block-title">
                {open === true && tasks.open !== undefined
                  ? tasks.open.length
                  : ""}{" "}
                Não iniciados
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setRefreshData(!refreshData)}
                >
                  <i className="si si-refresh"></i>
                </button>
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setOpen(!open)}
                >
                  <i
                    className={`si si-arrow-${open === true ? "up" : "down"}`}
                  ></i>
                </button>
              </div>
            </div>

            {open === true ? (
              <Table
                title={`${tasks.open.length} Não iniciados`}
                data={tasks.open}
                columns={organListHelper.PreparationCols}
                loading={tableLoading || false}
                refreshData={() => setRefreshData(!refreshData)}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, tasks.open)
                }
                viewAction={(data) => viewAction(data)}
                hideHeader={true}
              />
            ) : (
              ""
            )}
          </div>
          <RegistrationStatusCard />
          {userData &&
            (userData.preparationSteps === undefined ||
            userData.preparationSteps.length === 0 ||
            userData.preparationSteps.filter(
              (x) => x.step == PreparationStep.Track,
            ).length > 0 ? (
              <Table
                title={tasks.ongoing.length + " Em andamento"}
                loading={tableLoading || false}
                data={tasks.ongoing}
                columns={organListHelper.PreparationCols}
                refreshData={() => setRefreshData(!refreshData)}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, tasks.ongoing)
                }
                viewAction={(data) => viewAction(data)}
              />
            ) : (
              ""
            ))}
          {userData &&
          userData.preparationSteps !== undefined &&
          userData.preparationSteps.filter(
            (x) => x.step == PreparationStep.Review,
          ).length > 0 ? (
            <Table
              title={
                tasks && tasks.review && tasks.review.length + " Em conferência"
              }
              loading={tableLoading || false}
              data={tasks.review}
              columns={organListHelper.PreparationCols}
              refreshData={() => setRefreshData(!refreshData)}
              setFilter={(filter) =>
                Functions.ProcessFilter(filter, tasks.review)
              }
              viewAction={(data) => viewAction(data)}
            />
          ) : (
            ""
          )}

          <div className="block block-rounded block-transparent bg-light-25">
            <div className="block-header">
              <div className="block-title">
                {openDone === true ? tasks.closed.length : ""} Encerrados
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setRefreshData(!refreshData)}
                >
                  <i className="si si-refresh"></i>
                </button>
                <button
                  className="btn btn-sm btn-dual"
                  onClick={() => setOpenDone(!openDone)}
                >
                  <i
                    className={`si si-arrow-${
                      openDone === true ? "up" : "down"
                    }`}
                  ></i>
                </button>
              </div>
            </div>
            {openDone === true ? (
              <Table
                title={tasks.closed.length + " Encerrados"}
                loading={tableLoading || false}
                data={tasks.closed}
                columns={organListHelper.PreparationCols}
                refreshData={() => setRefreshData(!refreshData)}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, tasks.closed)
                }
                hideHeader={true}
                viewAction={(data) => viewAction(data)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (userData && userData.level === UserHelper.Level.Devolution) {
      return (
        <div className="col-md-12">
          {userData && loadPresentation()}
          <DevolutionPanel
            data={tasks}
            loading={tableLoading}
            reload={() => loadProcess()}
            setView={(i) => setShowOperationModal(i)}
          />

          <RegistrationStatusCard />
        </div>
      );
    } else if (userData && userData.level === UserHelper.Level.Customer) {
      return <div className="col-md-12">{/* <DevolutionPanel  /> */}</div>;
    } else if (userData && userData.level === UserHelper.Level.Operator) {
      return (
        <div className="col-md-12">
          <Table
            title="Orçamentos"
            loading={tableLoading || false}
            data={operationTasks}
            columns={colsOperation}
            refreshData={() => setRefreshData(!refreshData)}
            viewAction={(data) => viewOperationBudgetAction(data)}
            setFilter={(filter) =>
              Functions.ProcessFilter(filter, operationTasks)
            }
            deleteAction={(item) => deleteBudget(item)}
          />

          <RegistrationStatusCard />
        </div>
      );
    } else if (userData && userData.level === UserHelper.Level.Collector) {
      return (
        <div className="col-md-12">
          <CollectorPanel />
        </div>
      );
    } else if (userData && (userData.level === UserHelper.Level.Administrator || userData.level === UserHelper.Level.FinancialAdministrator)) {
      return (
        <div className="col-md-12">
          <AdministratorPanel />
        </div>
      );
    }
  }

  return (
    <div>
      <div className="">
        <div className="row">{loadPanel()}</div>
        <div className="row">{userData && userData.reavaliationPanel === true ? 
              <Table
                title={reavaliations.length + " Reavaliações"}
                loading={tableLoading || false}
                data={reavaliations}
                columns={organListHelper.PreparationCols}
                refreshData={() => setRefreshData(!refreshData)}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, tasks.closed)
                }
                viewAction={(data) => viewAction(data)}
              />
           
        : ""}</div>
      </div>
    </div>
  );
}
