import React, { useEffect, useState, useRef } from "react";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { DateTranslate, OnlyDateTranslate, OnlyDateTranslateToInput } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { CustomerController, CustomerList } from "@/app/core/base/controllers/CustomerController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { Typeahead } from "react-bootstrap-typeahead";
import CustomerListCard from "../../oportunities/main/CustomerListCard";

export default function Negativeds({ startDate, endDate }) {
    const [filter, setFilter] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [query, setQuery] = useState("");
    const tableRef = useRef(null);
    const colsDiscount = [
        { header: "Cód.", isSortable: true, name: "customerCode" },
        {
          header: "Nome",
          isSortable: true,
          name: "name",
          style: {
            maxWidth: "220px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        },
        { header: "Email", isSortable: true, name: "email" },
        { header: "Telefone", isSortable: true, name: "phone" },
        { header: "Valor devido", isSortable: true, name: "balance" },
        { header: "Data de negativação", isSortable: true, name: "negativation" },
      ];

    useEffect(() => {
        loadCustomers()
    }, [])

    async function loadCustomers() {
        setLoading(true);
        await CustomerList(res => {
            for(let s of res){
                s.query = `${s.customerCode} - ${s.name}`
            }
            setCustomers(res)
    });
        await CustomerController.ListNegativeds(res => {
            for(let s of res){
                s.balance = MoneyMask(s.balance);
                s.negativation = OnlyDateTranslate(s.negativation);
            }
            setFilter(res)
        });
        setLoading(false);
    }

    function restoreCustomer(item) {
        console.log(item)   
        if (window.confirm(Message.Confirm)) {
            CustomerController.RemoveNegative(item.id, res => {
                toast.success(Message.Saved);
                loadCustomers();
            })
        }
    }

    function registerCustomer() {
        if (window.confirm(Message.Confirm)) {
            CustomerController.RegisterNegative(data.customerId, data.negativation, res => {
                toast.success(Message.Saved);
                loadCustomers();
            })
        }
    }

    function handleChange(evt){
        setData(OnDataChange(data, evt));
    }



    return (
        <div>
            <div className="block-header mt-5">
                <div className="block-title">Clientes negativados</div>
                <div className="block-tools d-flex">
                    <DownloadTableExcel
                        filename="users table"
                        sheet={startDate && `Bloqueados - ${DateTranslate(startDate)} até ${DateTranslate(endDate)}`}
                        currentTableRef={tableRef.current}
                    >
                        <button className="btn btn-info"><i className="fa fa-download" /> Exportar Excel </button>
                    </DownloadTableExcel>
                    <DropdownElement
                        icon={"fa fa-thumbs-down"}
                        title={"Negativar cliente"}
                        submit={() => registerCustomer()}
                        style={{width: 350}}
                    >
                        <div class="form-group">
                            <label for="example-masked-time">Cliente</label>
                            <Typeahead
                                options={customers}
                                labelKey="query"
                                onSearch={(q) => setQuery(q)}
                                id="advanced-filter-state"
                                name="clientName"
                                placeholder="Selecionar Cliente"
                                value={data.customerId}
                                onChange={(selected) => {
                                    handleChange({
                                        target: {
                                            value: selected[0] ? selected[0].id : "",
                                            name: "customerId",
                                        },
                                    });
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <span>Data de negativação</span>
                            <input
                                type="date"
                                className="form-control"
                                name="negativation"
                                value={data.negativation}
                                onChange={handleChange}/>
                        </div>
                    </DropdownElement>
                </div>
            </div>
            {loading === true ? (
                <div className="block-content text-center">
                    <i className={"fa fa-circle-notch text-info fa-2x fa-spin"}></i>
                </div>
            ) : ""}
            <CustomerListCard
                title={""}
                infos={filter && filter}
                cols={colsDiscount}
                isOpen={true}
                reload={() => loadCustomers()}
                remove={item => restoreCustomer(item.data)}
                />
            {/* <table className="table table-hover table-striped" ref={tableRef}>
                <thead>
                    <tr>
                        <td>Id</td>
                        <td>Código de Cliente</td>
                        <td>Nome do Cliente</td>
                        <td>Email</td>
                        <td>Telefone</td>
                        <td>Valor devido</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {filter.map((item) => (
                        <tr>
                            <td>{item.id}</td>
                            <td>{item && item.customerCode}</td>
                            <td>{item && item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td className="text-danger">{MoneyMask(item.balance)}</td>
                            <td>
                                <button
                                    className="btn btn-sm btn-square btn-white m-1"
                                    onClick={() => openCustomer(item)}
                                >
                                    <i className="far fa-eye" /> Ver cliente
                                </button>
                                <button
                                    className="btn btn-sm btn-square btn-success m-1"
                                    onClick={() => restoreCustomer(item)}
                                >
                                    <i className="si si-check" /> Remover da lista
                                </button>
                            </td>
                        </tr>
                    )
                    )}
                </tbody>
            </table> */}
        </div>
    )
}