import React from "react";
const CustomerPendingCols = [
  { header: "Cód.", isSortable: true, name: "customerCode" },
  { header: "Nome", isSortable: true, name: "name" },
  {
    header: "Status",
    isSortable: true,
    name: "status",
    switch: {
      0: <span className="badge badge-secondary">Indefinido</span>,
      1: <span className="badge badge-info">Pendente</span>,
      2: <span className="badge badge-warning">Pendente Hasa</span>,
      3: <span className="badge badge-success">Ativo</span>,
      4: <span className="badge badge-danger">Bloqueado</span>,
    },
  },
  { header: "Saldo anterior", isSortable: true, name: "balance", style: { textAlign: "right" } },
  { header: "Pagamentos", isSortable: true, name: "credit", style: { textAlign: "right" } },
  { header: "Cobranças", isSortable: true, name: "debit", style: { textAlign: "right" } },
  { header: "Descontos", isSortable: true, name: "discounts", style: { textAlign: "right" } },
  { header: "Reembolsos", isSortable: true, name: "reimbursement", style: { textAlign: "right" } },
  { header: "Saldo atual", isSortable: true, name: "value", style: { textAlign: "right" } },
  // { header: "Cobrado", isSortable: true, name: "charges" },
  { header: "Qtd. Serviços", isSortable: true, name: "servicesCount", style: { textAlign: "right" } },
];

const CustomerBillCols = [
  { header: "Cód.", isSortable: true, name: "customerCode" },
  { header: "Nome", isSortable: true, name: "customerName" },
  { header: "Valor", isSortable: true, name: "charges", style: { textAlign: "right" } },
  { header: "Desconto", isSortable: true, name: "discount", style: { textAlign: "right" } },
  { header: "Saldo em aberto", isSortable: true, name: "open", style: { textAlign: "right" } },
  {
    header: "Status", isSortable: true, name: "customerStatus", switch: {
      0: <span className="badge badge-info">Em negociação</span>,
      3: <span className="badge badge-info">Ativo</span>,
      4: <span className="badge badge-danger">Bloqueado</span>,
    }
  },
];

const BillHistoryCols = [
  { header: "Mês da cobrança", isSortable: true, name: "month" },
  { header: "Valor total", isSortable: true, name: "total", style: { textAlign: "right" } },
  { header: "Valor recebido", isSortable: true, name: "done", style: { textAlign: "right" } },
  { header: "Saldo em aberto", isSortable: true, name: "pending", style: { textAlign: "right" } },
];

const SinteticAccountCols = [
  { header: "Cód.", isSortable: true, name: "customerCode" },
  { header: "Nome", isSortable: true, name: "customerName" },
  { header: "Saldo anterior", isSortable: true, name: "balance", style: { textAlign: "right" } },
  { header: "Pagamentos", isSortable: true, name: "credit", style: { textAlign: "right" } },
  { header: "Cobranças", isSortable: true, name: "debit", style: { textAlign: "right" } },
  { header: "Saldo atual", isSortable: true, name: "result", style: { textAlign: "right" } },
];

const ImportationsCols = [
  { header: "Cód.", isSortable: true, name: "customerCode" },
  { header: "Nome", isSortable: true, name: "customerName" },
  { header: "Saldo anterior", isSortable: true, name: "value", style: { textAlign: "right" } },
  { header: "Data de importação", isSortable: true, name: "createdDate", style: { textAlign: "right" } },
  {
    header: "Status", isSortable: true, name: "sync", switch: {
      true: <span className="text-success">Sincronizado</span>,
      false: <span className="text-danger">Sincronização pendente</span>
    }
  },
];
export { CustomerPendingCols, CustomerBillCols, SinteticAccountCols, BillHistoryCols, ImportationsCols };
