
import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { ElaboratorHelper } from "@/app/core/util/helpers/ElaboratorHelper";
import { ElaboratorController } from "@/app/core/base/controllers/ElaboratorController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";

export default function NewContractModal({ show, onHide, customerId }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        setData({});
    }, [show]);

    function handleChange(evt, type){
        setData(OnDataChange(data, evt, type));
    }

    function handleFormType(item){
        if(!data.name){
            toast.error("Informe o nome da empresa");
            return;
        }
        if(window.confirm(Message.Confirm)){
            ElaboratorController.Insert({...data, customerId: parseInt(customerId), type: item}, res =>{
                let oldData = data;
                oldData.type = item;
                setData(oldData);
                window.open(`#/drafter/${res.id}`, "_blank");

                setData({});
                onHide()
            })
        }
    }


    return (
        <CustomModal
            title="Novo contrato"
            show={show}
            isLoading={loading}
            onHide={onHide}>
            <div className="form-group">
                <span>Nome da empresa:</span>
                <input className="form-control" name="name" value={data && data.name} onChange={evt => handleChange(evt)}/>
            </div>
            <div className="row">
                {ElaboratorHelper.TypeList.map(item => (
                    <div className="col-md-4">
                        <button className={`btn btn-block btn-${item.color}`} onClick={()=> handleFormType(item.value)}>
                            {item.name}
                        </button>
                    </div>
                ))}
            </div>
        </CustomModal>
    );
}
