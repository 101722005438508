import React from "react";
import InputMask from "react-input-mask";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { Typeahead } from "react-bootstrap-typeahead";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";

export default function Field({ type, options, keyOption, nameOption, value, onChange, name, title, mask, hint, disabled, placeholder, maxLenght }) {
    const [query, setQuery] = useState("");

    return (
        <div className="form-group">
            <span>{title}</span>
            {type == FieldTypeObj.Select ?
                <select className="form-control" value={value} name={name} key={name} disabled={disabled} onChange={evt => onChange(evt)}>
                    <option selected={value == null ? true : false}>{title}</option>
                    {options && options.map(item => (
                        <option value={item[keyOption]}>{item[nameOption]}</option>
                    ))}
                </select>
                : ""}
            {type == FieldTypeObj.Mask ?
                <InputMask
                    mask={mask}
                    type="text"
                    className="form-control"
                    name={name}
                    placeholder={title}
                    key={name}
                    value={value}
                    onChange={(event) => {
                        onChange({
                            target: {
                                name: name,
                                value: event.target.value,
                            },
                        });
                    }} />
                : ""}
            {type == FieldTypeObj.Currency ?
                <CurrencyFormat
                    className={`form-control`}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    allowNegative={false}
                    name={name}
                    value={value}
                    prefix={"R$"}
                    onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        console.log("Dados", formattedValue, value);
                        onChange(
                            { target: { name: name, value: value } }
                        );
                    }}
                />
                : ""}
            {type == FieldTypeObj.TypeAhead ?
                <Typeahead
                    options={options}
                    labelKey={(option) => `${option[nameOption]}`}
                    onSearch={(q) => setQuery(q)}
                    placeholder={title}
                    key={name}
                    onChange={(selected) => {
                        if (selected[0] != undefined || selected[0] != null) {
                            onChange({ target: { value: selected[0].id, name } });
                        }
                    }}
                />
                : ""}
            {type == FieldTypeObj.Number ?
                <input type="number" placeholder={placeholder} name={name} value={value} key={name} disabled={disabled} className="form-control" onChange={evt => onChange(evt)} />
                : ""}
            {type == FieldTypeObj.Time ?
                <input type="time" placeholder={placeholder} name={name} value={value} key={name} disabled={disabled} className="form-control" onChange={evt => onChange(evt)} />
                : ""}
            {type == FieldTypeObj.Date ?
                <input type="date" placeholder={placeholder} name={name} value={value} key={name} disabled={disabled} className="form-control" onChange={evt => onChange(evt)} />
                : ""}
            {type == undefined || type == FieldTypeObj.Text ?
                <input type="text" placeholder={placeholder} name={name} value={value} key={name} disabled={disabled} className="form-control" onChange={evt => onChange(evt)} />
                : ""}
            {type == FieldTypeObj.TextArea ?
                <textarea type="text" placeholder={placeholder} name={name} value={value} key={name} disabled={disabled} maxLength={maxLenght} className="form-control" onChange={evt => onChange(evt)} />
                : ""}
            {hint != undefined ?
                <small>{hint}</small>
                : ""}
        </div>
    )
}