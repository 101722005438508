import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import React, { useEffect, useState } from "react";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import CollectorHistoryController from "@/app/core/base/controllers/CollectorHistoryController";

export default function HomeHistory() {
    const [raw, setRaw] = useState([]);
    const [filter, setFilter] = useState([]);
    const cols = [
        { header: "Descrição", isSortable: true, name: "description" },
        { header: "Data", isSortable: true, name: "createdDate" },
    ];

    useEffect(() => {
        loadPendingCustomers()
    }, [])

    async function loadPendingCustomers() {
        await CollectorHistoryController().Today(res => {
            for (let s in res) {
                res[s].createdDate = DateTranslate(res[s].createdDate);
                res[s].updatedDate = DateTranslate(res[s].updatedDate);
            }
            setFilter(res);
            setRaw(res);
        })
    }


    function handleChange(value) {
        if (value.length > 0) {
            setFilter([]);
            setFilter(autocomplete(value));
        } else {
            setFilter(raw);
        }
    }

    function autocomplete(value) {
        const inputValue = value.trim().toLowerCase();
        let itens = raw;
        let newResults = [];

        for (let s in itens) {
            const item = itens[s];
            const name =
                item.description !== undefined ? item.description.trim().toLowerCase() : "";
            const createdDate = item.createdDate;
            if (
                name.includes(inputValue) ||
                createdDate.includes(inputValue)
            ) {
                newResults.push(item);
            }
        }

        return newResults;
    }

    return (
        <div>
            <div className="block-header bg-dark">
                <div className="block-title text-white">
                    Histórico
                </div>
                <div className="block-tools">
                    <button className="btn btn-dark" onClick={() => loadPendingCustomers()}>
                        <i className="si si-refresh" />
                    </button>
                </div>
            </div>
            <div className="col-md-12 py-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(evt) => handleChange(evt.target.value)}
                />
            </div>
            <Table
                title={filter && filter.length + " Clientes"}
                loading={false}
                tableId="pending"
                data={filter}
                columns={cols}
                hideHeader={true}
                refreshData={() => loadPendingCustomers()}
                setFilter={(filtered) => Functions.ProcessFilter(filtered, filter)}
                viewAction={
                    (selected) =>
                        window.open(`#/customer-profile/${selected.data.customerId}`, "_blank")
                }
            />
        </div>
    )
}