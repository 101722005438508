import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function BalanceEntryController() {
    async function Insert(data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `balanceentry/insert`,
                data,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function Update(data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `balanceentry/update`,
                data,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function List(id, response, error) {
        await BaseService(
            {
                method: "GET",
                url: `balanceentry/${id}`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function Run(response, error) {
        await BaseService(
            {
                method: "GET",
                url: `balanceentry/run`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function Delete(id, response, error) {
        await BaseService(
            {
                method: "DELETE",
                url: `balanceentry/delete/${id}`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }

    return {
        Insert,
        Update,
        List,
        Delete,
        Run,
    };
}
