import React, { useState, useEffect } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Storage from "@/app/core/util/Storage";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toggler from "@/app/core/util/Toggler";
import SideOverlay from "@/app/core/layouts/main/components/SideOverlay";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import ProcessCard from "@/app/core/layouts/main/elements/ProcessCard";
import TimelineComponent from "@/app/core/layouts/main-components/TimelineComponent";
import ExigenciesComponent from "@/app/core/layouts/main-components/ExigenciesComponent";
import ProcessNotesComponent from "@/app/core/layouts/main-components/ProcessNotes";
import ProtocolComponent from "@/app/core/layouts/main-components/ProtocolComponent";
import DocsComponent from "@/app/core/layouts/main-components/DocsComponent";
import ShortcutsComponent from "@/app/core/layouts/main-components/ShortcutsComponent";
import ExigenciesInLine from "@/app/core/layouts/main-components/ExigenciesInLine";
import FileViewer from "@/app/core/layouts/main-components/FileViewer";
import DocumentsController from "@/app/core/base/controllers/DocumentsController";
import { NoteInsert } from "@/app/core/base/controllers/ProcessNotesController";
import { AnalysisHistory } from "@/app/core/base/controllers/services/HistoricService";
import NotificationComponent from "@/app/core/layouts/main-components/notifications/NotificationComponent";
import { ExigencyList } from "@/app/core/base/controllers/ExigenciesController";
import { ExigencyStatus } from "@/app/core/util/constants/ExigenciesConstants";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import DevolutionExigencyReport from "@/app/core/layouts/main-components/process/DevolutionExigencyReport";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import {
  HasMoreSteps,
  SelectedServiceAnalysisStatus,
} from "@/app/core/util/helpers/SelectedServiceHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import ConfirmReview from "@/app/core/layouts/main-components/organs/ConfirmReview";
import JobsController from "@/app/core/base/controllers/JobsController";
import FolderTemplatesComponent from "../../preparation/main/Templates";
import StatusTab from "@/app/core/layouts/main-components/process/StatusTab";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import ProcessHelper from "@/app/core/util/helpers/ProcessHelpers";

export default function MainDashboard(props) {
  const [loading, setLoading] = useState(false);
  const [pageKey, setPageKey] = useState(false);
  const [data, setData] = useState({});
  const [organList, setOrganList] = useState({});
  const [redirect, setRedirect] = useState(null);
  const [url, setUrl] = useState(null);
  const [rightUrl, setRightUrl] = useState(null);
  let screenHeight = window.innerHeight / 1.126;
  const [search, setSearch] = useState(null);
  const [observation, setObservation] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [disable, setDisable] = useState(false);
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [config, setConfig] = useState({ endButton: "Enviar p/ preparação" });
  const userData = Storage.getUserData();
  const enviroment = process.env.REACT_APP_ENV;

  const organCtrl = OrganListController();
  const docsCtrl = DocumentsController();
  const jobsDone = JobsController();

  useEffect(() => {
    // loadProcess();
    loadProcess();
    setPageKey(props.match.params.key);
    screenHeight = window.innerHeight / 1.2;
    Toggler.toggleSideOverlay();
  }, [window.updateDimensions, props.match.params]);

  async function loadProcess() {
    setLoading(true);
    await ProcessController().TheProcess(
      props.match.params.key,
      null,
      (res) => {
        setLoading(false);
        fileWork(res.docs);
        setData(res);

        for (let s in res.selectedServices) {
          const service = res.selectedServices[s];

          const organ = service.organList.filter(
            (x) => x.id == parseInt(props.match.params.key),
          );
          if (organ.length > 0) {
            setOrganList(organ[0]);
            processOrgan(organ[0], service);
          }
        }
      },
    );
  }

  function fileWork(files) {
    if (url == null && rightUrl == null) {
      let master = files.filter((x) => x.status === 1);
      let complementary = files.filter((x) => x.status === 2);

      if (master.length == 1) {
        showDoc(master[0].url);
      } else if (master.length > 1) {
        showDoc(master[0].url);
        showRightDoc(master[1].url);
      } else if (complementary.length > 0) {
        showDoc(complementary[0].url);
        if (complementary.length > 1) {
          showRightDoc(complementary[1].url);
        }
      }
    }
  }

  async function processOrgan(organ, selectedService) {
    if (organ.statusAnalysis === 0 || organ.stage === 0) {
      AnalysisHistory(organ.processId, organ.id); //Adicionando ao histórico
      setDisable(false);
    } else {
      if (organ.stage !== 2 && organ.stage !== 4) {
        setDisable(true);
      }
    }

    setConfig({ ...config, endButton: HasMoreSteps(2, selectedService.steps) });
  }

  async function saveAnalystNote(e) {
    e.preventDefault();
    if (observation !== "" && observation !== null) {
      if (file === null) {
        NoteInsert(
          {
            processId: data.id,
            observation: observation,
            fileId: parseInt(selectedDocument),
            organListId: organList.id,
          },
          (res) => {
            setObservation("");
            setSelectedDocument(null);
            toast.info("Salvo com sucesso");
            toggleClassObservation();
          },
        );
      } else {
        var formData = new FormData();
        formData.append("file", file);

        await docsCtrl.UploadDocs(
          data.id,
          formData,
          userData.id,
          organList.id,
          (res) => {
            NoteInsert(
              {
                processId: data.id,
                observation: observation,
                fileId: res[0].id,
              },
              (response) => {
                setFile(null);
                setObservation("");
                setSelectedDocument(null);
                toast.info("Salvo com sucesso");
                toggleClassObservation();
              },
            );
          },
        );
      }
    }
  }

  function toggleClass() {
    document.querySelector("#page-header-search").classList.toggle("show");
  }

  function toggleClassObservation() {
    document.querySelector("#page-header-observation").classList.toggle("show");
    document.getElementById("page-header-observation-input").focus();
  }

  function showDoc(url) {
    setUrl(url);
  }

  function showRightDoc(url) {
    setRightUrl(url);
  }

  async function UpdateStatus() {

    if (organList.statusAnalysis === 0 || organList.stage === 0) {
      if (userData.role === 0 || userData.id === organList.analystId) {
        let organ = organList;
        organ.stage = 2;
        organ.statusAnalysis = SelectedServiceAnalysisStatus.Ongoing;

        await organCtrl.Update(organ, (res) => {
          loadProcess();
        });
      }
    }
  }

  function closeAnalysis() {
    setRedirect(pageKey);
  }

  function endAnalysis() {
    let res = [];
    if (data.exigencies && data.exigencies.length > 0) {
      res = data.exigencies;
    }
    if (config.endButton == "Encerrar processo") {
      if (
        window.confirm("Você tem certeza de que deseja encerrar o processo?")
      ) {
        sendPreparation();
      }
    } else if (res.length > 0) {
      let results = res.filter((x) => x.status !== ExigencyStatus.done);
      const pending = res.filter(
        (x) =>
          x.status === ExigencyStatus.waitingConfirmation &&
          x.organListId == props.match.params.key,
      );
      if (pending.length > 0) {
        toast.error(
          "Confirme as exigências aguardando confirmação do operador para continuar",
        );
      } else {
        if (results.length > 0) {
          if (
            window.confirm(
              "Você tem certeza de que deseja enviar para a próxima etapa com Exigências em aberto?",
            )
          ) {
            sendPreparation();
          }
        } else {
          if (
            window.confirm(
              "Você tem certeza de que deseja enviar para a próxima etapa?",
            )
          ) {
            sendPreparation();
          }
        }
      }
    } else {
      if (
        window.confirm(
          "Você tem certeza de que deseja enviar para a próxima etapa?",
        )
      ) {
        sendPreparation();
      }
    }
    // });
  }

  async function sendPreparation() {
    await organCtrl.SendPreparation(organList.id, async (response) => {
      await jobsDone.Insert(organList.processId, organList.id, (res) => {
        toast.success(Message.Saved);
        setTimeout(() => {
          window.location = "#/app";
        }, 1200);
      });
    });
  }

  async function statusWaitingExigencies(status) {
    await organCtrl.Update({ ...organList, statusAnalysis: status }, (res) => {
      loadProcess();
      if (status === SelectedServiceAnalysisStatus.Ongoing) {
        toast.info(Message.Saved);
      }
    });
  }

  function openLightBox(item) {
    setImagesUrl([item]);
    setIsOpen(true);
  }

  if (redirect) return <Redirect to={`/app`} />;

  return (
    <div
      id="page-container"
      className={`enable-page-overlay side-scroll main-content-narrow sidebar-dark page-header-${Storage.getTheme()} side-overlay-hover`}
    >
      <Loading loading={loading} />

      {isOpen && (
        <Lightbox
          reactModalStyle={{ zIndex: 999999999999999999999 }}
          enableZoom={true}
          mainSrc={imagesUrl[photoIndex]}
          nextSrc={imagesUrl[(photoIndex + 1) % imagesUrl.length]}
          prevSrc={
            imagesUrl[(photoIndex + imagesUrl.length - 1) % imagesUrl.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesUrl.length - 1) % imagesUrl.length,
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesUrl.length)
          }
        >
          <button className="btn btn-square">has</button>
        </Lightbox>
      )}
      <header id="page-header">
        <div className="content-header">
          <div>
            <button
              type="button"
              className="btn btn-sm btn-outline-warning"
              onClick={() => toggleClass()}
            >
              <span className="ml-1 d-none d-sm-inline-block">+ Exigência</span>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-dark"
              onClick={() => toggleClassObservation()}
            >
              <span className="ml-1 d-none d-sm-inline-block">+ Obs</span>
            </button>
            {(organList &&
              organList.statusAnalysis ===
              SelectedServiceAnalysisStatus.WaitingOperatorConfirmation) ||
              (organList &&
                organList.statusAnalysis ===
                SelectedServiceAnalysisStatus.ReturnToCustomer) ? (
              <button
                type="button"
                className="btn btn-sm btn-outline-info mr-1"
                onClick={() =>
                  statusWaitingExigencies(SelectedServiceAnalysisStatus.Ongoing)
                }
              >
                Retornado ao cliente
              </button>
            ) : (
              ""
            )}
            <button
              type="button"
              className="btn btn-sm btn-dual mr-1"
              data-toggle="layout"
              onClick={() => Toggler.toggleSideOverlay()}
            >
              <i className="si si-settings"></i>
            </button>
            Nome do Processo : {data && data.processName}
          </div>
          {organList && organList.id !== undefined && (
            <ConfirmReview organList={organList} reload={() => loadProcess()} />
          )}
          <div>
            <div className="d-flex">
              <NotificationComponent
                processId={data && data.id}
                orientation={"left"}
              />
              {userData && userData.level === 4 ? (
                <div className="col-md-10">
                  <DevolutionExigencyReport
                    organListId={props.match.params.key}
                  />
                </div>
              ) : (
                <div className="col-md-8">
                  <button
                    type="button"
                    className="btn btn-sm btn-success mr-1"
                    onClick={() => {
                      endAnalysis();
                    }}
                  >
                    {config && config.endButton}
                  </button>
                </div>
              )}
              <button
                type="button"
                className="btn btn-dual mr-1"
                data-toggle="layout"
                onClick={() => {
                  closeAnalysis();
                }}
              >
                <i className="fa fa-fw fa-close text-danger"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          id="page-header-loader"
          className="overlay-header bg-primary-darker"
        >
          <div className="content-header">
            <div className="w-100 text-center">
              <i className="fa fa-fw fa-2x fa-sun fa-spin text-white"></i>
            </div>
          </div>
        </div>
        <div
          id="page-header-search"
          style={{ zIndex: 999999 }}
          className={
            "overlay-header bg-primary" +
            (search !== null && search !== undefined ? " show" : "")
          }
        >
          <ExigenciesInLine
            processId={data && data.id}
            organListId={parseInt(props.match.params.key)}
            toggleClass={() => toggleClass()}
          />
        </div>
        <div
          id="page-header-observation"
          style={{ zIndex: 999999 }}
          className={
            "overlay-header bg-primary" +
            (search !== null && search !== undefined ? " show" : "")
          }
        >
          <div className="content-header">
            <form className="w-100" onSubmit={(e) => saveAnalystNote(e)}>
              <div className="row">
                <div className="col-sm-7">
                  <input
                    type="text"
                    value={observation || ""}
                    onChange={(evt) => setObservation(evt.target.value)}
                    className="form-control"
                    placeholder="Descrição"
                    id="page-header-observation-input"
                    name="q"
                  />
                </div>
                <div className="col-sm-3">
                  <input
                    className="form-control"
                    type="file"
                    onChange={(evt) => setFile(evt.target.files[0])}
                  />
                </div>
                <div className="col-sm-2">
                  <button type="submit" className="btn btn-sm btn-info">
                    Salvar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => toggleClassObservation()}
                  >
                    <i className="fa fa-fw fa-times-circle" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </header>
      <div className="ml-1 mr-3 mb-1 mt-1 bg-dark">
        <div className="row" height="100%">
          <div className="col-md-6" style={{ height: screenHeight }}>
            <FileViewer
              file={url}
              height={screenHeight - 2}
              openImage={(item) => openLightBox(item)}
            />
          </div>
          <div className="col-md-6" style={{ height: screenHeight }}>
            <FileViewer
              file={rightUrl}
              height={screenHeight - 2}
              openImage={(item) => openLightBox(item)}
            />
          </div>
        </div>
      </div>

      <SideOverlay
        style={{ width: "535px" }}
        title={`Processo #${data && data.id}`}
      >
        <Tabs>
          <div
            className="nav nav-tabs nav-tabs-block nav-justified js-tabs-enabled"
            style={{ padding: 7 }}
          >
            <Tab className="nav-item">
              <button className="btn btn-sm btn-dual mr-1 border-right">
                <i className="si si-diamond"></i> Processo
              </button>
            </Tab>
            <Tab className="nav-item">
              <button className="btn btn-sm btn-dual mr-1 border-right">
                <i className="far fa-fw fa-clock"></i> Status
                {data && ProcessHelper().StatusTicker(data)}
              </button>
            </Tab>
            <Tab className="nav-item">
              <button className="btn btn-sm btn-dual mr-1 border-right">
                <i className="far fa-file-pdf"></i> Documentos
              </button>
            </Tab>
            <Tab className="nav-item">
              <button className="btn btn-sm btn-dual mr-1 border-right">
                <i className="far fa-fw fa-file-archive"></i> Timeline
              </button>
            </Tab>
            <Tab className="nav-item">
              <button className="btn btn-sm btn-dual mr-1 border-right">
                <i className="fa fa-fw fa-tasks"></i> Exigências
              </button>
            </Tab>
            <Tab className="nav-item">
              <button className="btn btn-sm btn-dual mr-1 border-right">
                <i className="far fa-fw fa-sticky-note"></i> Observações
                {data && data.customerNote && data.customerNote.length > 0 ? (
                  <i className="fa fa-exclamation-circle text-danger ml-2" />
                ) : (
                  ""
                )}
              </button>
            </Tab>
            <Tab className="nav-item">
              <button className="btn btn-sm btn-dual mr-1 border-right">
                <i className="far fa-fw fa-bookmark"></i> Consultas
              </button>
            </Tab>
            <Tab className="nav-item">
              <button
                type="button"
                className="btn btn-sm btn-dual mr-1 border-right"
                data-toggle="layout"
                alt="Exigências"
              >
                <i className="fa fa-link"></i> Links úteis
              </button>
            </Tab>
            <Tab className="nav-item">
              <button
                type="button"
                className="btn btn-sm btn-dual mr-1 border-right"
                data-toggle="layout"
                alt="Documentos"
              >
                <i className="far fa-fw fa-clipboard"></i> Templates
              </button>
            </Tab>
          </div>

          <Panel>
            <ProcessCard
              disabled={disable}
              processId={data && data.id}
              organListId={organList && organList.id}
              preLoad={data}
              refresh={() => loadProcess()}
            />
          </Panel>
          <Panel>
            <StatusTab
              processId={data && data.id}
              view={(data) => showDoc(data)}
              organListId={organList && organList.id}
              refresh={() => loadProcess()}
            />
          </Panel>
          <Panel>
            <div className="ml-3 mr-3">
              <DocsComponent
                processId={data && data.id}
                organListId={organList && organList.id}
                disabled={disable}
                notifyStart={() => UpdateStatus()}
                budgetId={0}
                setView={(data) => showDoc(data)}
                setRightView={(data) => showRightDoc(data)}
                preLoad={data}
                refresh={() => loadProcess()}
              />
            </div>
          </Panel>
          <Panel>
            <div
              className="ml-3 mr-3"
            // style={{ overflowY: "scroll", height: screenHeight }}
            >
              <TimelineComponent
                processId={data && data.id}
                preLoad={data}
                notifyStart={() => UpdateStatus()}
                view={(data) => showDoc(data)}
                refresh={() => loadProcess()}
                organListId={parseInt(props.match.params.key)}
              />
            </div>
          </Panel>
          <Panel>
            <div className="ml-3 mr-3">
              <ExigenciesComponent
                processId={data && data.id}
                organListId={parseInt(props.match.params.key)}
                disabled={disable}
                notifyStart={() => UpdateStatus()}
                report={(status) => statusWaitingExigencies(status)}
                preLoad={data}
                refresh={() => loadProcess()}
              />
            </div>
          </Panel>
          <Panel>
            <div className="ml-3 mr-3">
              <ProcessNotesComponent
                processId={data && data.id}
                organListId={parseInt(props.match.params.key)}
                open={(data) => showDoc(data)}
                notifyStart={() => UpdateStatus()}
                budgetId={0}
                disabled={disable}
              />
            </div>
          </Panel>
          <Panel>
            <div className="ml-3 mr-3">
              <ProtocolComponent
                processId={data && data.id}
                organListId={parseInt(props.match.params.key)}
                disabled={disable}
              />
            </div>
          </Panel>
          <Panel>
            <ShortcutsComponent />
          </Panel>
          <Panel>
            <FolderTemplatesComponent setView={(data) => showDoc(data)} />
          </Panel>
        </Tabs>
      </SideOverlay>

      <ToastContainer className="toastr" />
    </div>
  );
}
