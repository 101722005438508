import React, { useEffect, useState, useRef } from "react";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { CustomerController, CustomerList } from "@/app/core/base/controllers/CustomerController";
import ReportController from "@/app/core/base/controllers/ReportController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import CustomerListCard from "../../oportunities/main/CustomerListCard";

export default function Creditor({ startDate, endDate }) {
    const [filter, setFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);

    const colsDiscount = [
        { header: "Cód.", isSortable: true, name: "customerCode" },
        {
          header: "Nome",
          isSortable: true,
          name: "name",
          style: {
            maxWidth: "220px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        },
        { header: "Total", isSortable: true, name: "balance" },
        { header: "Última atualização", isSortable: true, name: "updatedDate" },
      ];
    
    useEffect(() => {
        reloadDiscount()
    }, [startDate, endDate])

    async function reloadDiscount() {
        setLoading(true);
        await ReportController().Creditor(startDate, endDate, res => {
            for(let s of res){
                s.balance = MoneyMask(s.balance);
            }
            setFilter(res)
    });
        setLoading(false);
    }

    return (
        <div>
            <div className="block-header mt-5">
                {/* <div className="block-title">Clientes que pagaram</div> */}
                <div className="block-tools d-flex">
                    {/* <DownloadTableExcel
                        filename="users table"
                        sheet={startDate && `Bloqueados - ${DateTranslate(startDate)} até ${DateTranslate(endDate)}`}
                        currentTableRef={tableRef.current}
                    >
                        <button className="btn btn-info"><i className="fa fa-download" /> Exportar Excel </button>
                    </DownloadTableExcel> */}
                </div>
            </div>
            {loading === true ? (
                <div className="block-content text-center">
                    <i className={"fa fa-circle-notch text-info fa-2x fa-spin"}></i>
                </div>
            ) : ""
        }
        <CustomerListCard
                title={"Clientes Credores"}
                infos={filter && filter}
                cols={colsDiscount}
                isOpen={true}
                reload={() => reloadDiscount()}
              />
        </div>
    )
}