import React from "react";
export function SendWhatsappBudget(name, phone, processId) {
  const address = `https%3A%2F%2Fapp.hasa.com.br%2F%23%2Fconfirm%2F${processId}`;
  const url = `https://api.whatsapp.com/send?phone=${phone}&text=Olá ${name}! Segue o link com o orçamento da Hasa Documentos : ${address}`;

  window.open(url, "_blank");
}

export function PhoneTranslate(i) {
  let phone = "";
  if (i === undefined) {
  } else if (i.length <= 9) {
    phone = "5511" + i;
  } else if (i.length > 9 && i.length < 13) {
    phone = "55" + i;
  } else {
    phone = i;
  }
  return phone;
}

export function TelephoneTranslate(i) {
  let phone = "";
  if (i === undefined) {
  } else if (i.length <= 9) {
    phone = "11" + i;
  } else if (i.length > 9 && i.length < 13) {
    phone = "" + i;
  } else {
    phone = i;
  }
  return phone;
}

export function PhoneMask(phone) {
  const number = PhoneTranslate(phone);
  const ddi = number.slice(0, 2);
  const ddd = number.slice(2, 4);
  const cel1 = number.slice(4, 9);
  const cel2 = number.slice(9, 13);

  const organized = `+${ddi} (${ddd}) ${cel1}-${cel2}`;
  if (number.length === 13) {
    return organized;
  } else if (number.length === 12) {
    return organized;
  }
}

export function TelphoneMask(phone) {
  const number = TelephoneTranslate(phone);
  const ddi = number.slice(0, 2);
  const ddd = number.slice(2, 6);
  const cel1 = number.slice(6, 13);

  const organized = `(${ddi}) ${ddd}-${cel1}`;
  if (number.length === 13) {
    return organized;
  } else if (number.length === 12) {
    return organized;
  } else {
    return organized;
  }
}

export function CpfMask(phone) {
  if (phone === undefined) {
    return "S.I.";
  }
  const number = phone + "";
  const ddi = number.slice(0, 3);
  const ddd = number.slice(3, 6);
  const cel1 = number.slice(6, 9);
  const cel2 = number.slice(9, 12);

  const organized = `${ddi}.${ddd}.${cel1}-${cel2}`;

  return organized;
}

export function CnpjMask(phone) {
  if (phone === undefined) {
    return "S.I.";
  }

  //99.999.999/9999-99
  const number = phone + "";
  const ddi = number.slice(0, 2);
  const ddd = number.slice(2, 5);
  const cel1 = number.slice(5, 8);
  const cel2 = number.slice(8, 12);
  const cel3 = number.slice(12, 15);

  const organized = `${ddi}.${ddd}.${cel1}/${cel2}-${cel3}`;

  return organized;
}

export function CepMask(cep) {
  if (cep === undefined) {
    return "S.I.";
  }
  const number = cep.toString().replace(/[^0-9]/g, ""); // Remove caracteres não numéricos
  const nb1 = number.slice(0, 5);
  const nb2 = number.slice(5, 8);

  const organized = `${nb1}-${nb2}`;

  return organized;
}
