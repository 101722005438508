import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "react-bootstrap/Tooltip";
import {
  ExigencyCustomerResponse,
  ExigencyList,
  ExigencyUpdate,
} from "@/app/core/base/controllers/ExigenciesController";
import DropdownElement from "../elements/DropdownElement";
import ContactSwitch from "../contact/ContactSwitch";
import DocsComponent from "../DocsComponent";
import { OpenOnGoogleDocs } from "@/app/core/util/helpers/DocsHelper";
import NotificationController from "@/app/core/base/controllers/NotificationController";
import { NotificationTypeExigencyFulfillment } from "@/app/core/util/helpers/NotificationHelper";
import { ExigencyStatus } from "@/app/core/util/constants/ExigenciesConstants";
import DocumentsController from "@/app/core/base/controllers/DocumentsController";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import OrganListHelper, {
  GetCurrentOperator,
} from "@/app/core/util/helpers/OrganListHelper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ProcessLinkText } from "@/app/core/util/helpers/ExigencyActionHelper";
import Storage from "@/app/core/util/Storage";
import ExigencyFulfillmentComponent from "../ExigencyFulfillmentModal";

export default function CustomerExigencies({
  organListId,
  isInternal,
  hideButtons,
  reportLogo,
}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState([]);
  const [all, setAll] = useState([]);
  const [organList, setOrganList] = useState({});
  const [selected, setSelected] = useState({});
  const [disable, setDisable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [exigencyData, setExigencyData] = useState(null);
  const [response, setResponse] = useState(null);
  const [process, setProcess] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const userData = Storage.getUserData();
  const inputFile = useRef(null);

  const organCtrl = OrganListController();
  const docsCtrl = DocumentsController();
  let screenWidth = window.innerWidth;

  useEffect(() => {
    loadOrganList();
  }, [organListId]);

  async function loadOrganList() {
    if (organListId !== null && organListId !== undefined) {
      await organCtrl.SelectById(organListId, (res) => {
        setOrganList(res);
        setProcess(res.process);
        setSelectedService(res.selectedService);
        loadExigencies(res.processId);
        if (reportLogo != undefined) {
          if (
            res.process &&
            res.process.customer &&
            res.process.customer.avatarImages &&
            res.process.customer.avatarImages.length > 0
          ) {
            reportLogo(res.process.customer.avatarImages[0].url);
          }
        }
      });
    }
  }

  async function timelineUpdates(message) {
    await NotifyUpdate(
      parseInt(process.id),
      message,
      false,
      TimelineChannelSistema,
      organList.id,
    );
  }

  function loadExigencies(id) {
    ExigencyList(process.id !== undefined ? process.id : id, (res) => {
      setAll(res);
      let exigencyRaw = [];
      let exigencyOpen = [];
      for (let s in res) {
        if (
          res[s].status === ExigencyStatus.waiting ||
          res[s].status === 0 ||
          res[s].status === ExigencyStatus.waitingConfirmation
        ) {
          exigencyRaw.push(res[s]);
        }

        if (res[s].status === ExigencyStatus.waiting) {
          exigencyOpen.push(res[s]);
        }
      }
      setOpen(exigencyOpen);
      setData(exigencyRaw);
      if (exigencyRaw.length === 0) {
        setDisable(true);
      }
    });
  }

  async function saveResponse(item) {
    if (response === null || response === undefined || response === "") {
      toast.error("Preencha todos os dados para poder salvar");
    } else {
      const responseRaw = {
        ...item,
        status: ExigencyStatus.waitingConfirmation,
        response: response === true ? null : response,
        notes: null,
        organListId:
          item.organListId === undefined ? organListId : item.organListId,
      };

      await ExigencyCustomerResponse(responseRaw, (res) => {
        loadExigencies();
        toast.success("Salvo com sucesso");
      });
    }
  }

  function removeDoc(item) {
    if (window.confirm("Deseja realmente retirar esse documento?")) {
      ExigencyUpdate({ ...item, processFileId: null }, (res) => {
        timelineUpdates(
          `O cliente removeu um documento como resposta de Exigência. O arquivo: ${item.url}`,
        );
        loadExigencies();
        toast.success("Salvo com sucesso");
      });
    }
  }

  function actionTypes(item) {
    if (item.action === 1) {
      return (
        <div>
          {docButton(
            item,
            item.processFileId !== undefined && item.processFileId !== 0,
          )}

          <DropdownElement
            submit={() => saveResponse(item)}
            icon=""
            disabled={item.response !== undefined && item.response !== ""}
            style={{ width: 310, height: 200 }}
            title={"Responder"}
          >
            <div className="form-group">
              <span>Resposta</span>
              <textarea
                type="text"
                className="form-control"
                onChange={(evt) => setResponse(evt.target.value)}
              />
            </div>
          </DropdownElement>
        </div>
      );
    }
    if (item.action === 2) {
      return (
        <div>
          <div>
            {docButton(
              item,
              item.processFileId !== undefined && item.processFileId !== 0,
            )}
          </div>
          <DropdownElement
            submit={() => saveResponse(item)}
            icon=""
            disabled={item.response !== undefined && item.response !== ""}
            style={{ width: 310, height: 200 }}
            title={"Responder"}
          >
            <div className="form-group">
              <span>Resposta</span>
              <textarea
                type="text"
                className="form-control"
                onChange={(evt) => setResponse(evt.target.value)}
              />
            </div>
          </DropdownElement>
        </div>
      );
    }
    if (item.action === 3) {
      return (
        <div>
          {docButton(
            item,
            item.processFileId !== undefined &&
              item.processFileId !== 0 &&
              item.status === ExigencyStatus.waitingConfirmation || item.status === ExigencyStatus.done,
            "Upload do comprovante",
          )}
        </div>
      );
    }
    if (item.action === 4 || item.action === 5) {
      return (
        <div>
          {docButton(
            item,
            item.processFileId !== undefined && item.processFileId !== 0,
          )}

          <DropdownElement
            submit={() => saveResponse(item)}
            icon=""
            disabled={item.response !== undefined && item.response !== ""}
            style={{ width: 310, height: 200 }}
            title={"Responder"}
          >
            <div className="form-group">
              <span>Resposta</span>
              <textarea
                type="text"
                className="form-control"
                onChange={(evt) => setResponse(evt.target.value)}
              />
            </div>
          </DropdownElement>
        </div>
      );
    }
  }

  function docButton(item, disable, name) {
    return (
      <div>
        <OverlayTrigger
          delay={{ hide: 450, show: 300 }}
          overlay={(props) => (
            <Tooltip {...props}>
              {item.processFileId !== undefined && item.processFileId !== 0
                ? `Arquivo enviado : ${item.processFile.name}`
                : "Nenhum arquivo enviado"}
            </Tooltip>
          )}
          placement="bottom"
        >
          {disable === true ? (
            <span className="btn btn-sm btn-outline-primary:disabled">
              <i className="far fa-arrow-alt-circle-up"></i>{" "}
              {name === undefined ? "Upload" : name}
            </span>
          ) : (
            <button
              className="btn btn-sm btn-outline-info"
              onClick={() =>
                disable === true
                  ? toast.info(`Arquivo enviado : ${item.processFile.name}`)
                  : UploadClick(item)
              }
            >
              <i className="far fa-arrow-alt-circle-up"></i>{" "}
              {name === undefined ? "Upload" : name}
            </button>
          )}
        </OverlayTrigger>
        {item.processFileId !== undefined && item.processFileId !== 0 ? (
          <button
            className="btn btn-sm btn-outline-danger"
            title="Remover documento"
              disabled={item.status === ExigencyStatus.done}
            onClick={() => removeDoc(item)}
          >
            {" "}
            <i className="si si-close"></i>
          </button>
        ) : (
          ""
        )}
      </div>
    );
  }

  function UploadClick(item) {
    setExigencyData(item);
    inputFile.current.click();
  }

  function hasFile(item) {
    if (
      item.fileUrl !== null &&
      item.fileUrl !== undefined &&
      item.fileUrl !== ""
    ) {
      return (
        <a
          className="btn btn-sm btn-outline-success"
          target="_blank"
          href={item.url}
          rel="noreferrer"
        >
          {" "}
          <i className="far fa-arrow-alt-circle-down"></i> Baixar Anexo
        </a>
      );
    } else if (item.sended !== null && item.sended !== undefined) {
      return (
        <a
          className="btn btn-sm btn-outline-success"
          target="_blank"
          title={item.sended.filename}
          href={item.sended.url}
          rel="noreferrer"
        >
          {" "}
          <i className="far fa-arrow-alt-circle-down"></i> Baixar Anexo
        </a>
      );
    } else {
      return null;
    }
  }

  async function uploadFile(file) {
    if (window.confirm(`O arquivo que pretende enviar é : ${file.name}?`)) {
      var formData = new FormData();
      formData.append("file", file);

      let userId = null;
      if (userData !== null) {
        userId = userData.id;
      }

      await docsCtrl.UploadDocs(
        process.id,
        formData,
        userId,
        organListId,
        (res) => {
          updateFeeExigency(res[0].id, file);
        },
      );
    }
  }

  async function updateFeeExigency(fileId, file) {
    ExigencyCustomerResponse(
      {
        ...exigencyData,
        processFileId: fileId,
        status: ExigencyStatus.waitingConfirmation,
        notes: null,
      },
      (res) => {
        toast.success("Salvo com sucesso");
        loadExigencies();
      },
    );
  }

  function toggleHistory(item) {
    setSelected(item);
    setShowModal(true);
  }

  function exigencyCard(item, index){
    return (
      <div className="block block-fx-pop block-bordered border-warning">
                <div className="block-header">
                  <div className="block-title">
                    {ProcessLinkText(item.description)}
                  </div>
                  {hideButtons !== true ? (
                    <div className="block-tools">{actionTypes(item)}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div class="block-content block-content-full d-flex justify-content-between block-content-sm bg-body-light">
                  <span class="font-size-sm text-muted">
                    {hasFile(item)} {" - "}
                    {item.status === ExigencyStatus.waitingConfirmation ? (
                      <td>Aguardando confirmação</td>
                    ) : (
                      <td>Pendente</td>
                    )}
                    {item.notes !== undefined ? (
                      <div class="table-active media p-2">
                        <div class="media-body">
                          <p class="mb-1">
                            <a class="font-w600" href="javascript:void(0)">
                              Resposta do operador :
                            </a>
                            <br />
                            {item.notes}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                  <span>
                    <button
                      className="btn btn-dual"
                      onClick={() => toggleHistory(item)}
                    >
                      <i className="si si-clock mr-2" />
                      Histórico de comunicação
                    </button>
                  </span>
                </div>
              </div>
    )
  }

  return (
    <>
      <div
        className=""
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          marginTop: screenWidth >= 600 ? "3em" : "1em",
          backgroundColor: "white",
        }}
      >
        <div
          style={
            screenWidth >= 600
              ? {
                  border: "1px solid #efefef",
                  backgroundColor: "white",
                  padding: "2em",
                  width: "100%",
                }
              : {
                  border: "1px solid #efefef",
                  backgroundColor: "white",
                  padding: "1em",
                  width: "100%",
                }
          }
        >
          {isInternal === true ? (
            ""
          ) : (
            <table className="table table-vcenter">
              <thead>
                <tr>
                  <div className="text-center">
                    <h4>Cumprimento de exigências</h4>
                    <h4>Processo : {process && process.processName}</h4>
                  </div>
                </tr>
              </thead>
            </table>
          )}
          <div className="row">
          {open.length !== 0 ? (
            <div className="col-sm-12 py-3 text-center">
              <ContactSwitch
                title="Dúvida no cumprimento?"
                processName={process && process.processName}
                processId={process && process.id}
                organListId={organList && organList.id}
              />
            </div>
          ) : (
            ""
          )}
        </div>
          {data &&
            data.map((item, index) => exigencyCard(item, index))}

          {all && all.filter(x => x.show === true && x.status === ExigencyStatus.done).length > 0 ? (
            <div className="content-heading">
              ({all.filter(x => x.show === true && x.status === ExigencyStatus.done).length}) Exigências concluídas
            </div>
          ):""}
          {all && all.filter(x => x.show === true && x.status === ExigencyStatus.done).map((item, index) => exigencyCard(item, index))}
          {data.length === 0 ? (
            <div className="text-center mt-5">
              <h4 className="text-muted">
                Você não possui exigências para cumprimento no momento.
                <br />
                <br />
                Em breve traremos novidades sobre seu processo :D
                <br />
                <br />
                Se ficar alguma dúvida acione o Fale Conosco, nossa equipe está
                a sua disposição!
              </h4>
            </div>
          ) : (
            ""
          )}
          {data.length === 0 && hideButtons === true ? (
            <div className="text-center text-muted">
              <h3>Nenhuma exigência registrada</h3>
            </div>
          ) : (
            ""
          )}

          <input
            type="file"
            style={{ visibility: "hidden" }}
            ref={inputFile}
            onChange={(event) => {
              uploadFile(event.currentTarget.files[0]);
            }}
          />
        </div>
        
        {hideButtons !== true ? (
          <div className="row">
            <div className="col-lg-12" align="center" style={{ margin: "1em" }}>
            </div>
          </div>
        ) : (
          ""
        )}
        <ExigencyFulfillmentComponent
          data={selected}
          show={showModal}
          organListId={organListId}
          onHide={() => setShowModal(!showModal) | setSelected({})}
          submit={(service) => setShowModal(!showModal)}
        />
      </div>
    </>
  );
}
