import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import OrganListHelper, { OrganStatus, OrganStatusTranslate } from "@/app/core/util/helpers/OrganListHelper";
import { PreparationStep } from "@/app/core/util/helpers/UserHelper";
import React, { useState } from "react";
import Productivity from "../../dashboard/components/main/Productivity";
import BudgetLine from "./BudgetLine";
import ProcessLine from "./ProcessLine";
import { toast } from "react-toastify";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import AccountController from "@/app/core/base/controllers/UsersController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";

export default function MemberComponent({ data, members, user, reload }) {
  const [open, setOpen] = useState(false);
  const [organListId, setOrganListId] = useState([]);
  const [newUser, setNewUser] = useState("");
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  function addOrgan(organId) {
    let organs = organListId;
    let exist = organs.filter((x) => x == organId);
    if (exist.length == 0) {
      organs.push(organId);
    }
    setOrganListId(organs);
    notyChange();
  }

  function notyChange() {
    setChange(date.getTime());
  }

  function removeOrgan(organId) {
    let organs = organListId.filter((x) => x != organId);
    setOrganListId(organs);
    notyChange();
  }

  async function redirectOrgans() {
    if (organListId.length == 0) {
      toast.error("Selecione ao menos 1 para prosseguir");
    } else if (newUser == "") {
      toast.error("Selecione um usuário para prosseguir");
    } else {
      for (let s in organListId) {
        await OrganListController().Redirect(
          organListId[s],
          newUser,
          (res) => {
            toast.success("Redirecionado com sucesso");
          },
          (error) => {
            if (
              error.response.data != undefined &&
              error.response.data != null &&
              error.response.data != ""
            ) {
              toast.error(
                `Processo ${error.response.data.process.processName} não direcionado porque o usuário de destino não tem o órgão atribuído ou estágio`,
              );
            }
          },
        );
      }
      reload();
      setOpen(false);
      setTimeout(() => {
        setOrganListId([]);
        setOpen(true);
      }, 300);
    }
  }

  function pauseOrStop() {
    AccountController().Update(
      { ...data.user, pause: !data.user.pause },
      (res) => {
        toast.success(Message.Saved);
        reload();
        notyChange();
      },
    );
  }

  function handleNumbers() {
    if (data.user && data.user.level === 1) {
      
            return (
              <div className="row">
                <div className="col-md-2 text-center">
                  <p className="font-w600 mb-0">
                    {data &&
                      data.openProcess &&
                      data.openProcess.length}
                  </p>
                  <p className="font-size-sm font-italic text-muted mb-0">
                    Não iniciados
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <p className="font-w600 mb-0">
                    {data &&
                      data.ongoingProcess &&
                      data.ongoingProcess.filter(
                        (x) => x.budgetStatus === BudgetStatus.WaitingApproval
                      ).length}
                  </p>
                  <p className="font-size-sm font-italic text-muted mb-0">
                    Aguardando aprovação
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <p className="font-w600 mb-0">
                    {data &&
                      data.ongoingProcess &&
                      data.ongoingProcess.filter(
                        (x) => x.budgetStatus === BudgetStatus.ReturnToCustomer
                      ).length}
                  </p>
                  <p className="font-size-sm font-italic text-muted mb-0">
                    Aguardando retornar ao cliente
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <p className="font-w600 mb-0">
                    {data &&
                      data.ongoingProcess &&
                      data.ongoingProcess.filter(
                        (x) => x.budgetStatus === BudgetStatus.Acepted
                      ).length}
                  </p>
                  <p className="font-size-sm font-italic text-muted mb-0">
                    Aceitos
                  </p>
                </div>
                <div className="col-md-2 text-center">
                  <p className="font-w600 mb-0">
                    {data &&
                      data.ongoingProcess &&
                      data.ongoingProcess.filter(
                        (x) => x.budgetStatus === BudgetStatus.Reavaliation
                      ).length}
                  </p>
                  <p className="font-size-sm font-italic text-muted mb-0">
                    Reavaliação
                  </p>
                </div>
                <div className="col-md-2 text-center">
                    <p className="font-w600 mb-0">
                    {data &&
                        data.doneProcess &&
                        data.doneProcess.length}
                    </p>
                    <p className="font-size-sm font-italic text-muted mb-0">
                      Concluídos
                    </p>
                  </div>
              </div>
            )

    } else if (data.user && data.user.level === 2) {

      return (
        <div className="row">
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.openOrganList &&
                data.openOrganList.length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Não iniciados
            </p>
          </div>
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.statusAnalysis === OrganListHelper().AnalysisStatus.Ongoing,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Em andamento
            </p>
          </div>
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.statusAnalysis === OrganListHelper().AnalysisStatus.WaitingExigencyFulfillment,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Aguardando comp. exigências
            </p>
          </div>
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.statusAnalysis === OrganListHelper().AnalysisStatus.WaitingOperatorConfirmation,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Aguardando conf. operador
            </p>
          </div>
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.statusAnalysis === OrganListHelper().AnalysisStatus.Reavaliation,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Reavaliação
            </p>
          </div>
          <div className="col-md-2 text-center">
              <p className="font-w600 mb-0">
              {data &&
                  data.doneOrganList &&
                  data.doneOrganList.length}
              </p>
              <p className="font-size-sm font-italic text-muted mb-0">
                Concluídos
              </p>
            </div>
        </div>
      )

    } else if (data.user && data.user.level === 3) {
      return (
        <div className="row">
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.openOrganList &&
                data.openOrganList.length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Não iniciados
            </p>
          </div>
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.statusPreparation === OrganListHelper().AnalysisStatus.Ongoing,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Em andamento
            </p>
          </div>
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.statusPreparation === OrganListHelper().AnalysisStatus.WaitingExigencyFulfillment,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Aguardando comp. exigências
            </p>
          </div>
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.statusPreparation === OrganListHelper().AnalysisStatus.WaitingOperatorConfirmation,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Aguardando conf. operador
            </p>
          </div>
          <div className="col-md-2 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.statusPreparation === OrganListHelper().AnalysisStatus.Reavaliation,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Reavaliação
            </p>
          </div>
          
          {data && data.user && data.user.level === 3  && data.reviewOrganList &&
                  data.reviewOrganList.length > 0? (
          <div
            className={
              data && data.user && data.user.level === 3
                ? "col-md-1 text-center"
                : ""
            }
          >
              <div>
                <p className="font-w600 mb-0">
                  {data &&
                    data.reviewOrganList &&
                    data.reviewOrganList.length}
                </p>
                <p className="font-size-sm font-italic text-muted mb-0">
                  Em conferência
                </p>
              </div>
          </div>
            ) : (
              ""
            )}
          <div className="col-md-2 text-center">
              <p className="font-w600 mb-0">
                {data &&
                  data.doneOrganList &&
                  data.doneOrganList.length}
              </p>
              <p className="font-size-sm font-italic text-muted mb-0">
                Concluídos
              </p>
            </div>

        </div>
      )
    } else if (data.user && data.user.level === 4) {
      return (
        <div className="row">

          <div className="col-md-3 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.status === OrganStatus.Filed,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Protocolados
            </p>
          </div>

          <div className="col-md-3 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.status === OrganStatus.Devolution,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Devolução
            </p>
          </div>

          <div className="col-md-3 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.ongoingOrganList &&
                data.ongoingOrganList.filter(
                  (x) => x.status === OrganStatus.Exigency,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Exigência
            </p>
          </div>

          <div className="col-md-3 text-center">
            <p className="font-w600 mb-0">
              {data &&
                data.doneOrganList &&
                data.doneOrganList.filter(
                  (x) => x.status === OrganStatus.Deferred,
                ).length}
            </p>
            <p className="font-size-sm font-italic text-muted mb-0">
              Deferidos
            </p>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="block block-rounded block-link-pop">
      <div className="block-content block-content-full">
        <div className="row">
          <div className="col-sm-3 d-flex">
            <img
              className="img-avatar img-avatar48"
              src={
                data && data.user.avatarImage === undefined
                  ? `${process.env.PUBLIC_URL}/assets/media/avatars/avatar8.jpg`
                  : data.user.avatarImage.url
              }
              alt=""
            />
            <div className="ml-3 text-left">
              <p className="font-w600 mb-0">
                {data && data.user.firstName} |{" "}
                {data && DepartmentTranslate(data.user.level)}
              </p>
              <p className="font-size-sm font-italic text-muted mb-0">
                {data && data.user.email}
              </p>
            </div>
          </div>
          <div className="col-sm-7">
              {handleNumbers()}
          </div>

          <div className="col-sm-2 text-center">
            <button
              className="btn btn-sm btn-light"
              onClick={() => pauseOrStop()}
            >
              {change && data.user.pause == true ? (
                <span>
                  <i className="fa fa-play text-success" /> Iniciar recebimento
                </span>
              ) : (
                <span>
                  <i className="fa fa-stop text-danger" /> Parar recebimento
                </span>
              )}
            </button>
            <button
              className="btn btn-sm btn-light"
              onClick={() => setOpen(!open)}
            >
              <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>
            </button>
          </div>
        </div>
        {open === true ? (
          <div className="row">
            <div className="col-md-12">
              <Productivity userData={data.user} hide={true} />
            </div>
            <div className="col-md-6">
              <div className="title">
                <h4 className="content-heading">
                  <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                  Processos
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <span>
                  Redirecionar {change && organListId && organListId.length}{" "}
                  itens para:
                </span>
                <select
                  className="form-control"
                  onChange={(evt) => setNewUser(evt.target.value)}
                >
                  <option value={null}>Selecione</option>
                  {members &&
                    members.map((item) => (
                      <option value={item.id}>
                        {item.firstName} - {item.email}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <button
                className="btn btn-block btn-success mt-4"
                onClick={() => redirectOrgans()}
              >
                Redirecionar
              </button>
            </div>
            {data && data.user && data.user.level !== 4 ? (
              <div className="col-md-12">
                {data &&
                  data.user &&
                  (data.user.level !== 3 ||
                    (data.user.preparationSteps &&
                      data.user.preparationSteps.filter(
                        (x) => x.step == PreparationStep.Track,
                      ).length > 0)) ? (
                  <div className="block block-rounded block-themed block-transparent">
                    <div className="block-header">
                      <div className="block-title">Não iniciados</div>
                    </div>
                    <div className="block-content">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <td>Nível</td>
                            <td>Nome</td>
                            {data && data.user && data.user.level !== 1 ? (
                              <td>Órgão</td>
                            ) : (
                              ""
                            )}
                            {data && data.user && data.user.level !== 1 ? (
                              <td>Serviço</td>
                            ) : (
                              ""
                            )}
                            <td>Status</td>
                            <td>Obs</td>
                            <td>Atualizado em</td>
                            <td>Data início</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.openOrganList &&
                            data.openOrganList.map((item) => (
                              <ProcessLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                                addOrgan={(organId) => addOrgan(organId)}
                                removeOrgan={(organId) => removeOrgan(organId)}
                              />
                            ))}
                          {data &&
                            data.openProcess &&
                            data.openProcess.map((item) => (
                              <BudgetLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {data &&
                  data.user &&
                  data.user.level === 3 &&
                  data.user.preparationSteps &&
                  data.user.preparationSteps.filter(
                    (x) => x.step == PreparationStep.Track,
                  ).length > 0 ? (
                  <div className="block block-rounded block-themed block-transparent">
                    <div className="block-header">
                      <div className="block-title">Em andamento</div>
                    </div>
                    <div className="block-content">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <td>Nível</td>
                            <td>Nome</td>
                            {data && data.user && data.user.level !== 1 ? (
                              <td>Órgão</td>
                            ) : (
                              ""
                            )}
                            {data && data.user && data.user.level !== 1 ? (
                              <td>Serviço</td>
                            ) : (
                              ""
                            )}
                            <td>Status</td>
                            <td>Obs</td>
                            <td>Atualizado em</td>
                            <td>Data início</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.ongoingOrganList &&
                            data.ongoingOrganList.map((item) => (
                              <ProcessLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                                addOrgan={(organId) => addOrgan(organId)}
                                removeOrgan={(organId) => removeOrgan(organId)}
                              />
                            ))}
                          {data &&
                            data.ongoingProcess &&
                            data.ongoingProcess.map((item) => (
                              <BudgetLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {data && data.user && data.user.level !== 3 ? (
                  <div className="block block-rounded block-themed block-transparent">
                    <div className="block-header">
                      <div className="block-title">Em andamento</div>
                    </div>
                    <div className="block-content">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <td>Nível</td>
                            <td>Nome</td>
                            {data && data.user && data.user.level !== 1 ? (
                              <td>Órgão</td>
                            ) : (
                              ""
                            )}
                            {data && data.user && data.user.level !== 1 ? (
                              <td>Serviço</td>
                            ) : (
                              ""
                            )}
                            <td>Status</td>
                            <td>Obs</td>
                            <td>Atualizado em</td>
                            <td>Data início</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.ongoingOrganList &&
                            data.ongoingOrganList.map((item) => (
                              <ProcessLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                                addOrgan={(organId) => addOrgan(organId)}
                                removeOrgan={(organId) => removeOrgan(organId)}
                              />
                            ))}
                          {data &&
                            data.ongoingProcess &&
                            data.ongoingProcess.map((item) => (
                              <BudgetLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {data &&
                  data.user &&
                  data.user.level === 3 &&
                  data.reviewOrganList &&
                  data.reviewOrganList.length > 0 ? (
                  <div className="block block-rounded block-themed block-transparent">
                    <div className="block-header">
                      <div className="block-title">Em conferência</div>
                    </div>
                    <div className="block-content">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <td>Nível</td>
                            <td>Nome</td>
                            <td>Órgão</td>
                            <td>Serviço</td>
                            <td>Status</td>
                            <td>Obs</td>
                            <td>Atualizado em</td>
                            <td>Data início</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.reviewOrganList &&
                            data.reviewOrganList.map((item) => (
                              <ProcessLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                                addOrgan={(organId) => addOrgan(organId)}
                                removeOrgan={(organId) => removeOrgan(organId)}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-12">
                  <div className="block block-rounded block-themed block-transparent">
                    <div className="block-header">
                      <div className="block-title">Concluídos</div>
                    </div>
                    <div className="block-content">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <td>Nível</td>
                            <td>Nome</td>
                            {data && data.user && data.user.level !== 1 ? (
                              <td>Órgão</td>
                            ) : (
                              ""
                            )}
                            {data && data.user && data.user.level !== 1 ? (
                              <td>Serviço</td>
                            ) : (
                              ""
                            )}
                            <td>Status</td>
                            <td>Obs</td>
                            <td>Atualizado em</td>
                            <td>Data início</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.doneOrganList &&
                            data.doneOrganList.map((item) => (
                              <ProcessLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                                done={true}
                              />
                            ))}
                          {data &&
                            data.doneProcess &&
                            data.doneProcess.map((item) => (
                              <BudgetLine
                                members={members}
                                item={item}
                                user={data.user}
                                reload={() => reload()}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12">
                <div className="block block-rounded block-themed block-transparent">
                  <div className="block-header">
                    <div className="block-title">
                      {" "}
                      {data &&
                        data.openOrganList &&
                        data.openOrganList.length}{" "}
                      - A protocolar
                    </div>
                  </div>
                  <div className="block-content">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <td>Nível</td>
                          <td>Nome</td>
                          <td>Órgão</td>
                          <td>Serviço</td>
                          <td>Status</td>
                          <td>Obs</td>
                          <td>Atualizado em</td>
                          <td>Data início</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.openOrganList &&
                          data.openOrganList.map((item) => (
                            <ProcessLine
                              members={members}
                              item={item}
                              user={data.user}
                              reload={() => reload()}
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="block block-rounded block-themed block-transparent">
                  <div className="block-header">
                    <div className="block-title">
                      {data &&
                        data.ongoingOrganList &&
                        data.ongoingOrganList.filter(
                          (x) => x.status === OrganStatus.Filed,
                        ).length}{" "}
                      - Protocolados
                    </div>
                  </div>
                  <div className="block-content">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <td>Nível</td>
                          <td>Nome</td>
                          <td>Órgão</td>
                          <td>Serviço</td>
                          <td>Status</td>
                          <td>Obs</td>
                          <td>Atualizado em</td>
                          <td>Data início</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.ongoingOrganList &&
                          data.ongoingOrganList.map((item) => {
                            if (item.status === OrganStatus.Filed) {
                              return (
                                <ProcessLine
                                  members={members}
                                  item={item}
                                  user={data.user}
                                  reload={() => reload()}
                                />
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="block block-rounded block-themed block-transparent">
                  <div className="block-header">
                    <div className="block-title">
                      {data &&
                        data.ongoingOrganList &&
                        data.ongoingOrganList.filter(
                          (x) => x.status === OrganStatus.Devolution,
                        ).length}{" "}
                      - Aguardando devolução do órgão
                    </div>
                  </div>
                  <div className="block-content">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <td>Nível</td>
                          <td>Nome</td>
                          <td>Órgão</td>
                          <td>Serviço</td>
                          <td>Status</td>
                          <td>Obs</td>
                          <td>Atualizado em</td>
                          <td>Data início</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.ongoingOrganList &&
                          data.ongoingOrganList.map((item) => {
                            if (item.status === OrganStatus.Devolution) {
                              return (
                                <ProcessLine
                                  members={members}
                                  item={item}
                                  user={data.user}
                                  reload={() => reload()}
                                />
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="block block-rounded block-themed block-transparent">
                  <div className="block-header">
                    <div className="block-title">
                      {data &&
                        data.doneOrganList &&
                        data.doneOrganList.filter(
                          (x) => x.status === OrganStatus.Deferred,
                        ).length}{" "}
                      - Deferidos
                    </div>
                  </div>
                  <div className="block-content">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <td>Nível</td>
                          <td>Nome</td>
                          <td>Órgão</td>
                          <td>Serviço</td>
                          <td>Status</td>
                          <td>Obs</td>
                          <td>Atualizado em</td>
                          <td>Data início</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.doneOrganList &&
                          data.doneOrganList.map((item) => {
                            if (item.status === OrganStatus.Deferred) {
                              return (
                                <ProcessLine
                                  members={members}
                                  item={item}
                                  user={data.user}
                                  reload={() => reload()}
                                />
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="block block-rounded block-themed block-transparent">
                  <div className="block-header">
                    <div className="block-title">
                      {data &&
                        data.ongoingOrganList &&
                        data.ongoingOrganList.filter(
                          (x) => x.status === OrganStatus.Exigency,
                        ).length}{" "}
                      - Exigências
                    </div>
                  </div>
                  <div className="block-content">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <td>Nível</td>
                          <td>Nome</td>
                          <td>Órgão</td>
                          <td>Serviço</td>
                          <td>Status</td>
                          <td>Obs</td>
                          <td>Atualizado em</td>
                          <td>Data início</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.ongoingOrganList &&
                          data.ongoingOrganList.map((item) => {
                            if (item.status === OrganStatus.Exigency) {
                              return (
                                <ProcessLine
                                  members={members}
                                  item={item}
                                  user={data.user}
                                  reload={() => reload()}
                                />
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="block block-rounded block-themed block-transparent">
                  <div className="block-header">
                    <div className="block-title">
                      {data &&
                        data.doneOrganList &&
                        data.doneOrganList.filter(
                          (x) => x.status === OrganStatus.Done,
                        ).length}{" "}
                      - Concluídos
                    </div>
                  </div>
                  <div className="block-content">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <td>Nível</td>
                          <td>Nome</td>
                          <td>Órgão</td>
                          <td>Serviço</td>
                          <td>Status</td>
                          <td>Obs</td>
                          <td>Atualizado em</td>
                          <td>Data início</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.doneOrganList &&
                          data.doneOrganList.map((item) => {
                            if (
                              item.status === OrganStatus.Done ||
                              item.stage === 10
                            ) {
                              return (
                                <ProcessLine
                                  members={members}
                                  item={item}
                                  user={data.user}
                                  done={true}
                                  reload={() => reload()}
                                />
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
