import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";

export default function ElaboratorTutorialModal({ show, onHide }) {
    const [loading] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <CustomModal
            title="Tutorial Completo"
            show={show}
            isLoading={loading}
            onHide={onHide}>

            <h3>O que é o Elaborador Automático de Contratos?</h3>
            <p>Nosso elaborador automático é uma ferramenta do Hasa Pro desenvolvida para ajudar você a elaborar contratos de Abertura, Alteração e Cancelamento através de perguntas e respostas sobre a empresa.</p>
            <h3>Benefícios:</h3>
            <ul className="mb-3 pb-3">
                <li>
                    <span className="font-w700">Automação:</span> Elabore contratos de forma rápida e eficiente, reduzindo o tempo e o esforço manual;
                </li>
                <li>
                    <span className="font-w700">Personalização:</span> Permite que o usuário baixe o arquivo em Word para inserir informações específicas, adaptando o contrato às suas necessidades;
                </li>
                <li>
                    <span className="font-w700">Agilidade:</span> Geração instantânea de contratos, permitindo que sejam elaborados em tempo recorde;
                </li>
                <li>
                    <span className="font-w700">Conformidade Legal:</span>  Os modelos utilizados são otimizados para registro, com base em milhares de deferimentos na Junta Comercial feitos por nossa equipe;
                </li>
                <li>
                    <span className="font-w700">Segurança:</span>  Armazenamento seguro e backup dos contratos gerados, evitando perdas e garantindo acessibilidade.
                </li>
            </ul>
            
            <h3>Funcionalidades:</h3>
            <ul className="mb-3 pb-3">
                <li>
                    <span className="font-w700">Modelos de Contratos:</span> Acesso a uma variedade de modelos prontos, incluindo Abertura, Alteração e Cancelamento de empresas LTDA e SLU. E isso é apenas o começo – outros tipos de contratos estão a caminho! 😉
                </li>
                <li>
                    <span className="font-w700">Interface Intuitiva:</span> O usuário pode preencher os campos de forma simples e rápida, sem necessidade de conhecimentos jurídicos avançados;
                </li>
                <li>
                    <span className="font-w700">Validação Automática:</span> O sistema verifica automaticamente a completude dos dados inseridos antes da geração do documento final;
                </li>
                <li>
                    <span className="font-w700">Facilidade:</span> Você pode enviar o link de preenchimento diretamente ao empresário, permitindo que ele responda às perguntas sobre a empresa. Dessa forma, a elaboração será concluída com as informações registradas de forma precisa.
                </li>
            </ul>
            
            <h3>Passo a Passo:</h3>
            <ul className="mb-3 pb-3">
                <div>
                <span className="font-w700">1. Clique em "Iniciar Contrato":</span> Dê início ao processo de elaboração do seu contrato.
                </div>
                <div>
                <span className="font-w700">2. Escolha o Tipo de Registro e Empresa:</span> Defina o tipo de registro desejado e a categoria da empresa.
                </div>
                <div>
                <span className="font-w700">3. Responda às Perguntas:</span> Preencha as informações solicitadas ou encaminhe o link para o responsável, para que ele possa responder.
                </div>
                <div>
                <span className="font-w700">4. Baixe o Documento:</span> Após a elaboração, faça o download do arquivo e faça correções ou personalizações conforme necessário.
                </div>
                <div>
                <span className="font-w700">5. Verifique as Informações:</span> Revise todos os dados antes da assinatura – essa etapa é fundamental para garantir a precisão.
                </div>
                <div>
                <span className="font-w700">6. Acesse o Contrato Finalizado:</span> Encontre o contrato elaborado no menu “Elaborador Automático de Contratos” para revisão final e uso.
                </div>
            </ul>
            
            <h3>Cuidados recomendados</h3>
            <p>Antes de usar o elaborador automático de contratos, o usuário deve tomar os seguintes cuidados:</p>
            <ul className="mb-3 pb-3">
                <div>
                    <span className="font-w700">1. Verificação de Dados:</span> Certifique-se de que todas as informações da empresa e das partes envolvidas estão corretas e atualizadas.
                </div>
                <div>
                    <span className="font-w700">2. Escolha do Modelo Adequado:</span> Escolha o modelo de contrato que melhor se adapta à sua necessidade, considerando o tipo de transação ou acordo.
                </div>
                <div>
                    <span className="font-w700">3. Leitura das Cláusulas:</span> Leia atentamente as cláusulas do contrato para garantir que todos os pontos importantes estejam incluídos e que não haja ambiguidades.
                </div>
                <div>
                    <span className="font-w700">4. Verificação de Normas Legais:</span> Esteja ciente das legislações e normas aplicáveis ao seu tipo de contrato e à sua área de atuação.
                </div>
                <div>
                    <span className="font-w700">5. Condições de Uso da Plataforma:</span> Familiarize-se com os termos de uso do elaborador automático e qualquer limitação ou responsabilidade que a plataforma possa ter.
                </div>
                <div>
                    <span className="font-w700">6. Confirmação de Identidade:</span> Assegure-se de que as partes que assinarão o contrato tenham sua identidade confirmada para evitar fraudes.
                </div>
                <div>
                    <span className="font-w700">7. Backup do Documento:</span> Após a elaboração, faça cópias de segurança do contrato para garantir que você tenha acesso às versões anteriores, se necessário.
                </div>
            </ul>
            <p> Esses cuidados ajudam a minimizar riscos e garantem que o contrato atenda às suas necessidades de forma eficaz.</p>
        </CustomModal>
    );
}
