import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { CustomerBillCols } from "@/app/core/layouts/main-components/customer/CustomerPendingCols";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function BillModal({ show, onHide, selected, customerId, customer }) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const cols = [
        { header: "Cód.", isSortable: true, name: "customerCode" },
        { header: "Nome", isSortable: true, name: "name" },
        { header: "Valor da cobrança", isSortable: true, name: "price" },
        { header: "Valor pago", isSortable: true, name: "done" },
        { header: "Saldo em aberto", isSortable: true, name: "pending" },
        {
            header: "Status",
            isSortable: true,
            name: "status",
            switch: {
                0: <span className="badge badge-warning">Em negociação</span>,
                1: <span className="badge badge-info">Pendente</span>,
                2: <span className="badge badge-success">Pago</span>,
                3: <span className="badge badge-dark">Atualizando</span>,
                4: <span className="badge badge-danger">Atrasado</span>,
            },
        },
    ];

    useEffect(() => {
        processValue()
    }, [selected, customerId]);

    function processValue() {
        let base = selected.chargeList;
        console.log("Resultado", selected);

        for (let s in base) {
            base[s].pending = 0;
            base[s].done = 0;

            for (let e in base[s].entries) {
                const item = base[s].entries[e];
                if (item.type == FinancialHelper().OperationType.Credit) {
                    base[s].done += item.value.toFixed(2);
                }
            }

            if (base[s].status == FinancialHelper().PaymentStatus.Paid) {
                base[s].done = base[s].price;
            }

            base[s].pending = MoneyMask((base[s].price + base[s].done), 2);
            base[s].price = MoneyMask(base[s].price, 2);
            base[s].done = MoneyMask(base[s].done, 2);
        }

        setData(base);

    }

    async function save() {
    }

    return (
        <Modal
            title={data && `Cobranças - ${data.month}`}
            show={show}
            isLoading={false}
            onHide={onHide}
            size="lg"
            onSave={() => save()}
            onSubmit={() => save()}>


            <Table
                title={data && data.chargeList && data.chargeList.length + " Cobranças"}
                tableId="billOfExhange"
                loading={false}
                data={data && data}
                columns={cols}
                hideHeader={true}
                viewAction={
                    (selected) =>
                        window.open(`#/customer-profile/${selected.data.customerId}`, "_blank")
                }
            />

        </Modal>
    );
}
