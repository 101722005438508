
import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import CopyToClipboard from "react-copy-to-clipboard";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { SendDrafterEmail } from "@/app/core/base/controllers/ContactController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function SendFulfillmentContractModal({ show, onHide, infos, customer }) {
    const [loading] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {}, []);

    function handleChanges(evt){
        setData(OnDataChange(data, evt));
    }

    function sendEmail(){
        if(!data.email){
            toast.error(Message.PendingData);
        }else{
            SendDrafterEmail(infos.id, customer.name, CustomerHelper().GetAvatar(customer), data.email);
        }
    }

    function sendWhats(){
        window.open(` https://wa.me/?text=Olá!%0DPara dar continuidade ao atendimento da sua empresa, precisamos de algumas informações.%0D 🔗 Acesse aqui para preencher o formulário:%0D%0D${encodeURIComponent(GetBaseUrl()+`#/drafter/${infos.id}`)}%0D%0DSe tiver dúvidas, é só nos chamar por aqui. Estamos à disposição para ajudar!`)   
    }

    function notifyCopy(){
        toast.success(Message.Copied);
    }
    
    return (
        <CustomModal
            title="Enviar para preenchimento"
            show={show}
            isLoading={loading}
            onHide={onHide}>
            <div className="content row">
                <div className="col-12">
                    <div className="form-group">
                        <span>Enviar por email</span>
                        <div className="input-group">
                            <input
                                className="form-control"
                                name="email"
                                placeholder="Email do empresário"
                                onChange={(evt) => handleChanges(evt)}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-success"
                                    onClick={() => sendEmail()}
                                >
                                    <i className="fa fa-location-arrow"></i> Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <span>Outras opções de compartilhamento</span>
                        <br />
                        <br />
                        <button
                            className="btn btn-success mr-2"
                            onClick={() => sendWhats()}
                        >
                            <i className="fab fa-whatsapp"></i> WhatsApp
                        </button>
                        OU
                        <CopyToClipboard
                            text={`${GetBaseUrl()}#/drafter/${infos && infos.id
                                }?customer=true`}

                        >
                            <button
                                type="button"
                                className="btn btn-info ml-2"
                                title="copiar"
                                onClick={() => notifyCopy()}
                            >
                                <i className="far fa-copy mr-1" /> Copiar URL
                            </button>
                        </CopyToClipboard>

                        <p className="mt-5 font-w700">

                            <b className="font-w700">
                                Informações importantes:
                            </b>
                            <br />
                            <br />
                            1 – O logo adicionado na Aba Aba Perfil será o logo que aparecerá nas mensagens enviadas ao cliente final (Empresário);
                            <br />
                            <br />
                            2 - Não somos responsáveis por acordos e pagamentos financeiros entre o parceiro Hasa e o cliente final;
                            <br />
                            <br />
                            3 – NNossa plataforma tem o objetivo de entregar ferramentas poderosas para nossos parceiros, caso possua dúvidas sobre a usabilidade fale com nosso time para orientação.
                        </p>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
}
