import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleUploadErrors } from "../../util/helpers/DocsHelper";

export const FinancialReminderController = {
    Insert: async function (data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `financialreminder/insert`,
                data
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    Update: async function (data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `financialreminder/update`,
                data
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    Response: async function (reminderId, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `financialreminder/response/${reminderId}`,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    AddContact: async function (reminderId, userId, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `financialreminder/insert/contact/${reminderId}?userId=${userId}`,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    Delete: async function (reminderId, response, error) {
        await BaseService(
            {
                method: "DELETE",
                url: `financialreminder/delete/${reminderId}`,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    ByCustomer: async function (customerId, response, error) {
        await BaseService(
            {
                method: "GET",
                url: `financialreminder/customer/${customerId}`,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    ByOperator: async function (response, error) {
        await BaseService(
            {
                method: "GET",
                url: `financialreminder/operator/`,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    Get: async function (reminderId, response, error) {
        await BaseService(
            {
                method: "GET",
                url: `financialreminder/${reminderId}`,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
}