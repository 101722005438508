import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const TemplateModelController = {

    Insert: async function (values, response) {
      BaseService(
        {
          method: "POST",
          url: "templateModel/insert",
          data: values,
        },
        (res) => {
          response(res);
        },
        (err) => {
          toast.error(HandleErrors(err));
        }
      );
    },
    
    Update: async function (values, response) {
      BaseService(
        {
          method: "POST",
          url: "templateModel/update",
          data: values,
        },
        (res) => {
          response(res);
        },
        (err) => {
          toast.error(HandleErrors(err));
        }
      );
    },
    
    Delete: async function (id, response) {
      BaseService(
        {
          method: "GET",
          url: `templateModel/delete/${id}`,
        },
        (res) => {
          response(res);
        },
        (err) => {
          toast.error(HandleErrors(err));
        }
      );
    },
    
    List: async function (response) {
      BaseService(
        {
          method: "GET",
          url: `templateModel`,
        },
        (res) => {
          response(res);
        },
        (err) => {
          toast.error(HandleErrors(err));
        }
      );
    }
} 
