import React, { useEffect, useState, useRef } from "react";
import { BudgetStatusTranslate } from "@/app/core/util/StatusTranslater";
import { Dropdown } from "react-bootstrap";
import Storage from "@/app/core/util/Storage";
import {
  SelectedServiceUpdate,
  GetSelectedService,
  RemoveSelectedService,
  PlayOrPauseService,
  ChangeSelectedService,
  ServiceList,
} from "@/app/core/base/controllers/ServicesController";
import { OnDataChange } from "@/app/core/util/services/DataService";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { toast } from "react-toastify";
import OrganCard from "../organs/OrganCard";
import {
  DeleteConfirm,
  Message,
  WrongRole,
} from "@/app/core/util/messages/GeralMessages";
import { SelectedServiceStatus } from "@/app/core/util/helpers/SelectedServiceHelper";
import { OrganStatus } from "@/app/core/util/helpers/OrganListHelper";
import DiscountListComponent from "../budget/DiscountListComponent";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { Typeahead } from "react-bootstrap-typeahead";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import DeleteServiceModal from "./DeleteServiceModal";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

export default function ServiceCard({
  service,
  reload,
  disable,
  organListId,
  process,
  selectedServices,
  preLoad,
}) {
  const [data, setData] = useState({});
  const [newOrgan, setNewOrgan] = useState({});
  const [organs, setOrgans] = useState(Storage.getOrgans());
  const [organList, setOrganList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledField, setDisabledField] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const userData = Storage.getUserData();
  const organCtrl = OrganListController();
  const [services, setServices] = useState(
    Storage.getServices() != null ? Storage.getServices() : [],
  );
  const [query, setQuery] = useState("");

  const dragItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    if (preLoad != undefined && preLoad.id != undefined) {
      setData(preLoad);
      organizeOrgans(preLoad.organList);
    } else if (service) {
      setData(service);
      loadOrganList(service.id);
    }

    if (userData.level === 1 || userData.level === 2) {
      loadOrgans();
    }
    if (disable !== undefined) {
      setDisabled(disable);
    }

    if (userData.level === 5) {
      setDisabled(true);
    }
    if (services.length == 0) {
      loadServices();
    }
  }, [service]);

  function loadServices() {
    ServiceList((res) => setServices(res));
  }

  function loadService() {
    GetSelectedService(data.id, (res) => {
      setData(res);
    });
  }

  async function loadOrganList(id) {
    if (id !== undefined) {
      // setLoading(true);
      await organCtrl.SelectByService(id, (res) => {
        organizeOrgans(res);
      });
    }
  }

  function organizeOrgans(res) {
    let itens = res.sort(function (a, b) {
      return a.position - b.position;
    });

    if (service && service.steps && service.steps.length > 0) {
      for (let s in itens) {
        if (service.steps.length == 1) {
          if (service.steps[0].department == userData.level) {
            itens[s].done = true;
          }
        } else if (service.steps.length == 2) {
          if (
            service.steps[0].department == userData.level &&
            userData.level == 2
          ) {
            itens[s].done = false;
          } else if (
            service.steps[0].department == userData.level &&
            userData.level == 3
          ) {
            itens[s].done = true;
          }
        }
      }
    }

    // setLoading(false);
    setOrganList(itens);
  }

  async function loadOrgans() {
    if (!organs || organs.length == 0) {
      await organCtrl.OrgansSelect((res) => {
        setOrgans(res);
      });
    }
  }

  async function deleteService() {
    if (userData.level !== 1 && userData.role === 0) {
      toast.error(WrongRole);
      return;
    }

    let hasDeferredOrDone = organList.filter(
      (x) => x.status === OrganStatus.Deferred || x.status === OrganStatus.Done,
    );

    if (hasDeferredOrDone.length > 0) {
      toast.error("Você não pode parar um serviço com órgãos deferidos");
      return;
    }
    if (window.confirm(DeleteConfirm)) {
      if (userData.level == 1) {
        if(process && (process.budgetStatus === BudgetStatus.Acepted || data.acceptedDate !== "0001-01-01T00:00:00+00:00")){
          setShowDeleteModal(true);
        }else{
          setLoading(true);
          await RemoveSelectedService(data.id, (res) => {
            setLoading(false);
            reload();
          });
        }
      } else if (userData.role > 0) {
        setShowDeleteModal(true);
      } else {
        toast.error(
          "Você não pode excluir um serviço. Entre em contato com um supervisor",
        );
      }
    }
  }

  function verifyContact() {
    if (data.budgetStatus === 4) {
      return (
        <button
          className="btn btn-outline-info"
          onClick={() => updateService({ ...data, budgetStatus: 5 })}
        >
          Entrei em contato com o cliente
        </button>
      );
    }
  }

  function handleChange(evt, type) {
    let infos = OnDataChange(data, evt, type);
    setData(infos);
  }

  function saveOrUpdate() {
    if (disabledField === false) {
      updateService(data);
    }else{
      setDisabledField(!disabledField);
    }
  }

  function updateService(info) {
    if (info.newServiceId) {
      const service = services.filter((x) => x.id == info.newServiceId);
      if (service.length > 0) {
        NotifyUpdate(
          info.processId,
          `Serviço alterado de ${info.serviceName} (${MoneyMask(info.price, 2)}) para ${service[0].name} (${MoneyMask(service[0].value, 2)}) autorizado por ${userData.firstName} ${userData.lastName}`,
          false,
        );
        info.serviceName = service[0].name;
        info.serviceId = service[0].id;
        ChangeSelectedService(info.id, service[0].id, (res) => loadService());
      }
    } else {
      if(info.installments !== undefined && info.installments > 0){
        const valorParcela = info.price / info.installments;

        if(valorParcela < 500){
          toast.error(`O valor mínimo por parcela aceito é de R$500,00. O serviço que está tentado atualizar está em ${MoneyMask(valorParcela)}`);
          setDisabledField(false)
          return;
        }
      }
      SelectedServiceUpdate(info, (res) => loadService());

    }
    setDisabledField(!disabledField);
  }

  function handleNewOrgan(evt) {
    let infos = OnDataChange(newOrgan, evt);
    setNewOrgan(infos);
  }

  async function addNewOrgan() {
    if (newOrgan.organId) {
      await organCtrl.Insert(
        {
          ...newOrgan,
          organId: parseInt(newOrgan.organId),
          processId: data.processId,
          SelectedServiceId: data.id,
          stage: 1,
          position: organList.length + 1,
        },
        (res) => {
          toast.info(Message.Saved);
          loadOrganList(data.id);
        },
      );
    }
  }

  function reloadList() {
    loadOrganList(data.id);
  }

  function status() {
    if (userData.level === 1) {
      return BudgetStatusTranslate(data.budgetStatus);
    }
    if (userData.level === 2) {
    }
    if (userData.level === 3) {
    }
    if (userData.level === 4) {
    }
  }

  function upperPosition() {
    if (data.stage > 2) {
      toast.error("Esse Serviço não pode mais ser movido");
      return;
    }

    if (data.position < selectedServices.length) {
      let oldData = data;
      SelectedServiceUpdate(
        { ...oldData, position: data.position + 1 },
        (res) => {
          reload();
        },
      );
    }
  }

  function lowerPosition() {
    if (data.stage > 2) {
      toast.error("Esse Serviço não pode mais ser movido");
      return;
    }
    if (data.position > 1) {
      let oldData = data;
      SelectedServiceUpdate(
        { ...oldData, position: data.position - 1 },
        (res) => {
          reload();
        },
      );
    }
  }

  function playService() {
    if (window.confirm("Tem certeza de que deseja iniciar esse serviço?")) {
      PlayOrPauseService(data.id, SelectedServiceStatus.Ongoing, (res) => {
        toast.info("Serviço iniciado");
        reload();
      });
    }
  }

  async function stopService() {
    if (window.confirm("Tem certeza de que deseja parar esse serviço?")) {
      if (userData.level === 1) {
        SelectedServiceUpdate(
          { ...data, status: SelectedServiceStatus.NotStarted },
          async (res) => {
            toast.info("Serviço parado");
            reload();
          },
        );
      } else if (userData.level === 2) {
        let otherServices = selectedServices.filter((x) => x.id !== data.id);
        if (otherServices.length === 0) {
          toast.error(
            "Você não pode parar o único serviço em andamento do processo",
          );
          return;
        }

        PlayOrPauseService(data.id, SelectedServiceStatus.NotStarted, (res) => {
          toast.info("Serviço parado");
          reload();
        });
      }
    }
  }

  const drop = async (e) => {
    if (
      disabled == true ||
      (userData &&
        userData.level !== 1 &&
        userData.level !== 2 &&
        userData.level !== 10)
    ) {
      return;
    }
    let copyListItems = [...organList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    for (let s in copyListItems) {
      copyListItems[s].position = parseInt(s) + 1;
    }

    setOrganList(copyListItems);
    await organCtrl.UpdateList(
      copyListItems,
      (res) => {},
      (error) => {},
    );
  };

  function dragStart(e, index) {
    dragItem.current = index;
  }
  function dragEnter(e, index) {
    dragOverItem.current = index;
  }

  return (
    <div
      className="block block-bordered border-2x border-info-light"
      key={`serviço-${data.position}`}
    >
      <div className="block-header">
        {disabledField === true ? (
          <div className="block-title">
            {userData && userData.level !== 5 ? `${data.position}º - ` : ""}
            Serviço : {data.serviceName && data.serviceName}
            {data && data.acceptedDate != "0001-01-01T00:00:00+00:00" ? (
              <small className="font-size-sm text-muted">
                <br />
                Aceito em: {data && DateTranslate(data.acceptedDate)}
              </small>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {disabledField === false && data.editable == false ? (
          <div className="block-title">
            {userData && userData.level !== 5 ? `${data.position}º - ` : ""}
            Serviço : {data.serviceName && data.serviceName}
          </div>
        ) : (
          ""
        )}

        {disabledField === false && data.editable == true ? (
          <input
            disabled={disabledField}
            className="form-control"
            value={data && data.serviceName}
            name="serviceName"
            onChange={(evt) =>
              setData({
                ...data,
                serviceNameEdited: evt.target.value,
                serviceName: evt.target.value,
              })
            }
          />
        ) : (
          ""
        )}

        {data && data.budgetStatus !== null ? verifyContact() : ""}
        {data && status()}
        {userData && userData.level !== 5 ? (
          <div className="block-tools d-flex align-items-center justify-content-between">
            {userData && (userData.level === 1 || userData.level === 2) ? (
              <button
                className="btn btn-sm btn-white"
                onClick={() => lowerPosition(data)}
                disabled={disabled !== undefined ? disabled : false}
              >
                <i className="si si-arrow-up" />
              </button>
            ) : (
              ""
            )}
            {userData && (userData.level === 1 || userData.level === 2) ? (
              <button
                className="btn btn-sm btn-white"
                onClick={() => upperPosition(data)}
                disabled={disabled !== undefined ? disabled : false}
              >
                <i className="si si-arrow-down" />
              </button>
            ) : (
              ""
            )}
            <br />

            <button
              className="btn btn-sm btn-light"
              onClick={() => deleteService()}
            >
              <i className="si si-close text-danger" /> Remover
            </button>
            {/* {userData &&
            (userData.level === 1 || userData.role === 1) &&
            data.stage !== 4 ? (
            ) : (
              ""
            )} */}

            {data && data.status === 0 ? (
              <button
                className="btn btn-sm btn-light"
                title="Iniciar Serviço"
                onClick={() => playService()}
              >
                <i className="fa fa-play text-success" />
              </button>
            ) : (
              <button
                className="btn btn-sm btn-light"
                title="Parar Serviço"
                onClick={() => stopService()}
              >
                <i className="fa fa-stop text-danger" />
              </button>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="block-content">
        <div className="form-group">
          <div className="row">
            <div className="col-6">
              <span>Descrição : </span>
            </div>
            <div className="col-6 text-right">
            {userData && userData.level !== 5 ? (
            <button
              className="btn btn-sm btn-square btn-success"
              disabled={disabled}
              onClick={() => saveOrUpdate(data)}
            >
              {disabledField === true ? (
                <span>
                  <i className="far fa-edit" /> Editar
                </span>
              ) : (
                "Salvar"
              )}
            </button>
          ) : (
            ""
          )}
            </div>
          </div>
          <br />
          {disabledField === true ? (
            <p>{data && data.description}</p>
          ) : (
            <textarea
              disabled={disabledField}
              className="form-control"
              value={data && data.description}
              name="description"
              onChange={(evt) => handleChange(evt)}
            />
          )}
           {disabledField === true ? (
                        <div className="form-group">
                <span>{data && data.installments > 1 ? `Parcelas: ${data.installments}` : ""} </span>
                        </div>          
          ) : (
            <div className="form-group">
                <span>Parcelas: </span>
                {disabledField === true ? (
                  <p>{data && `${MoneyMask(data.price, 2)}`} </p>
                ) : (
                  <input
                    disabled={disabledField}
                    className="form-control"
                    value={data && data.installments}
                    type="number"
                    name="installments"
                    max={6}
                    min={0}
                    onChange={(evt) =>
                      setData({
                        ...data,
                        installments: parseInt(evt.target.value),
                      })
                    }
                  />
                )}
              </div>
            
          )}
          {disabledField === true ? (
            ""
          ) : (
            <div className="form-group">
              <span>Trocar Serviço por</span>
              <Typeahead
                options={services}
                onSearch={(q) => setQuery(q)}
                defaultInputValue={query}
                clearButton={true}
                name="name"
                id={`newService-`}
                placeholder="Selecionar serviço"
                labelKey={(option) =>
                  `${option.name} - ${MoneyMask(option.value, 2)}`
                }
                onChange={(selected) => {
                  if (selected[0] !== undefined) {
                    setData({ ...data, newServiceId: selected[0].id });
                  }
                }}
              />
            </div>
          )}
          {data && data.editable == true ? (
            <div className="row">
              <div className="form-group col-6">
                <span>Valor : </span>
                {disabledField === true ? (
                  <p>{data && `${MoneyMask(data.price, 2)}`} </p>
                ) : (
                  <input
                    disabled={disabledField}
                    className="form-control"
                    value={data && data.price}
                    type="number"
                    name="price"
                    onChange={(evt) =>
                      setData({
                        ...data,
                        priceEdited: parseFloat(evt.target.value),
                        price: parseFloat(evt.target.value),
                      })
                    }
                  />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          
         
          
        </div>
        {userData && userData.level !== 5 && userData.level === 1 ? (
          <div className="col-12">
            <DiscountListComponent
              service={data}
              refresh={() => loadService()}
              disabled={disabled}
              selectedServiceId={data && data.id}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="block-header mb-0 pb-0" style={{marginTop: -40}}>
        <div className="block-title mb-0 pb-0">
          <h4 className="content-heading mb-0 pb-0">
            <i className="fa fa-angle-right text-muted mr-1"></i> Órgãos
          </h4>
        </div>
        <div className="block-tools d-flex align-items-center justify-content-between">
          <a className="btn btn-dual btn-sm border-right" href="https://hasa.com.br/documentos_necessarios/" target="_blank"><i className="far fa-file"/> Documentos Necessários</a>
          {userData &&
          (userData.level === UserHelper.Level.Comercial ||
            userData.level === UserHelper.Level.Analyst ||
            (userData.role > 0 && userData.level !== UserHelper.Level.Customer)) ? (
            <Dropdown>
              <Dropdown.Toggle
                variant={"light"}
                disabled={disabled}
                className="btn btn-sm btn-dual"
              >
                <i className="si si-list" /> Adicionar órgão
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="form-group">
                  <span>Órgão</span>
                  <select
                    className="form-control"
                    name="organId"
                    value={newOrgan.organId}
                    onChange={(evt) => handleNewOrgan(evt)}
                  >
                    <option value={null}>Selecione</option>
                    {organs &&
                      organs.map((organ) => (
                        <option key={`organ-${organ.id}`} value={organ.id}>
                          {organ.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-sm btn-square btn-sucess"
                    disabled={disabled}
                    onClick={() => addNewOrgan()}
                  >
                    Salvar
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </div>
      </div>
      {loading === true ? (
        <div className="block-content text-center">
          <i className={"fa fa-circle-notch fa-spin"}></i>
        </div>
      ) : (
        <div className="block-content">
          {organList.map((organ, index) => (
            <div
              className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn"
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              key={`organlist-${data.id}-${index}`}
              onDragEnd={drop}
              draggable
            >
              <OrganCard
                baseData={organ}
                organs={organList}
                refresh={() => reloadList()}
                organListId={organListId}
                selectedService={service}
                process={process}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
      )}
      <DeleteServiceModal
        showModal={showDeleteModal}
        data={data && data}
        onHide={() => setShowDeleteModal(false) | reload()}
      />
    </div>
  );
}
