import React, { useEffect, useState } from "react";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { AnalysisStatusTranslate } from "@/app/core/util/StatusTranslater";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import {
  OrganStatus,
  OrganStatusTranslate,
  OrganStatusList,
} from "@/app/core/util/helpers/OrganListHelper";
import { PreparationStep } from "@/app/core/util/helpers/UserHelper";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function ProcessLine({
  item,
  user,
  members,
  reload,
  addOrgan,
  removeOrgan,
  done,
}) {
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const userData = Storage.getUserData();

  const [candidates, setCandidates] = useState([]);
  const [newUser, setNewUser] = useState("");
  const [newDevolutionUser, setNewDevolutionUser] = useState({});
  const organCtrl = OrganListController();

  useEffect(() => {
    let responses = [];
    members &&
      members.map((user) => {
        let assignments = user.assignments;
        let results = assignments.filter((x) => x.organId === item.organId);
        if (results.length > 0) {
          if (user.level === item.stage) {
            if (item.stage != 3) {
              responses.push(user);
            } else {
              const preparerTrack = user.preparationSteps.filter(
                (x) => x.step == PreparationStep.Track,
              );
              const preparerReview = user.preparationSteps.filter(
                (x) => x.step == PreparationStep.Review,
              );
              if (
                item.preparationStage === PreparationStep.Track &&
                preparerTrack.length > 0
              ) {
                responses.push(user);
              } else if (
                item.preparationStage === PreparationStep.Review &&
                preparerReview.length > 0
              ) {
                responses.push(user);
              }
            }
          }
        }
      });

    setCandidates(responses);
  }, []);

  function openSelectedService() {
    if (user.level === 2) {
      window.open(`#/analysis/${item.id}`, "_blank");
    } else if (user.level === 3) {
      window.open(`#/preparation/${item.id}`, "_blank");
    } else {
      setSelectedData({
        processId: item.processId,
        id: item.processId,
        organListId: item.id,
      });
      setShowProcessModal(true);
    }
  }

  async function redirectProcess() {
    if (newUser.length === 0 || newUser === null) {
      toast.error("Selecione um usuario para salvar");
    } else {
      if (window.confirm("Deseja redirecionar esse processo?")) {
        let selectedService = item;
        if (user.level === 2) {
          selectedService.analystId = newUser;
        } else if (user.level === 3) {
          if (item.preparationStage == PreparationStep.Track) {
            selectedService.preparerId = newUser;
          } else if (item.preparationStage == PreparationStep.Review) {
            selectedService.preparerReviewId = newUser;
          }
        } else {
          selectedService.devolutionId = newUser;
        }

        await organCtrl.Update(selectedService, (res) => {
          toast.success("Salvo com sucesso");
          reload();
        });
      }
    }
  }

  async function redirectDevolutionProcess() {
    if (
      newDevolutionUser.userId.length === 0 ||
      newDevolutionUser.step === null
    ) {
      toast.error("Selecione um usuario para salvar");
    } else {
      if (window.confirm("Deseja redirecionar esse processo?")) {
        let organ = item;
        if (newDevolutionUser.step === OrganStatus.NotStarted) {
          organ.devolutionNotStartedId = newDevolutionUser.userId;
        } else if (newDevolutionUser.step === OrganStatus.Filed) {
          organ.devolutionFiledId = newDevolutionUser.userId;
        } else if (newDevolutionUser.step === OrganStatus.Devolution) {
          organ.devolutionDevolutionId = newDevolutionUser.userId;
        } else if (newDevolutionUser.step === OrganStatus.Exigency) {
          organ.devolutionExigencyId = newDevolutionUser.userId;
        } else if (newDevolutionUser.step === OrganStatus.Deferred) {
          organ.devolutionDeferredId = newDevolutionUser.userId;
        }

        await organCtrl.Update(organ, (res) => {
          toast.success("Salvo com sucesso");
          reload();
        });
      }
    }
  }

  function processStatus(item) {
    if (user.level === 2) {
      return AnalysisStatusTranslate(item.statusAnalysis);
    } else if (user.level === 3) {
      return AnalysisStatusTranslate(item.statusPreparation);
    } else if (user.level === 4) {
      return OrganStatusTranslate(item.status);
    } else {
      return "S.I.";
    }
  }

  function defineStep(status) {
    if (status === 0) {
      return "devolutionNotStarted";
    }
    if (status === 1) {
      return "devolutionFiled";
    }
    if (status === 2) {
      return "devolutionFiled";
    }
    if (status === 3) {
      return "devolutionExigency";
    }
    if (status > 4) {
      return "devolutionDeferred";
    }
  }

  function getStartedDate() {
    if (user.level === 1) {
      return DateTranslate(item.createdDate);
    }
    if (user.level === 2) {
      return DateTranslate(item.process.endBudgetDate);
    }
    if (user.level === 3) {
      return DateTranslate(item.endAnalysisDate);
    }
    if (user.level === 4) {
      return DateTranslate(item.endPreparationDate);
    }
  }

  function onCheck(evt) {
    if (evt.target.checked == true) {
      addOrgan(item.id);
    } else {
      removeOrgan(item.id);
    }
  }

  return (
    <tr>
      <td>
        {done == undefined ? (
          <div className="custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-lg custom-control-dark mb-1">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`check-${item.id}`}
              name={`check-${item.id}`}
              onChange={(evt) => onCheck(evt)}
            />
            <label className="custom-control-label" for={`check-${item.id}`}>
              {item.process && item.process.customer && item.process.customer.level !== undefined  && CustomerHelper().ShowSupervisorLevel(item.process.customer.level)}
            </label>
          </div>
        ) : (
          `${item.id}`
        )}
      </td>
      <td>{item.processName}</td>
      <td>{item.organName}</td>
      <td>{item.serviceName}</td>
      <td>{processStatus(item)}</td>
      <td>
        {item.returned === true ? (
          <span className="badge badge-danger">Retornado com exigência</span>
        ) : (
          ""
        )}
        {item.reavaliation > 0 && item.reavaliation < user.level ? (
          <span className="badge badge-dark">Reavaliação</span>
        ) : (
          ""
        )}
        {item.internalFeedback === true ? (
          <div>
            <span className="badge badge-info">Feedback interno</span>
          </div>
        ) : (
          ""
        )}
        {item.customerReturn === true ? (
          <div>
            <span className="badge badge-warning">Ret. do cliente</span>
          </div>
        ) : (
          ""
        )}
        {item.urgent === true ? (
          <div>
            <span className="badge badge-danger">
              <i className="fa fa-exclamation-circle" /> Urgente
            </span>
          </div>
        ) : (
          ""
        )}
        {item.consulting === true ? (
          <div>
            <span className="badge badge-secondary">Consultando</span>
          </div>
        ) : (
          ""
        )}
      </td>
      <td>{DateTranslate(item.updatedDate)}</td>
      <td>{getStartedDate()}</td>
      <td>
        <div className="row">
          <div className="col-md-6">
            <button
              className="btn btn-dual"
              onClick={() => openSelectedService()}
            >
              <i className="fa fa-eye" />
            </button>
          </div>
          <div className="col-md-6">
            {item && item.stage !== 4 ? (
              <DropdownElement
                icon={"fa fa-edit"}
                button={"Salvar"}
                style={{ width: 330 }}
                orientation={"left"}
                submit={() => redirectProcess()}
              >
                <div className="form-group">
                  <span>Atribuir para</span>
                  <select
                    className="form-control"
                    onChange={(evt) => setNewUser(evt.target.value)}
                  >
                    <option value={null}>Selecione</option>
                    {candidates &&
                      candidates.map((item) => (
                        <option value={item.id}>
                          {item.firstName} - {item.email}
                        </option>
                      ))}
                  </select>
                </div>
              </DropdownElement>
            ) : (
              <div>
                <DropdownElement
                  icon={"fa fa-edit"}
                  style={{ width: 330 }}
                  button={"Salvar"}
                  orientation={"left"}
                  submit={() => redirectDevolutionProcess()}
                >
                  {OrganStatusList.map((organStatus) => {
                    if (organStatus.visible === true) {
                      return (
                        <div className="form-group ribbon ribbon-right ribbon-bookmark ribbon-warning">
                          {item.status === organStatus.value ? (
                            <div
                              className="ribbon-box"
                              style={{ marginTop: 27 }}
                            >
                              <small>Atual</small>
                            </div>
                          ) : (
                            ""
                          )}
                          <span>"{organStatus.name}" Atribuir para</span>
                          <select
                            className="form-control"
                            onChange={(evt) =>
                              setNewDevolutionUser({
                                userId: evt.target.value,
                                step: organStatus.value,
                              })
                            }
                          >
                            <option value={null}>Selecione</option>
                            {candidates &&
                              candidates.map((element) => {
                                const steps = element.devolutionSteps;
                                const results = steps.filter(
                                  (x) => x.step === organStatus.value,
                                );
                                if (results.length > 0) {
                                  return (
                                    <option value={element && element.id}>
                                      {element && element.firstName} -{" "}
                                      {element && element.email}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </div>
                      );
                    }
                  })}
                </DropdownElement>
              </div>
            )}
          </div>
        </div>
      </td>
      <OperationProcessModal
        data={selectedData}
        show={showProcessModal}
        onHide={() => setShowProcessModal(false) | setSelectedData({})}
      />
    </tr>
  );
}
