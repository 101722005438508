import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import UserModal from "@/app/modules/process/components/UserModal";
import CustomerCard from "../../main-components/customer/CustomerCard";
import ProcessHistoryCard from "../../main-components/process/ProcessHistoryCard";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import ServiceCard from "../../main-components/services/ServiceCard";
import { SelectedServices } from "@/app/core/base/controllers/ServicesController";
import { Dropdown } from "react-bootstrap";
import ReavaliationCard from "../../main-components/process/ReavaliationCard";
import ResponsibleCard from "../../main-components/process/ResponsibleCard";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import OrganListHelper, {
  OrganStatus,
  OrganStatusList,
} from "@/app/core/util/helpers/OrganListHelper";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import ProcessHelper from "@/app/core/util/helpers/ProcessHelpers";
import { Message } from "@/app/core/util/messages/GeralMessages";
import ServiceDropdown from "../../main-components/services/ServiceDropdown";
import TimelineController, {
  NotifyUpdate,
} from "@/app/core/base/controllers/TimelineController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import ProcessMirror from "../../main-components/documents/ProcessMirror";
import DropdownElement from "../../main-components/elements/DropdownElement";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import FollowingProcessButton from "../../main-components/process/FollowingProcessButton";
import { ProcessStatus } from "@/app/core/util/helpers/SelectedServiceHelper";
import {
  NoteInsert,
  ProcessNotes,
} from "@/app/core/base/controllers/ProcessNotesController";
import DevolutionDoc from "../../main-components/documents/DevolutionDoc";

export default function ProcessCard({
  refresh,
  processId,
  disabled,
  organListId,
  reportFractioned,
  preLoad,
}) {
  const user = Storage.getUserData();
  const [data, setData] = useState({});
  const [organList, setOrganList] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [status, setStatus] = useState("Em andamento");
  const [customerNote, setCustomerNote] = useState("");
  const [showUserModal, setShowUserModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [active, setActive] = useState(true);
  const [processName, setProcessName] = useState("");
  const [disable, setDisable] = useState(false);
  const [consult, setConsult] = useState({});

  const [updateInfo, setUpdateInfo] = useState({});
  const enviroment = process.env.REACT_APP_ENV;

  const processHelper = ProcessHelper();
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();
  const timelineCtrl = TimelineController();

  useEffect(() => {
    if (preLoad != undefined && preLoad.id != undefined) {
      organizeData(preLoad);
    }
    if (disabled === undefined || disabled === null) {
      if (preLoad && preLoad.status == ProcessStatus.Returned) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(disabled);
    }
  }, [processId, disabled, organListId, preLoad]);

  async function loadConsults() {
    let base = preLoad;
    for (let s in base) {
      let organs = [];
      for (let i in base.selectedServices) {
        organs = [...organs, ...base.selectedServices[i].organList];
      }
      base.organList = organs;
    }
    setConsult(base);
  }

  function registerObservation() {
    if (customerNote.length == 0) {
      toast.error(Message.PendingData);
    } else {
      const note = `O cliente registrou a seguinte observação: ${customerNote}`;
      NotifyUpdate(data.id, note, false);
      NoteInsert(
        {
          ProcessId: data.id,
          Observation: note,
          userId: userData.id,
        },
        (res) => {
          toast.success(Message.Saved);
        },
      );
      processCtrl.Update({ ...data, customerNote }, (res) => {});
    }
  }

  async function organizeData(res) {
    setData(res);
    setProcessName(res.processName);
    presentSelectedServices();
    let sts = "Em andamento";
    if (res.status === 2) {
      sts = "Concluído";
      setStatus(sts);
      for (let s in preLoad.selectedServices) {
        const service = preLoad.selectedServices[s];
        const organ = service.organList.filter(
          (x) => x.id == parseInt(organListId),
        );
        if (organ.length > 0) {
          setStatus(`${sts} - ${DepartmentTranslate(organ[0].stage)}`);
          setOrganList(organ[0]);
        }
      }
    } else if (res.status === 9) {
      sts = "Excluído";
      setStatus(sts);
    } else if (organListId) {
      for (let s in preLoad.selectedServices) {
        const service = preLoad.selectedServices[s];
        const organ = service.organList.filter(
          (x) => x.id == parseInt(organListId),
        );
        if (organ.length > 0) {
          setStatus(`${sts} - ${DepartmentTranslate(organ[0].stage)}`);
          setOrganList(organ[0]);
        }
      }
    }
    loadConsults();
  }

  async function registerUrgency() {
    await organCtrl.Urgent(data.id, !data.urgent, (res) => {
      toast.success("Salvo com sucesso");
      refresh();
    });
  }

  function presentSelectedServices() {
    const services = preLoad.selectedServices;
    const itens = services.sort(function (a, b) {
      return a.position - b.position;
    });

    let fractioned = services.filter((x) => x.steps && x.steps.length > 0);
    if (fractioned.length > 0 && reportFractioned != undefined) {
      reportFractioned();
    }
    setSelectedServices(itens);
  }

  function reload() {
    refresh();
  }

  async function saveProcess() {
    await processCtrl.Update({ ...data, processName: processName }, (res) => {
      if (refresh !== undefined) {
        refresh();
      }
      toast.info("Salvo com sucesso");
    });
  }

  function toggleUserModal(data) {
    setUserData(data);
    setShowUserModal(!showModal);
  }

  function handleSave() {
    if (active === false && processName !== null && processName !== "") {
      saveProcess();
    }

    setActive(!active);
  }

  function loadComplains() {
    if (
      data.complains !== undefined &&
      data.complains !== "" &&
      data.complains !== null
    ) {
      return (
        <Dropdown>
          <Dropdown.Toggle variant={"light"} className="btn btn-sm btn-dual">
            <i className="fa fa-exclamation-circle text-danger" /> O cliente fez
            uma reclamação
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="form-group">
              <textarea className="form-control" value={data.complains} />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }

//ToDo apresentar o modal perguntando se devemos excluir os boletos
  async function deleteOrRecover() {
    if (processHelper.Status.Deleted === data.status) {
      if (window.confirm("Deseja realmente reativar esse processo?")) {
        const motive = window.prompt("Descreva o motivo da reativação:");
        if (motive === "" || motive === null) {
          toast.error(Message.PendingData);
          return;
        }
        await processCtrl.Recover(data.id, motive, (res) => {
          toast.success("Recuperado com sucesso");
          refresh();
        });
      }
    } else {
      if (window.confirm("Deseja realmente remover esse processo?")) {
        const motive = window.prompt("Descreva o motivo da exclusão:");
        if (motive === "" || motive === null) {
          toast.error(Message.PendingData);
          return;
        }
        await processCtrl.Delete(data.id, motive, (res) => {
          toast.success("Removido com sucesso");
          refresh();
        });
      }
    }
  }

  async function deleteProcess() {
    if (window.confirm("Deseja realmente remover esse processo?")) {
      if (
        data.motive === "" ||
        data.motive === null ||
        data.reimbursementType == undefined
      ) {
        toast.error(Message.PendingData);
        return;
      }
      await processCtrl.Delete(
        data.id,
        data.motive,
        data.reimbursementType,
        (res) => {
          toast.success("Removido com sucesso");
          refresh();
        },
      );
    }
  }

  function handleChange(evt, type) {
    setUpdateInfo(OnDataChange(updateInfo, evt, type));
  }

  async function notifyReturn() {
    if (
      updateInfo.channel === undefined ||
      updateInfo.observation === undefined
    ) {
      toast.error("Preencha os dados para salvar");
      return;
    }
    if (window.confirm("Realmente deseja prosseguir?")) {
      let item = organList;
      if (item.stage === 2) {
        item.statusAnalysis = organHelper.AnalysisStatus.Ongoing;
      }
      if (item.stage === 3) {
        item.statusPreparation = organHelper.AnalysisStatus.Ongoing;
      }
      if (item.stage === 4) {
        item.statusDevolution = organHelper.DevolutionStatus.Exigency;
      }

      await timelineCtrl.Insert(
        {
          organListId: item.id,
          processId: item.processId,
          userId: user.id,
          ...updateInfo,
        },
        async (res) => {
          await organCtrl.Update(item, (res) => {
            toast.success(Message.Saved);
            refresh();
          });
        },
      );
    }
  }

  function handleCheckChange(evt) {
    let base = {};
    if (evt.target.id == "Email") {
      base = { ...data, contact: ProcessHelper().ContactPreference.Email };
      setData(base);
    } else if (evt.target.id == "WhatsApp") {
      base = { ...data, contact: ProcessHelper().ContactPreference.WhatsApp };
      setData(base);
    } else if (evt.target.id == "Both") {
      base = { ...data, contact: ProcessHelper().ContactPreference.Both };
      setData(base);
    } else if (evt.target.id == "erro") {
      base = {
        ...data,
        reimbursementType: ProcessHelper().ReimbursementType.Erro,
      };
      setData(base);
    } else if (evt.target.id == "desistencia") {
      base = {
        ...data,
        reimbursementType: ProcessHelper().ReimbursementType.Desistencia,
      };
      setData(base);
    } else if (evt.target.id == "alteracao") {
      base = {
        ...data,
        reimbursementType: ProcessHelper().ReimbursementType.Alteracao,
      };
      setData(base);
    }

    ProcessController().Update(base, (res) => {
      if (refresh != undefined) {
        refresh();
      }
    });
  }

  function handleDeleteCheckChange(evt) {
    let base = {};
    if (evt.target.id == "erro") {
      base = {
        ...data,
        reimbursementType: ProcessHelper().ReimbursementType.Erro,
      };
      setData(base);
    } else if (evt.target.id == "desistencia") {
      base = {
        ...data,
        reimbursementType: ProcessHelper().ReimbursementType.Desistencia,
      };
      setData(base);
    } else if (evt.target.id == "alteracao") {
      base = {
        ...data,
        reimbursementType: ProcessHelper().ReimbursementType.Alteracao,
      };
      setData(base);
    }
  }

  return (
    <div className="block">
      {organList.statusAnalysis ===
        organHelper.AnalysisStatus.ReturnToCustomer ||
      organList.statusPreparation ===
        organHelper.AnalysisStatus.ReturnToCustomer ||
      organList.statusDevolution ===
        organHelper.DevolutionStatus.ReturnToCustomer ? (
        <DropdownElement
          submit={() => notifyReturn()}
          icon=""
          style={{ width: "100%", height: 250 }}
          title={"Retornado ao cliente"}
        >
          <div className="form-group">
            <span>Qual o canal de contato?</span>
            <select
              type="text"
              className="form-control"
              name="channel"
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option value={1}>Email</option>
              <option value={2}>Whatsapp</option>
              <option value={3}>Chat</option>
              <option value={4}>Telefone</option>
              <option value={5}>Recepção</option>
            </select>
          </div>
          <div className="form-group">
            <span>O que foi tratado?</span>
            <textarea
              type="text"
              className="form-control"
              name="observation"
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </DropdownElement>
      ) : (
        ""
      )}
      <div className="text-center">
        {data && data.id !== undefined ? loadComplains() : ""}
      </div>
      <div className="block block-fx-pop">
        <div className="block-header">
          <div className="block-title">Informações</div>
          <div className="block-tools d-flex">
            <a className="btn btn-sm btn-dual" href={consult && `#/confirm/${consult.id}`} target="_blank">Ver orçamento</a>
            {user && user.level !== 5 ? (
              <button
                className={`btn btn-sm btn-square btn-${
                  active === true ? "info" : "success"
                }`}
                disabled={disable}
                onClick={() => handleSave()}
              >
                {active === true ? "Editar" : "Salvar"}
              </button>
            ) : (
              ""
            )}
            {user && user.level !== 5 && consult && (
              <ProcessMirror process={consult && consult} />
            )}
            {user && user.level !== 5 && consult && (
              <DevolutionDoc process={consult && consult} />
            )}
            {user && user.level != 5 ? (
              <div>
                {data && data.status !== processHelper.Status.Deleted ? (
                  <DropdownElement
                    title="Excluir"
                    className="btn btn-sm btn-danger"
                    icon="si si-close mr-2"
                    style={{ width: 340 }}
                    submit={() => deleteProcess()}
                    button="Excluir"
                  >
                    <div className="form-group">
                      <label className="d-block">Tipo de exclusão:</label>
                      <div className="custom-control custom-radio custom-control-inline custom-control-lg">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="erro"
                          name="deleteType"
                          onChange={(evt) => handleDeleteCheckChange(evt)}
                          checked={
                            data &&
                            data.reimbursementType ==
                              ProcessHelper().ReimbursementType.Erro
                          }
                        />
                        <label className="custom-control-label" for="erro">
                          Erro
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline custom-control-lg">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="desistencia"
                          name="deleteType"
                          onChange={(evt) => handleDeleteCheckChange(evt)}
                          checked={
                            data &&
                            data.reimbursementType ==
                              ProcessHelper().ReimbursementType.Desistencia
                          }
                        />
                        <label
                          className="custom-control-label"
                          for="desistencia"
                        >
                          Desistência
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline custom-control-lg">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="alteracao"
                          name="deleteType"
                          onChange={(evt) => handleDeleteCheckChange(evt)}
                          checked={
                            data &&
                            data.reimbursementType ==
                              ProcessHelper().ReimbursementType.Alteracao
                          }
                        />
                        <label className="custom-control-label" for="alteracao">
                          Alteração de serviço
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <span>Qual o motivo da exclusão: Descrição interna</span>
                      <textarea
                        className="form-control"
                        onChange={(evt) =>
                          setData({ ...data, motive: evt.target.value })
                        }
                      />
                    </div>
                  </DropdownElement>
                ) : (
                  <button
                    className={`btn btn-sm btn-danger`}
                    onClick={() => deleteOrRecover()}
                  >
                    <i className="si si-check mr-2" />
                    Reativar processo
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
            {data && user && user.level != 5 ? (
              <FollowingProcessButton processId={data && data.id} />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="block-content">
          <div className="form-group">
            <span>Código de cliente</span>
            <input
              className="form-control"
              type="text"
              disabled={true}
              defaultValue={data.customer && data.customer.customerCode}
            />
          </div>
          <div className="form-group">
            <span>Processo</span>
            <input
              className="form-control"
              type="text"
              disabled={active}
              onChange={(evt) => setProcessName(evt.target.value)}
              defaultValue={data && data.processName}
            />
          </div>

          {(user &&
            user.level == 5 &&
            organList.status != OrganStatus.Deferred &&
            organList.status != OrganStatus.Done) ||
          user.level != 5 ? (
            <div className="form-group">
              <label className="d-block">
                Quero receber as notificações deste processo por:
              </label>
              <div className="custom-control custom-radio custom-control-inline custom-control-lg">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="Email"
                  name="example-rd-custom-inline-lg"
                  disabled={
                    data &&
                    (data.status == ProcessStatus.Done ||
                      data.status == ProcessStatus.Returned)
                      ? true
                      : false
                  }
                  onChange={(evt) => handleCheckChange(evt)}
                  checked={
                    data &&
                    data.contact == ProcessHelper().ContactPreference.Email
                  }
                />
                <label className="custom-control-label" for="Email">
                  Email
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline custom-control-lg">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="WhatsApp"
                  name="example-rd-custom-inline-lg"
                  disabled={
                    data &&
                    (data.status == ProcessStatus.Done ||
                      data.status == ProcessStatus.Returned)
                      ? true
                      : false
                  }
                  onChange={(evt) => handleCheckChange(evt)}
                  checked={
                    data &&
                    data.contact == ProcessHelper().ContactPreference.WhatsApp
                  }
                />
                <label className="custom-control-label" for="WhatsApp">
                  WhatsApp
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline custom-control-lg">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="Both"
                  name="example-rd-custom-inline-lg"
                  disabled={
                    data &&
                    (data.status == ProcessStatus.Done ||
                      data.status == ProcessStatus.Returned)
                      ? true
                      : false
                  }
                  onChange={(evt) => handleCheckChange(evt)}
                  checked={
                    data &&
                    data.contact == ProcessHelper().ContactPreference.Both
                  }
                />
                <label className="custom-control-label" for="Both">
                  Ambos
                </label>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="form-group text-center">
            {user && user.level !== 5 ? (
              <button
                className={`btn btn-square btn-${
                  data.urgent === true ? "info" : "danger"
                }`}
                onClick={() => registerUrgency()}
              >
                {data.urgent === false
                  ? "Solicitar urgência"
                  : "Remover urgência"}
              </button>
            ) : (
              ""
            )}
          </div>

          {user &&
          user.level == 5 &&
          organList.status != OrganStatus.Deferred &&
          organList.status != OrganStatus.Done ? (
            <div className="form-group">
              <span>Anote suas Observações para a equipe Hasa</span>
              <textarea
                className="form-control"
                type="text"
                disabled={disable}
                onChange={(evt) => setCustomerNote(evt.target.value)}
                defaultValue={data && data.customerNote}
              />
              <button
                className="btn btn-block btn-success"
                disabled={disable}
                onClick={() => registerObservation()}
              >
                Salvar Observação
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {user && user.level !== 5 ? (
        <CustomerCard
          customerId={data && data.customerId}
          preLoad={data.customer}
        />
      ) : (
        ""
      )}

      {user && user.level !== 5 ? (
        <ResponsibleCard
          data={data}
          toggleUserModal={(item) => toggleUserModal(item)}
          refresh={() => refresh()}
        />
      ) : (
        ""
      )}

      <div className="block block-fx-shadow">
        <div className="block-header">
          <div className="block-title">Serviços Contratados</div>
          <div className="block-tools">
            {(user && user.role === 1) ||
            user.level == 10 ||
            user.level == 1 ||
            user.level == 2 ? (
              <ServiceDropdown
                selectedServices={selectedServices}
                reload={() => reload()}
                processId={processId}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        {loading === true ? (
          <div className="block-content text-center">
            <i className={"fa fa-circle-notch fa-spin"}></i>
          </div>
        ) : (
          <div className="block-content">
            {selectedServices &&
              selectedServices.map((item) => (
                <ServiceCard
                  service={item}
                  disable={disable}
                  process={data}
                  organListId={organListId}
                  selectedServices={selectedServices}
                  preLoad={item}
                  reload={() => reload()}
                />
              ))}
          </div>
        )}
      </div>

      <div className="py-3 text-center">
        {data &&
        data.id !== undefined &&
        user &&
        user.level !== 5 &&
        user.level !== 6 ? (
          <ReavaliationCard
            process={data}
            preLoad={preLoad}
            organListId={organListId}
            disable={
              userData &&
              organList &&
              userData.level === 4 &&
              organList.stage === 4
                ? true
                : disable
            }
            show={(user) => {
              toggleUserModal(user);
            }}
          />
        ) : (
          ""
        )}
      </div>

      {/* <ProcessHistoryCard processId={processId} /> */}

      <UserModal
        data={userData}
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
      />
    </div>
  );
}
