import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import Storage from "@/app/core/util/Storage";
import { CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import React, { useEffect, useState } from "react";
import CompanyModal from "./components/CompanyModal";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import SituationOfCompanyModal from "./components/SituationOfCompanyModal";
import CustomerNewBudget from "@/app/core/layouts/main-components/budget/CustomerNewBudget";
import CompanyOrgansModal from "@/app/core/layouts/main-components/organs/CompanyOrgansModal";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import ResumeTaskCard from "../../customerProfile/main/components/ResumeTaskCard";
import BudgetCard from "../../customerProfile/main/components/BudgetCard";
import ReminderCard from "../../customerProfile/main/components/ReminderCard";
import ElaboratorCard from "../../customerProfile/main/components/ElaboratorCard";
import ReportCompaniesModal from "./components/ReportCompaniesModal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import CompaniesTutorialModal from "./components/CompaniesTutorialModal";

export default function CompaniesComponent(props) {
  const userData = Storage.getUserData();
  const [customer, setCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const [query, setQuery] = useState({ filter: "" });
  const [showModal, setShowModal] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showCompany, setShowCompany] = useState(false);
  const [showNewBudgetModal, setShowNewBudgetModal] = useState(false);
  const [showOrganModal, setShowOrganModal] = useState(false);
  const [company, setCompany] = useState({});
  const date = new Date();
  const [change, setChange] = useState(date.getTime());
  const [customerData, setCustomerData] = useState({});
  const timestamp = new Date().getTime();
  const [filter, setFilter] = useState({
    Nome: false,
    CNPJ: false,
    Ult: false,
    Situacao: false,
    desc: true,
  });

  const [report, setReport] = useState({
    total: 0,
    regular: 0,
    irregular: 0,
  });

  useEffect(() => {
    if (userData && userData.level != 5) {

      const params = new URLSearchParams(props.location.search);
      let baseCustomerId = params.get("customerId");
      setCustomerId(baseCustomerId);

      setCustomer(baseCustomerId);
      getCompanies(baseCustomerId);
      loadCustomer(baseCustomerId);
    } else {
      setCustomer(userData.customerId);
      getCompanies(userData.customerId);
      loadCustomer(userData.customerId);
    }
  }, []);


  function onFilter(newFilter) {
    newFilter.desc = !filter.desc;
    setFilter(newFilter);

    let newData = data;

    if (newFilter.Nome) {
      newData = newData.sort((a, b) => newFilter.desc ? b.id - a.id : a.id - b.id);
    }
    if (newFilter.CNPJ) {
      newData = newData.sort((a, b) => newFilter.desc ? b.doc - a.doc : a.doc - b.doc);
    }
    if (newFilter.Ult) {
      newData.sort((a, b) => newFilter.desc ? b.lastCheckedTime - a.lastCheckedTime : a.lastCheckedTime - b.lastCheckedTime);
    }
    if (newFilter.Situacao) {
      newData = newData.sort((a, b) => newFilter.desc ? b.status : a.status);
    }

    setData(newData);
    notifyChange();
  }

  function loadCustomer(customerId) {
    if (userData && userData.level == 5 && userData.customer) {
      setCustomerData(userData.customer);
    } else {
      CustomerSelect(customerId, (res) => {
        setCustomerData(res);
      });
    }
  }


  function notifyChange() {
    setChange(date.getTime());
  }

  function handleChange(evt) {
    setQuery(OnDataChange(query, evt));

    if (evt.target.value === "") {
      setData(raw)
    } else {
      setData(autocomplete(evt.target.value))
    }
  }

  function autocomplete(value) {
    const inputValue = value.trim().toLowerCase();

    return raw.filter(item => {
      const name = item.name?.trim().toLowerCase() || "";
      const cnpj = item.doc?.toLowerCase() || "";
      const phone = item.phone || "";
      const email = item.email || "";
      const responsible = item.responsible || "";

      return (
        name.includes(inputValue) ||
        phone.includes(inputValue) ||
        email.includes(inputValue) ||
        responsible.includes(inputValue) ||
        cnpj.includes(inputValue)
      );
    });
  }

  function getCompanies(id) {
    CompanyController.List(id, (res) => {
      for (let s in res) {
        res[s].checked = false;
        if (res[s].organs && res[s].organs.length > 0) {
          const regular = res[s].organs.filter(
            (x) => x.status == OrganHelper.CompanyOrganStatus.Regular,
          );
          const irregular = res[s].organs.filter(
            (x) => x.status == OrganHelper.CompanyOrganStatus.Irregular
              && x.docs && x.docs.length > 0
          );
          const notApply = res[s].organs.filter(
            (x) => x.status == OrganHelper.CompanyOrganStatus.NotApply,
          );

          if (irregular.length > 0) {
            res[s].isRegular = true;
          } else if (notApply.length == res[s].organs.length) {
            res[s].isRegular = undefined;
          } else {
            res[s].isRegular = false;
          }

          for (let y in res[s].organs) {
            res[s].organs[y].time = new Date(
              res[s].organs[y].updatedDate,
            ).getTime();
          }

          res[s].organs.sort(function (a, b) {
            return b.time - a.time;
          });

          res[s].lastChecked = DateTranslate(res[s].organs[0].updatedDate);
          res[s].lastCheckedTime = new Date(
            res[s].organs[0].updatedDate,
          ).getTime();

        }
        console.log("Resultado", res);
      }

      res.sort(function (a, b) {
        return b.lastCheckedTime - a.lastCheckedTime;
      });

      setReport({
        total: res.length,
        regular: res.filter((x) => x.isRegular == true).length,
        irregular: res.filter((x) => x.isRegular != true).length,
      });
      setData(res);
      setRaw(res);
    });
  }

  function deleteCompany(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      CompanyController.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        getCompanies(customer);
      });
    }
  }

  function openCompany(item) {
    setCompany(item);
    if (userData && userData.level == 5) {
      setShowCompany(true);
    } else {
      setShowOrganModal(true);
    }
  }


  function newService(item) {
    let invalids = item.organs;
    item.organDocs = [];
    if (invalids.length > 0) {
      item.observation = "Quero regularizar esta empresa no órgão:  \n";
      for (let s in invalids) {
        if (invalids[s].status === OrganHelper.CompanyOrganStatus.Irregular) {
          item.observation += `${invalids[s].name}; \n`;
          item.organDocs = [...item.organDocs, ...invalids[s].docs.filter(x => x.visible === true)];
        }
      }
    }

    item.id = "dlfkvmlkdf";

    setSelected(item);
    setShowNewBudgetModal(true);
  }


  return (
    <div className="content ">
      <div className="row mr-0 pr-0">
        <div className="col-md-10 mb-3">
          <h2 className="mb-0 ml-3 mt-2">
            <strong className="font-w700">Consulta de Regularidade Cadastral</strong>
            <br />
          </h2>
          <p className="text-muted ml-3 mb-1 mt-2">
            Adicione o CNPJ do seu cliente e tenha acesso a um relatório com todos os órgãos regulares ou irregulares da empresa automaticamente!
            <br />Esta funcionalidade tem como objetivo garantir que todos as empresas que você cuida estejam em plena conformidade com as obrigações e cadastros exigidos pelos órgãos públicos, de acordo com suas atividades.
          </p>
        </div>
        <div className="col-md-2 mb-3 text-right">
          <button className="btn btn-info" onClick={() => setShowTutorialModal(true)}>
            <i className="si si-question" /> Tutorial Completo
          </button>
        </div>
        <div className="col-md-4 ml-0 mr-0">
          <div className="block block-rounded block-link-pop block-bordered border-2x">
            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
              <div>
                <i className="far fa-2x fa-user-circle text-success"></i>
              </div>
              <div className="ml-3 text-right">
                <p className="font-size-h3 font-w300 mb-0">
                  {report && report.total}
                </p>
                <p className="text-muted mb-0">Empresas cadastradas</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 ml-0 mr-0">
          <div className="block block-rounded block-link-pop block-bordered border-2x cursor" onClick={() => setSelectedCompanies(data.filter(x => x.isRegular === true)) | setShowReport(true)}>
            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
              <div>
                <i className="far fa-2x fa-check-circle text-info"></i>
              </div>
              <div className="ml-3 text-right">
                <p className="font-size-h3 font-w300 mb-0">
                  {report && report.regular}
                </p>
                <p className="text-muted mb-0">Empresas regulares</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 ml-0 mr-0">
          <div className="block block-rounded block-link-pop block-bordered border-2x cursor" onClick={() => setSelectedCompanies(data.filter(x => x.isRegular === false)) | setShowReport(true)}>
            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
              <div>
                <i className="si fa-2x si-user-unfollow text-danger"></i>
              </div>
              <div className="ml-3 text-right">
                <p className="font-size-h3 font-w300 mb-0">
                  {report && report.irregular}
                </p>
                <p className="text-muted mb-0">Empresas irregulares</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="block block-bordered border-2x">
            <div className="block-header">
              <div className="block-title">Empresas</div>
              <div className="block-tools">
                {/* <button className="btn btn-outline-success" onClick={() => requestConsultation()}>({change && data && data.filter(x => x.checked == true).length}) Solicitar consulta</button> */}
                {userData && userData.level == 5 ? (
                  <button
                    className="btn btn-outline-info"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="si si-plus" /> Adicionar empresa
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="block-content">

              <div className="input-group input-group-lg">
                <input type="text" className="js-icon-search form-control" name="filter" value={query.filter} placeholder="Filtrar por" onChange={evt => handleChange(evt)} />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fa fa-search"></i>
                  </span>
                </div>
              </div>

              <table className="table table-hover table-borderless">
                <thead>
                  <tr>
                    <th className="cursor" onClick={() => onFilter({ Nome: true, desc: !filter.Nome })}>
                      {change && filter && filter.Nome === true && filter.desc === true ?
                        <i className="si si-arrow-down" />
                        : filter.Nome === true && filter.desc === false ? <i className="si si-arrow-up" /> : ""
                      }
                      Nome
                    </th>
                    <th className="cursor" onClick={() => onFilter({ CNPJ: true, desc: !filter.CNPJ })}>
                      {change && filter && filter.CNPJ === true && filter.desc === true ?
                        <i className="si si-arrow-down" />
                        : filter.CNPJ === true && filter.desc === false ? <i className="si si-arrow-up" /> : ""
                      }
                      CNPJ
                    </th>
                    <th className="cursor" onClick={() => onFilter({ Ult: true, desc: !filter.Ult })}>
                      {change && filter && filter.Ult === true && filter.desc === true ?
                        <i className="si si-arrow-down" />
                        : filter.Ult === true && filter.desc === false ? <i className="si si-arrow-up" /> : ""
                      }
                      Ult. consulta
                    </th>
                    <th className="cursor" onClick={() => onFilter({ Situacao: true, desc: !filter.Situacao })}>
                      {/* {change && filter && filter.Situacao === true && filter.desc === true ?
                        <i className="si si-arrow-down" />
                        : filter.Situacao === true && filter.desc === false ? <i className="si si-arrow-up" /> : ""
                      } */}
                      Situacao
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {change &&
                    data &&
                    data.map((item, index) => (
                      <tr>
                        <td title={item.id}>
                          {item.name}
                        </td>
                        <td>
                          {item.doc.length == 11
                            ? CpfMask(item.doc)
                            : CnpjMask(item.doc)}
                        </td>
                        <td>{item.lastChecked}</td>
                        <td>
                          {item.status ==
                            CustomerHelper().CompanyStatus.NotConsulted ? (
                            <span className="badger badge-seccondary mr-2">
                              Não consultado
                            </span>
                          ) : item.status ==
                            CustomerHelper().CompanyStatus.Consulting ? (
                            <span className="badge badge-warnin mr-2g">
                              Consultando
                            </span>
                          ) : item.isRegular == true ? (
                            <span className="badge badge-success mr-2">Regular</span>
                          ) : (
                            <span className="badge badge-danger mr-2">
                              Irregular
                            </span>
                          )}
                          {item && `(${item.organs && item.organs.filter(x => x.status === OrganHelper.CompanyOrganStatus.Irregular || x.status === OrganHelper.CompanyOrganStatus.Regular).length} de ${item.organs && item.organs.filter(x => x.status !== OrganHelper.CompanyOrganStatus.NotApply).length}) Órgãos consultados`}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-hero-sm btn-hero-success"
                            onClick={() => newService(item)}
                          >
                            Novo Pedido
                          </button>
                        </td>
                        <td>
                          <span
                            className="text-primary-lighter shaved-btn cursor-pointer  ml-2 mr-2"
                            title="Editar"
                            onClick={() =>
                              setSelected(item) | setShowModal(true)
                            }
                          >
                            <i className="fa fa-fw fa-pencil-alt" />
                          </span>
                          {item && item.organs && item.organs.length > 0 ? (
                            <span
                              className="text-primary-lighter shaved-btn cursor-pointer ml-2 mr-2"
                              title="Visualizar"
                              onClick={() => openCompany(item)}
                            >
                              <i className="fas fa-eye" />
                            </span>
                          ) : (
                            ""
                          )}
                          <span
                            className="text-primary-lighter shaved-btn cursor-pointer  ml-2 mr-2"
                            title="Excluir"
                            onClick={() => deleteCompany(item)}
                          >
                            <i className="fas fa-times" />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {data && data.length === 0 ?
                <div className="text-center">
                  <h3 className="text-muted">Você ainda não possui empresas cadastradas</h3><br />
                  <button className="btn btn-info mb-5" onClick={() => setShowModal(true)}><i className="si si-plus" /> Clique aqui para adicionar</button>
                </div>
                : ""}
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <ResumeTaskCard customerId={customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Tasks, customerData)} />
        </div>
        <div className="col-md-3">
          <BudgetCard customerId={customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Tasks, customerData)} />
        </div>
        <div className="col-md-3">
          <ElaboratorCard customerId={customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Tasks, customerData)} />
        </div>
        <div className="col-md-3">
          <ReminderCard customerId={customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Tasks, customerData)} />
        </div>

      </div>

      <CompanyModal
        show={showModal}
        infos={selected}
        onHide={() =>
          setShowModal(false) | getCompanies(customer) | setSelected({})
        }
      />

      <SituationOfCompanyModal
        show={showCompany}
        infos={company}
        onHide={() =>
          setShowCompany(false) | getCompanies(customer) | setCompany({})
        }
      />

      <CustomerNewBudget
        show={showNewBudgetModal}
        infos={selected}
        customerId={userData && userData.customerId}
        onHide={() => setShowNewBudgetModal(false) | setSelected({})}
      />

      <CompanyOrgansModal
        show={showOrganModal}
        onHide={() =>
          setShowOrganModal(false) | getCompanies(customer) | setSelected({})
        }
        companyId={company && company.id}
      />

      <CompaniesTutorialModal
        show={showTutorialModal}
        onHide={() =>
          setShowTutorialModal(false)
        }
      />

      <ReportCompaniesModal
        show={showReport}
        onHide={() =>
          setShowReport(false) | getCompanies(customer) | setSelected({})
        }
        infos={selectedCompanies}
        newService={item => newService(item)}
        setSelected={item => setSelected(item)}
        setShowModal={item => setShowModal(item)}
        openCompany={item => openCompany(item)}
        deleteCompany={item => deleteCompany(item)}
      />
    </div>
  );
}
