import React, { useEffect, useState } from "react";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { ArrayMes, DateTimeNow, DateTranslate, DateTranslateToInput, ManualTranslater, OnlyDateTranslateToInput } from "@/app/core/util/DateTranslater";
import BalanceImportController from "@/app/core/base/controllers/BalanceImportController";
import BalanceEntryModal from "./BalanceEntryModal";
import { Message } from "@/app/core/util/messages/GeralMessages";
// import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import BalanceEntryController from "@/app/core/base/controllers/BalanceEntryController";
import Table from "@/app/core/components/table/";
import { ImportationsCols } from "@/app/core/layouts/main-components/customer/CustomerPendingCols";
import Functions from "@/app/core/util/Functions";

export default function Importations({ }) {
    const [raw, setRaw] = useState([]);
    const [filter, setFilter] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState({});
    const [query, setQuery] = useState("");

    useEffect(() => {
        loadBillOfExchange()
    }, [])

    async function loadBillOfExchange() {
        await BalanceImportController().List(res => {
            for (let s in res) {
                for (let i in res[s].entries) {
                    res[s].entries[i].dueDate = OnlyDateTranslateToInput(res[s].entries[i].dueDate);
                    res[s].entries[i].createdDate = DateTranslate(res[s].entries[i].createdDate);
                    res[s].entries[i].value = res[s].entries[i].value.toLocaleString();
                    res[s].entries[i].status = res[s].entries[i].status + "";
                    res[s].entries[i].customerName = res[s].entries[i].customer ? res[s].entries[i].customer.name : "S.I.";
                }
            }
            setFilter(res);
        });
    }

    function editEntry(entry) {
        setSelected(entry);
        setShowModal(true);
    }

    function removeEntry(entry) {
        if (window.confirm(Message.DeleteConfirm)) {
            BalanceEntryController().Delete(entry.id, res => {
                loadBillOfExchange();
            });
        }
    }

    function deleteImportation(item) {
        if (window.confirm(Message.DeleteConfirm)) {
            BalanceImportController().Delete(item.id, res => {
                loadBillOfExchange();
            });
        }
    }

    function processFiles(e) {
        e.preventDefault();
        // if (e.target.files && e.target.files.length > 0) {
        //     const reader = new FileReader();
        //     reader.onload = (e) => {
        //         const data = e.target.result;
        //         const workbook = XLSX.read(data, { type: "array" });
        //         const sheetName = workbook.SheetNames[0];
        //         const worksheet = workbook.Sheets[sheetName];
        //         const json = XLSX.utils.sheet_to_json(worksheet, {
        //             raw: false,
        //             dateNF: "dd/mm/yyyy"
        //         });
        //         console.log("Excel", json);

        //         processData(json)
        //     };

        //     reader.readAsArrayBuffer(e.target.files[0]);
        // }

    }

    function processData(json) {
        console.log("Excel", json);
        let base = {
            entries: [],
            description: `Importação-${DateTimeNow()}`,
            DueDate: DateTimeNow(),
        }
        for (let s in json) {
            if (!json[s].CustomerCode) {
                toast.error(`Código de cliente inválido na linha ${s + 1}`);
                return;
            } else if (!json[s].Description) {
                toast.error(`Descrição inválida na linha ${s + 1}`);
                return;
            } else if (parseFloat(json[s].Value) == NaN) {
                toast.error(`Valor inválido na linha ${s + 1}`);
                return;
            } else if (!json[s].DueDate) {
                toast.error(`Data inválida na linha ${s + 1}`);
                return;
            } else {
                console.log("Importacao --", json[s].DueDate, ManualTranslater(json[s].DueDate));
                json[s].DueDate = ManualTranslater(json[s].DueDate);
                json[s].Value = parseFloat(json[s].Value);
                base.entries.push(json[s]);
            }
        }


        if (base.entries.length > 0) {
            BalanceImportController().Insert(base, res => {
                toast.success(Message.Saved);
                loadBillOfExchange()
            });
        } else {
            toast.error(Message.PendingData);
        }
        console.log("Importação", base);
    }

    function openCustomer(entry) {
        if (entry.data.customer) {
            window.open(`#/customer-profile/${entry.data.customerId}`)
        } else {
            toast.info("Esse cliente ainda não foi registrado na plataforma")
        }
    }

    function run() {
        BalanceEntryController().Run(res => {
            loadBillOfExchange();
        });
    }

    return (
        <div>
            <div className="block-header mt-5">
                <div className="block-title">Importação de saldo anterior</div>
                <div className="block-tools">
                    <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

                        onChange={processFiles}
                    />
                </div>
            </div>
            {filter && filter.map(item => (
                <div className="block block-bordered block-rounded">
                    <div className="block-header">
                        <div className="block-title">{item.description} - {DateTranslate(item.dueDate)}</div>
                        <div className="block-tools">
                            <button className="btn btn-dual" onClick={() => deleteImportation(item)}><i className="si si-close text-danger"></i> Excluir importação</button>
                            <button className="btn btn-dual" onClick={() => run()}><i className="si si-check"></i> Sincronizar lançamentos</button>
                            <button className="btn btn-dual" onClick={() => loadBillOfExchange()}><i className="si si-refresh"></i> Atualizar</button>
                        </div>
                    </div>
                    <div className="block-content">
                        <Table
                            title={item.entries && item.entries.length}
                            loading={false}
                            tableId="importations"
                            data={item.entries}
                            columns={ImportationsCols}
                            hideHeader={true}
                            refreshData={() => loadBillOfExchange()}
                            setFilter={(filtered) => Functions.ProcessFilter(filtered, item.entries)}
                            editAction={data => editEntry(data.data)}
                            deleteAction={data => removeEntry(data.data)}
                            viewAction={
                                (selected) => openCustomer(selected)
                            }
                        />
                        {/* <table className="table table-hover">
                            <thead>
                                <th>Código</th>
                                <th>Nome</th>
                                <th>Saldo anterior</th>
                                <th>Data da importação</th>
                                <th>Status</th>
                            </thead>
                            <tbody>
                                {item.entries && item.entries.map(entry => (
                                    <tr>
                                        <td>{entry.customerCode}</td>
                                        <td>{entry.customer && entry.customer.name}</td>
                                        <td>{(entry.value).toLocaleString()}</td>
                                        <td>{DateTranslate(entry.createdDate)}</td>
                                        <td>{item.status == true ? <span className="text-success">Sincronizado</span> : <span className="text-danger">Sincronização pendente</span>}</td>
                                        <td>
                                            <button className="btn btn-dual" onClick={() => editEntry(entry)}><i className="far fa-edit"></i></button>
                                            <button className="btn btn-dual" onClick={() => removeEntry(entry)}><i className="si si-close text-danger"></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                </div>
            ))}

            <BalanceEntryModal
                show={showModal}
                onHide={() => setShowModal(false) | loadBillOfExchange()}
                selected={selected}
            />
        </div>
    )
}