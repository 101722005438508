import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import { CustomerPaymentPending } from "@/app/core/base/controllers/CustomerController";
import { CustomerPendingCols } from "@/app/core/layouts/main-components/customer/CustomerPendingCols";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import CloseBillingModal from "./CloseBillingModal";
// import ReactToExcel from "react-html-table-to-excel";

export default function PendingCustomers({ refreshData, startDate, endDate }) {
    const [raw, setRaw] = useState([]);
    const [filter, setFilter] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [query, setQuery] = useState("");
    const chargeController = ServiceChargeController();
    const customerHelper = CustomerHelper();

    useEffect(() => {
        loadPendingCustomers()
    }, [refreshData, startDate, endDate])

    async function loadPendingCustomers() {
        await CustomerPaymentPending(startDate, `${endDate}T23:59:59`, res => {
            for (let s in res) {
                res[s].prices = processValue(res[s]);

                res[s].balance = res[s].balance.toLocaleString();
                res[s].value = (res[s].result).toLocaleString();
                
                res[s].discounts = (res[s].prices.discounts).toLocaleString();
                console.log("Resultados", res[s].result, res[s].prices);

                res[s].reimbursement = (res[s].prices.reimbursement).toLocaleString();

                res[s].charges = (res[s].prices.charges).toLocaleString();
                res[s].credit = (res[s].credit).toLocaleString();
                res[s].debit = (res[s].debit).toLocaleString();

                res[s].createdDate = DateTranslate(res[s].createdDate);
                res[s].updatedDate = DateTranslate(res[s].updatedDate);
            }
            setFilter(res);
            setRaw(res);
            console.log("Clientes", res);
        })
    }


    function handleChange(value) {
        setQuery(value);
        if (value.length > 0) {
            setFilter([]);
            setFilter(autocomplete(value));
        } else {
            setFilter(raw);
        }
    }

    function autocomplete(value) {
        const inputValue = value.trim().toLowerCase();
        let itens = raw;
        let newResults = [];

        for (let s in itens) {
            const item = itens[s];
            const name =
                item.name !== undefined ? item.name.trim().toLowerCase() : "";
            const customerCode =
                item.customerCode !== undefined ? item.customerCode : "";
            const cpf = item.cpf !== undefined ? item.cpf.toLowerCase() : "";
            const cnpj = item.cnpj !== undefined ? item.cnpj.toLowerCase() : "";
            const phone = item.phone !== undefined ? item.phone : "";
            const email = item.email !== undefined ? item.email : "";
            const status =
                item.status !== undefined
                    ? customerHelper.StatusTranslate(item.status).toLowerCase()
                    : "";
            const createdDate = item.createdDate;
            console.log(customerCode, value);
            if (
                name.includes(inputValue) ||
                customerCode.includes(value) ||
                cpf.includes(inputValue) ||
                phone.includes(inputValue) ||
                email.includes(inputValue) ||
                status.includes(inputValue) ||
                createdDate.includes(inputValue) ||
                cnpj.includes(inputValue)
            ) {
                newResults.push(item);
            }
        }

        return newResults;
    }

    function processValue(customer) {
        let price = 0;
        let discounts = 0;
        let reimbursement = 0;
        let charges = 0;
        let outcome = 0;

        for (let s in customer.services) {
            const item = customer.services[s];
            price += item.price;
        }

        for (let s in customer.charges) {
            const item = customer.charges[s];
            price += item.price;
            charges += item.price;
        }


        for (let s in customer.entries) {
            const res = customer.entries[s];
            if (res.type === FinancialHelper().OperationType.Income) {
                price += res.value;
            } else if (res.type === FinancialHelper().OperationType.Discount) {
                discounts += res.value;
            } else if (res.type === FinancialHelper().OperationType.Reimbursement) {
                reimbursement += res.value;
            } else if (res.type === FinancialHelper().OperationType.Outcome) {
                outcome -= res.value;
            }
        }

        return { price, discounts, reimbursement, charges, outcome };
    }

    return (
        <div>
            <div className="block-header mt-4">
                <div className="block-title">
                    {filter && filter.length + " Clientes com saldo em aberto/pendente"}
                </div>
                <div className="block-tools">
                    <button className="btn btn-hero-warning" onClick={() => setShowModal(true)}>
                        <i className="si si-check mr-1" /> Fazer fechamento do período
                    </button>
                    {/* <ReactToExcel
                        className="btn btn-info"
                        table="pending"
                        filename={`Relatorio - Clientes pendentes - periodo: ${startDate} - ${endDate}`}
                        sheet="sheet 1"
                        buttonText="Exportar"
                    /> */}
                </div>
            </div>
            <div className="col-md-12 py-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(evt) => handleChange(evt.target.value)}
                />
            </div>
            <Table
                title={filter && filter.length + " Clientes"}
                loading={false}
                tableId="pending"
                data={filter}
                columns={CustomerPendingCols}
                hideHeader={true}
                refreshData={() => loadPendingCustomers()}
                setFilter={(filtered) => Functions.ProcessFilter(filtered, filter)}
                viewAction={
                    (selected) =>
                        window.open(`#/customer-profile/${selected.data.id}`, "_blank")
                }
            />
            <CloseBillingModal
                show={showModal}
                onHide={()=> setShowModal(false)}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    )
}