import React from "react";
const cols = [
  { header: "Cód.", isSortable: true, name: "customerCode" },
  { header: "Nome", isSortable: true, name: "name" },
  { header: "Email", isSortable: true, name: "email" },
  { header: "CPF / CNPJ", isSortable: true, name: "doc" },
  {
    header: "Tipo",
    isSortable: true,
    name: "type",
    switch: {
      0: "S.I.",
      1: "Pessoa Física",
      2: "Pessoa Jurídica",
    },
  },
  {
    header: "Status",
    isSortable: true,
    name: "status",
    switch: {
      0: <span class="badge badge-secondary">Indefinido</span>,
      1: <span class="badge badge-info">Pendente</span>,
      2: <span class="badge badge-warning">Pendente Hasa</span>,
      3: <span class="badge badge-success">Ativo</span>,
      4: <span class="badge badge-danger">Bloqueado (Financeiro)</span>,
      5: <span class="badge badge-success">Ativo (Política Bloqueada)</span>,
      6: <span class="badge badge-danger">Bloqueado (Relacionamento)</span>,
    },
  },
  {
    header: "Nível",
    isSortable: true,
    name: "level",
    switch: {
      0: <div></div>,
      1: (
        <span>
          <i
            title="VIP"
            className={"fa fa-handshake fa-2x text-info mr-1 ml-1 "}
          />{" "}
          VIP{" "}
        </span>
      ),
      2: (
        <span>
          <i
            title="Exclusive"
            className={"si si-rocket fa-2x text-danger mr-1 ml-1 "}
          />{" "}
          Exclusive
        </span>
      ),
      4: (
        <span>
          <i
            title="Basic"
            className={"fa fa-user-tie fa-2x text-danger mr-1 ml-1 "}
          />{" "}
          Basic
        </span>
      ),
      3: (
        <span>
          <i
            title="Elite"
            className={"si si-diamond fa-2x text-danger mr-1 ml-1 "}
          />{" "}
          Elite
        </span>
      ),
    },
  },
  { header: "Telefone", isSortable: true, name: "phone" },
  { header: "Celular", isSortable: true, name: "whatsapp" },
  { header: "Atualizado em", isSortable: true, name: "updatedDate" },
  { header: "Criado em", isSortable: true, name: "createdDate" },
];

const report = [
  { header: "Cód.", isSortable: true, name: "customerCode" },
  { header: "Nome", isSortable: true, name: "name" },
  { header: "Email", isSortable: true, name: "email" },
  { header: "CPF / CNPJ", isSortable: true, name: "doc" },
  { header: "Telefone", isSortable: true, name: "phone" },
  {
    header: "Nível",
    isSortable: true,
    name: "level",
    switch: {
      0: <div></div>,
      1: (
        <span>
          <i title="VIP" className={"si si-rocket text-info mr-1 ml-1 "} /> VIP{" "}
        </span>
      ),
      2: (
        <span>
          <i
            title="Exclusive"
            className={"si si-diamond text-danger mr-1 ml-1 "}
          />{" "}
          Exclusive
        </span>
      ),
    },
  },
  { header: "Última compra", isSortable: true, name: "updatedDate" },
  { header: "Último serviço", isSortable: true, name: "observation" },
  // { header: "Criado em", isSortable: true, name: "createdDate" },
];
export { cols };
