import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import { SupportController } from "@/app/core/base/controllers/SupportController";

export default function FinancialHelpModal({
show,
onHide,
submit,
isLoading,
customerId
}) {
const [data, setData] = useState({});
const userData = Storage.getUserData();

useEffect(() => {
    loadData();
}, [show]);

async function loadData() {
    let customerId = "";
    if (userData !== null && userData.level === 5) {
        await CustomerSelect(
            userData.customerId,
            (res) => (customerId = res.customerCode)
        );
    } else if (customerId) {
        await CustomerSelect(
            customerId,
            (res) => (customerId = res.customerCode)
        );
    }

    setData({
        name: `${userData !== null ? userData.firstName : ""} ${userData !== null ? userData.lastName : ""
            }`,
        email: userData !== null ? userData.email : "",
        phone: userData !== null ? userData.phone : "",
        customerCode: customerId,
    });
}

async function save() {
    if (
        data.name !== undefined &&
        data.name !== "" &&
        data.email !== undefined &&
        data.email !== "" &&
        data.phone !== undefined &&
        data.phone !== "" &&
        data.description !== undefined &&
        data.description !== ""
    ) {
        SupportController.Send(data, (res) => {

            setTimeout(() => {
                toast.success(
                    "Sua solicitação de ajuda foi enviada com sucesso, em breve retornaremos"
                );
                onHide();
            }, 500);
        });
    } else {
        toast.error(Message.PendingData);
    }
}

function handleChange(evt) {
    setData(OnDataChange(data, evt));
}

return (
    <Modal
        title="Ajuda"
        show={show}
        isLoading={isLoading}
        onHide={onHide}
        onSave={submit}
        onSubmit={() => save()}
    >
        <div className="form-group">
            <span>Nome</span>
            <input
                className="form-control"
                name="name"
                value={data.name}
                onChange={(evt) => handleChange(evt)}
            />
        </div>
        <div className="form-group">
            <span>E-mail</span>
            <input
                className="form-control"
                name="email"
                value={data.email}
                onChange={(evt) => handleChange(evt)}
            />
        </div>
        <div className="form-group">
            <span>Cód. de cliente</span>
            <input
                className="form-control"
                name="customerCode"
                value={data.customerCode}
                onChange={(evt) => handleChange(evt)}
            />
        </div>
        <div className="form-group">
            <span>Telefone para contato</span>
            <input
                className="form-control"
                name="phone"
                value={data.phone}
                onChange={(evt) => handleChange(evt)}
            />
        </div>
        <div className="form-group">
            <span>Descreva o motivo do contato</span>
            <textarea
                className="form-control"
                name="description"
                value={data.description}
                onChange={(evt) => handleChange(evt)}
            />
        </div>
    </Modal>
);
}
