import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import Storage from "@/app/core/util/Storage";
import ConfirmService from "@/app/modules/confirm-service";
import { Panel, Tab, Tabs } from "@bumaga/tabs";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import CustomerBudgetModal from "./CustomerBudgetModal";
import BudgetsCard from "../BudgetsCard";

export default function BudgetListModal(
  { show, onHide, process, customerId, reload },
  props,
) {
  const userData = Storage.getUserData();
  const [loading, setLoading] = useState(false);
  const [showBudget, setShowBudget] = useState(false);
  const [processId, setProcessId] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(process);
  }, [show, process]);

  function acceptBudget(item, index) {
    if (window.confirm(Message.Confirm)) {
      ProcessController().BudgetAccepted(item.id, false, (res) => {
        toast.success(Message.Saved);
        let old = data;
        delete old[index];
        setData(old);
      });
    }
  }

  function openBudget(item) {
    setProcessId(item.id);
    setShowBudget(true);
  }

  function loadData() {
    reload();
  }

  return (
    <CustomModal
      title="Orçamentos"
      show={show}
      isLoading={loading}
      onHide={onHide}
    >
      {data && data.length == 0 ? (
        <div className="text-center">
          <i className="si si-check fa-2x text-success" />
          <br />
          <h3 className="text-muted">Você não tem orçamentos pendentes</h3>
        </div>
      ) : (
        <div>
          <BudgetsCard
            getCustomerSO={() => loadData()}
            budgets={process}
            refusedBudgets={[]}
            customerId={customerId}
          />
        </div>
      )}

      <CustomerBudgetModal
        processId={processId && processId}
        show={showBudget}
        onHide={() => setShowBudget(false) | setProcessId(null)}
      />
    </CustomModal>
  );
}
