import React, { useEffect, useState, useRef } from "react";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { CustomerController, CustomerList } from "@/app/core/base/controllers/CustomerController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { Typeahead } from "react-bootstrap-typeahead";

export default function Blockeds({ startDate, endDate }) {
    const [filter, setFilter] = useState([]);
    const [raw, setRaw] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [query, setQuery] = useState("");
    const tableRef = useRef(null);
    const [question, setQuestion] = useState("");

    useEffect(() => {
        loadCustomers()
    }, [])

    async function loadCustomers() {
        setLoading(true);
        await CustomerList(res => {
            for (let s of res) {
                s.query = `${s.customerCode} - ${s.name}`
            }
            setCustomers(res)
        });
        await CustomerController.ListBlockeds(res => {
            setFilter(res)
            setRaw(res)
        });
        setLoading(false);
    }

    useEffect(() => {
        search();
    }, [question]);

    function search() {
        if (question.length == 0) {
            setFilter(raw);
        } else {
            const results = autocomplete();
            setFilter(results);
        }
    }

    function autocomplete() {
        let newResults = [];
        const itens = raw;
        const value = question;
        for (let s in itens) {
            const item = itens[s];

            const customerCode = item.customerCode?.toLowerCase();
            const name = item.name?.toLowerCase();
            const email = item.email?.toLowerCase();
            const phone = item.phone?.toLowerCase();
            const whatsapp = item.whatsapp?.toLowerCase();
            const lastTimeline = item?.lastTimelineString?.toLowerCase();

            if (
                (customerCode && customerCode.includes(value.toLowerCase())) ||
                (name && name.includes(value.toLowerCase())) ||
                (email && email.includes(value.toLowerCase())) ||
                (phone && phone.includes(value.toLowerCase())) ||
                (lastTimeline && lastTimeline.includes(value.toLowerCase())) ||
                (whatsapp && whatsapp.includes(value.toLowerCase()))
            ) {
                newResults.push(item);
            }
        }
        return newResults;
    }


    function openCustomer(entry) {
        window.open(`#/customer-profile/${entry.id}`)
    }

    function restoreCustomer(item) {
        if (window.confirm(Message.Confirm)) {
            CustomerController.RemoveBlock(item.id, res => {
                toast.success(Message.Saved);
                loadCustomers();
            })
        }
    }

    function registerCustomer() {
        if (window.confirm(Message.Confirm)) {
            CustomerController.RegisterBlock(data.customerId, res => {
                toast.success(Message.Saved);
                loadCustomers();
            })
        }
    }

    function handleChange(evt) {
        setData(OnDataChange(data, evt));
    }

    return (
        <div>
            <div className="block-header mt-5">
                <div className="block-title">Clientes bloqueados</div>
                <div className="block-tools d-flex">
                    <DownloadTableExcel
                        filename="users table"
                        sheet={startDate && `Bloqueados - ${DateTranslate(startDate)} até ${DateTranslate(endDate)}`}
                        currentTableRef={tableRef.current}
                    >
                        <button className="btn btn-info"><i className="fa fa-download" /> Exportar Excel </button>
                    </DownloadTableExcel>
                    <DropdownElement
                        icon={"fa fa-stop-circle"}
                        title={"Bloquear cliente"}
                        submit={() => registerCustomer()}
                        style={{ width: 350 }}
                    >
                        <div class="form-group">
                            <label for="example-masked-time">Cliente</label>
                            <Typeahead
                                options={customers}
                                labelKey="query"
                                onSearch={(q) => setQuery(q)}
                                id="advanced-filter-state"
                                name="clientName"
                                placeholder="Selecionar Cliente"
                                value={data.customerId}
                                onChange={(selected) => {
                                    handleChange({
                                        target: {
                                            value: selected[0] ? selected[0].id : "",
                                            name: "customerId",
                                        },
                                    });
                                }}
                            />
                        </div>
                    </DropdownElement>
                </div>
            </div>
            {loading === true ? (
                <div className="block-content text-center">
                    <i className={"fa fa-circle-notch text-info fa-2x fa-spin"}></i>
                </div>
            ) : ""}

            <div className="form-group">
                <div className="input-group">
                    <input
                        type="text"
                        placeholder={`Pesquisar...`}
                        className="form-control form-control-alt"
                        value={question}
                        onChange={(evt) => setQuestion(evt.target.value)}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text input-group-text-alt">
                            <i className="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <table className="table table-hover table-striped" ref={tableRef}>
                <thead>
                    <tr>
                        <td>Id</td>
                        <td>Código de Cliente</td>
                        <td>Nome do Cliente</td>
                        <td>Email</td>
                        <td>Telefone</td>
                        <td>Valor devido</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {filter.map((item) => (
                        <tr>
                            <td>{item.id}</td>
                            <td>{item && item.customerCode}</td>
                            <td>{item && item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td className="text-danger">{MoneyMask(item.balance)}</td>
                            <td>
                                <button
                                    className="btn btn-sm btn-square btn-white m-1"
                                    onClick={() => openCustomer(item)}
                                >
                                    <i className="far fa-eye" /> Ver cliente
                                </button>
                                <button
                                    className="btn btn-sm btn-square btn-success m-1"
                                    onClick={() => restoreCustomer(item)}
                                >
                                    <i className="si si-check" /> Remover da lista
                                </button>
                            </td>
                        </tr>
                    )
                    )}
                </tbody>
            </table>
        </div>
    )
}