export function DateToPast(date) {
  let dta = Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60);

  if (dta < 60) {
    return `${Math.floor(dta)} minutos atrás`;
  } else {
    dta = dta / 60;

    if (Math.floor(dta) < 24) {
      return `${Math.floor(dta)} horas atrás`;
    } else {
      dta = dta / 24;
      return `${Math.floor(dta)} dias atrás`;
    }
  }
}

export function AddOnlyDate(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");


  const formatted = `${year}-${month}-${day}`;
  return formatted;
}
export function ManualTranslater(dte) {
  const date = dte.split("/");
  const day = date[0];// < 10 ? `0${date[0]}` : date[0];
  const month = date[1];// < 10 ? `0${date[1]}` : date[1];
  const year = date[2];


  const formatted = `${year}-${month}-${day}`;
  return formatted;
}

export function YearsAgo(date) {
  const dataSelecionada = new Date(date);
  const hoje = new Date();

  let anos = hoje.getFullYear() - dataSelecionada.getFullYear();
  const mes = hoje.getMonth() - dataSelecionada.getMonth();
  const dia = hoje.getDate() - dataSelecionada.getDate();

  // Verifica se o mês ou dia já passou para ajustar a idade
  if (mes < 0 || (mes === 0 && dia < 0)) {
      anos--;
  }

  return anos;
}

export function DateIsNear(date) {
  let dta = Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60);
  return Math.floor(dta);
}

export function IsTodayDate(date) {
  let dta = Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60);

  if (dta < 60) {
    // return Math.floor(dta); //minutos
    return true;
  } else {
    dta = dta / 60;

    if (Math.floor(dta) <= 12) {
      // return Math.floor(dta); //horas
      return true;
    } else {
      // dta = dta / 24;
      // return Math.floor(dta);//dias
      return false;
    }
  }
}

export function DateTranslate(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month}/${year} ${hours}:${minutes}`;
  if(formatted == "31/12/0 22:53")
    return "0";
  return formatted;
}

export function CompleteDateToPast(dataStr) { 
  
  const now = new Date();
    const past = new Date(dataStr);
    const diffMs = now - past;
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
        return `${diffDays} dia(s) atrás`;
    } else {
        return `${diffHours} hora(s) atrás`;
    }
}


export function OnlyDateTranslate(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month}/${year}`;
  return formatted;
}

export function OnlyDateInputTranslate(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${year}-${month}-${day}`;
  return formatted;
}

export function DateTranslateSimple(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month} ${hours}:${minutes}`;
  return formatted;
}

export function DateTranslateToInput(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${year}-${month}-${day}T12:00`;
  return formatted;
}

export function DateTranslateToTimestamp(dte) {
  let s = dte;
  let [dia, mes, ano, hora, minuto] = s.split(/[\/: ]/).map((v) => parseInt(v));

  let data = new Date(ano, mes - 1, dia, hora, minuto);
  return DateTranslateToInput(data);
}

export function GetTimestamp(dte) {
  let s = dte;
  let [dia, mes, ano, hora, minuto] = s.split(/[\/: ]/).map((v) => parseInt(v));

  let data = new Date(ano, mes - 1, dia, hora, minuto);
  return data.getTime();
}

export function DateTimeNow() {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const formatted = `${year}-${month}-${day}T${
    hours < 10 ? `0${hours}` : hours
  }:${minutes}`;
  return formatted;
}

export function ProcessDates(list) {
  for (let s in list) {
    list[s].createdDate = DateTranslate(list[s].createdDate);
    list[s].updatedDate = DateTranslate(list[s].updatedDate);
  }
  return list;
}

export const ArrayMes = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

function getMesExtenso(mes) {
  return ArrayMes[mes];
}

export function GetMes() {
  const dataAtual = new Date();
  const mes = getMesExtenso(dataAtual.getMonth());

  return mes;
}

export function GetDia() {
  const dataAtual = new Date();
  const dia = dataAtual.getDate();

  return dia;
}


export function GetLastDayOfMonth(year, month) {
  let days = 30;
  if (month === 1) {
    days = 28;
  } else if (
    month == 0 ||
    month == 2 ||
    month == 4 ||
    month == 6 ||
    month == 9 ||
    month == 11
  ) {
    days = 31;
  } else {
    days = 30
  }
  return new Date(year, month, days);
}


export function OnlyDateTimeNow() {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formatted = `${year}-${month}-${day}`;
  return formatted;
}

export function OnlyDateTranslateToInput(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formatted = `${year}-${month}-${day}`;
  return formatted;
}
