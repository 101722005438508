import HomeOpenCustomers from "@/app/core/layouts/main-components/financial/HomeOpenCustomers";
import HomeHistory from "@/app/core/layouts/main-components/history/HomeHistoryCard";
import FinancialReview from "@/app/modules/customerProfile/main/FinancialReview";
import React from "react";

export default function CollectorPanel() {
    return (
        <div>
            <HomeOpenCustomers />
            <FinancialReview />
            <HomeHistory />
        </div>
    )
}