import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export async function InsertExigency(values, response) {
  await BaseService(
    {
      method: "POST",
      url: "exigency/insert",
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.info(HandleErrors(err));
    },
  );
}

export function ExigencyUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: `exigency/update`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function ExigencyCustomerResponse(data, response) {
  await BaseService(
    {
      method: "POST",
      url: `exigency/response`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function ExigencyChangeOrgan(organId, response) {
  await BaseService(
    {
      method: "POST",
      url: `exigency/change/organ/${organId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ExigencyList(processId, response) {
  BaseService(
    {
      method: "GET",
      url: `exigency/process/${processId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.error("Consulta de exigencias", err);
      toast.error(HandleErrors(err));
    },
  );
}

export function ExigencyDelete(exigencyId, response) {
  BaseService(
    {
      method: "DELETE",
      url: `exigency/delete/${exigencyId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function ExigenciesList(processId, response) {
  await BaseService(
    {
      method: "GET",
      url: `exigencies-list?processId=${processId}&`,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function ExigencyResponse(id, returnMessage, response) {
  await BaseService(
    {
      method: "GET",
      url: `exigency-confirm?exigencyId=${id}&response=${returnMessage}&`,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function ExigencyDeliveryList(response) {
  await BaseService(
    {
      method: "GET",
      url: `exigency/devolution-exigencies`,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function ExigencyShowAll(processId, response) {
  await BaseService(
    {
      method: "POST",
      url: `exigency/show-all/${processId}`,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function ExigencyHideAll(processId, response) {
  await BaseService(
    {
      method: "POST",
      url: `exigency/hide-all/${processId}`,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}
