import React, { useState, useEffect } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Toggler from "@/app/core/util/Toggler";
import SideOverlay from "@/app/core/layouts/main/components/SideOverlay";
import ProcessCard from "@/app/core/layouts/main/elements/ProcessCard";
import Storage from "@/app/core/util/Storage";
import TemplateCard from "./Templates";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TimelineComponent from "@/app/core/layouts/main-components/TimelineComponent";
import ExigenciesComponent from "@/app/core/layouts/main-components/ExigenciesComponent";
import ProcessNotesComponent from "@/app/core/layouts/main-components/ProcessNotes";
import DocsComponent from "@/app/core/layouts/main-components/DocsComponent";
import ProtocolComponent from "@/app/core/layouts/main-components/ProtocolComponent";
import ShortcutsComponent from "@/app/core/layouts/main-components/ShortcutsComponent";
import FileViewer from "@/app/core/layouts/main-components/FileViewer";
import NotificationComponent from "@/app/core/layouts/main-components/notifications/NotificationComponent";
import EndPreparationModal from "@/app/core/layouts/main-components/EndPreparationModal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import {
  HasMoreSteps,
  SelectedServiceAnalysisStatus,
} from "@/app/core/util/helpers/SelectedServiceHelper";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import ConfirmReview from "@/app/core/layouts/main-components/organs/ConfirmReview";
import { ExigencyStatus } from "@/app/core/util/constants/ExigenciesConstants";
import StatusTab from "@/app/core/layouts/main-components/process/StatusTab";
import JobsController from "@/app/core/base/controllers/JobsController";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { PreparationHistory } from "@/app/core/base/controllers/services/HistoricService";
import ProcessHelper from "@/app/core/util/helpers/ProcessHelpers";

export default function PreparationDashboard(props) {
  const [loading] = useState(false);
  let screenHeight = window.innerHeight / 1.209;
  const [url, setUrl] = useState(null);
  const [data, setData] = useState({});
  const [organList, setOrganList] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [config, setConfig] = useState({
    endButton: "Encerrar/Enviar ao órgão",
  });
  const organCtrl = OrganListController();
  const userData = Storage.getUserData();
  const jobsDone = JobsController();
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    loadProcess();
  }, []);

  async function loadProcess() {
    ProcessController().TheProcess(props.match.params.key, null, (res) => {
      setData(res);

      for (let s in res.selectedServices) {
        const service = res.selectedServices[s];

        const organ = service.organList.filter(
          (x) => x.id == parseInt(props.match.params.key),
        );
        if (organ.length > 0) {
          setSelectedService(service[s]);
          setOrganList(organ[0]);
          processOrgan(organ[0], service);
        }
      }

      if (url == null || url == "") {
        let master = res.docs.filter((x) => x.status === 1);
        let complement = res.docs.filter((x) => x.status === 2);
        let show = false;
        for (let s in master) {
          if (!show) {
            showDoc(master[s].url);
          }
          show = true;
        }

        if (master.length === 0 && complement.length > 0 && show == false) {
          showDoc(complement[0].url);
        }
      }
    });
  }

  async function processOrgan(selected, service) {
    if (
      selected.stage === 3 &&
      selected.statusPreparation === SelectedServiceAnalysisStatus.NotStarted
    ) {
      setDisable(false);
    } else {
      if (selected.stage !== 3) {
        setDisable(true);
      }
    }

    setConfig({ ...config, endButton: HasMoreSteps(3, service.steps) });
  }

  function showDoc(url) {
    setUrl(url);
  }

  async function updateStatus(){
    let selected = organList;
    if (userData.role === 0 || userData.id === selected.preparerId) {
      selected.statusPreparation = SelectedServiceAnalysisStatus.Ongoing;

      await organCtrl.Update(selected, async (res) => {
        PreparationHistory(
          selected.processId,
          parseInt(props.match.params.key),
        );
        await NotifyUpdate(
          selected.processId,
          `Em Preparação`,
          false,
          TimelineChannelSistema,
          selected.id,
        );
      });
    }
  }

  function endPreparation() {
    if (
      organList.consulting === true ||
      organList.internalFeedback === true ||
      organList.customerReturn === true
    ) {
      alert(
        "Confirme se checou o status de consultando, feedback interno ou retorno do cliente antes de seguir em frente",
      );
      return;
    }
    // ExigencyList(data.id, async (res) => {
    let res = [];
    if (data.exigencies) {
      res = data.exigencies;
    }
    const results = res.filter(
      (x) =>
        x.status === ExigencyStatus.waitingConfirmation &&
        x.organListId == props.match.params.key,
    );
    if (results.length > 0) {
      toast.error(
        "Confirme as exigências aguardando confirmação do operador para continuar",
      );
    } else {
      if (
        window.confirm("Você tem certeza de que deseja encerrar a preparação?")
      ) {
        if (
          organList.selectedService &&
          organList.selectedService.steps.length > 0
        ) {
          const base = organList.selectedService.steps.filter(
            (x) => x.department > 3,
          );
          if (base.length == 0) {
            organCtrl.SendDevolution(
              {
                ...organList,
                deliveryType: 5,
              },
              async (res) => {
                await jobsDone.Insert(data.id, organList.id, (res) => {
                  closure();
                });
              },
            );
          } else {
            setShowModal(true);
          }
        } else {
          setShowModal(true);
        }
      }
    }
    // });
  }

  async function closure() {
    toast.success(Message.Saved);
    setTimeout(() => {
      closePreparation();
    }, 900);
  }

  function closePreparation() {
    window.location = "#/app";
  }

  async function statusWaitingExigencies(sts) {
    await organCtrl.Update({ ...organList, statusPreparation: sts }, (res) => {
      loadProcess();
      if (sts === SelectedServiceAnalysisStatus.Ongoing) {
        toast.info("Salvo com sucesso");
      }
    });
  }

  function openLightBox(item) {
    setImagesUrl([item]);
    setIsOpen(true);
  }

  return (
    <div
      id="page-container"
      className={`enable-page-overlay side-scroll main-content-narrow sidebar-dark page-header-${Storage.getTheme()}`}
    >
      {isOpen && (
        <Lightbox
          reactModalStyle={{ zIndex: 999999999999999999999 }}
          enableZoom={true}
          mainSrc={imagesUrl[photoIndex]}
          nextSrc={imagesUrl[(photoIndex + 1) % imagesUrl.length]}
          prevSrc={
            imagesUrl[(photoIndex + imagesUrl.length - 1) % imagesUrl.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesUrl.length - 1) % imagesUrl.length,
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesUrl.length)
          }
        >
          <button className="btn btn-square">has</button>
        </Lightbox>
      )}
      <Loading loading={loading} />
      <header id="page-header">
        <div class="content-header">
          <div>
            <button
              type="button"
              className="btn btn-sm btn-success mr-1"
              onClick={() => {
                endPreparation();
              }}
            >
              <i className="fa fa-fw fa-save"></i> {config && config.endButton}
            </button>
            <button
              type="button"
              className="btn btn-dual mr-1"
              data-toggle="layout"
              onClick={() => {
                closePreparation();
              }}
            >
              <i className="fa fa-fw fa-close text-danger"></i>
            </button>
            {(organList &&
              organList.statusPreparation ===
                SelectedServiceAnalysisStatus.WaitingOperatorConfirmation) ||
            (organList &&
              organList.statusPreparation ===
                SelectedServiceAnalysisStatus.ReturnToCustomer) ? (
              <button
                type="button"
                className="btn btn-sm btn-outline-info mr-1"
                onClick={() =>
                  statusWaitingExigencies(SelectedServiceAnalysisStatus.Ongoing)
                }
              >
                Retornado ao cliente
              </button>
            ) : (
              ""
            )}
            Nome do Processo : {data && data.processName}
          </div>
          {organList && organList.id !== undefined && (
            <ConfirmReview organList={organList} reload={() => loadProcess()} />
          )}
          <div>
            <div className="row">
              <div className="col-md-6">
                <NotificationComponent
                  processId={data && data.id}
                  orientation={"left"}
                />
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn btn-dual mr-1"
                  data-toggle="layout"
                  onClick={() => Toggler.toggleSideOverlay()}
                >
                  <i className="si si-settings"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="page-header-loader" class="overlay-header bg-primary-darker">
          <div class="content-header">
            <div class="w-100 text-center">
              <i class="fa fa-fw fa-2x fa-sun fa-spin text-white"></i>
            </div>
          </div>
        </div>
      </header>
      <div className="ml-1 mr-1 mb-1 mt-1">
        <div className="row" height="100%">
          <div className="col-md-7" style={{ height: screenHeight }}>
            <FileViewer
              file={url}
              height={screenHeight}
              openImage={(item) => openLightBox(item)}
            />
          </div>
          <div className="col-md-5">
            <div
              className="block block-rounded"
              style={{
                height: "100%",
                overflow: "auto",
                maxHeight: screenHeight,
                width: "107%",
                marginLeft: -30,
              }}
            >
              <Tabs className="tablist">
                <div className="block-header">
                  <div className="block-title">
                    <Tab className="nav-item">
                      <button className="btn btn-sm btn-dual mr-1 border-right">
                        <i className="si si-diamond"></i> Processo
                      </button>
                    </Tab>
                    <Tab className="nav-item">
                      <button className="btn btn-sm btn-dual mr-1 border-right">
                        <i className="far fa-fw fa-clock"></i> Status
                        {data && ProcessHelper().StatusTicker(data)}
                      </button>
                    </Tab>
                    <Tab className="nav-item">
                      <button className="btn btn-sm btn-dual mr-1 border-right">
                        <i className="far fa-file-pdf"></i> Documentos
                      </button>
                    </Tab>
                    <Tab className="nav-item">
                      <button className="btn btn-sm btn-dual mr-1 border-right">
                        <i className="far fa-fw fa-file-archive"></i> Timeline
                      </button>
                    </Tab>
                    <Tab className="nav-item">
                      <button className="btn btn-sm btn-dual mr-1 border-right">
                        <i className="fa fa-fw fa-tasks"></i> Exigências
                      </button>
                    </Tab>
                    <Tab className="nav-item">
                      <button className="btn btn-sm btn-dual mr-1 border-right">
                        <i className="far fa-fw fa-sticky-note"></i> Observações
                        {data &&
                        data.customerNote &&
                        data.customerNote.length > 0 ? (
                          <i className="fa fa-exclamation-circle text-danger ml-2" />
                        ) : (
                          ""
                        )}
                      </button>
                    </Tab>
                    <Tab className="nav-item">
                      <button className="btn btn-sm btn-dual mr-1 border-right">
                        <i className="far fa-fw fa-bookmark"></i> Consultas
                      </button>
                    </Tab>
                  </div>
                </div>
                <div className="ml-3 mr-3">
                  <Panel>
                    <ProcessCard
                      data={data}
                      processId={data && data.id}
                      organListId={
                        props.match.params.key &&
                        parseInt(props.match.params.key)
                      }
                      disabled={disable}
                      refresh={() => loadProcess()}
                      preLoad={data}
                    />
                  </Panel>
                  <Panel>
                    <StatusTab
                      processId={data && data.id}
                      view={(data) => showDoc(data)}
                      organListId={parseInt(props.match.params.key)}
                      refresh={() => loadProcess()}
                    />
                  </Panel>
                  <Panel>
                    <div className="">
                      <DocsComponent
                        processId={data && data.id}
                        organListId={
                          props.match.params.key &&
                          parseInt(props.match.params.key)
                        }
                        disabled={disable}
                        notifyStart={() => updateStatus()}
                        setView={(data) => showDoc(data)}
                        preLoad={data}
                        refresh={() => loadProcess()}
                      />
                    </div>
                  </Panel>
                  <Panel>
                    <div
                      className=""
                      style={{ overflowY: "scroll", height: screenHeight }}
                    >
                      <TimelineComponent
                        budgetId={0}
                        preLoad={data}
                        processId={data && data.id}
                        view={(data) => showDoc(data)}
                        notifyStart={() => updateStatus()}
                        refresh={() => loadProcess()}
                        organListId={parseInt(props.match.params.key)}
                      />
                    </div>
                  </Panel>
                  <Panel>
                    <div className="">
                      <ExigenciesComponent
                        processId={data && data.id}
                        disabled={disable}
                        notifyStart={() => updateStatus()}
                        organListId={parseInt(props.match.params.key)}
                        report={(status) => statusWaitingExigencies(status)}
                        preLoad={data}
                        refresh={() => loadProcess()}
                      />
                    </div>
                  </Panel>
                  <Panel>
                    <div className="">
                      <ProcessNotesComponent
                        disabled={disable}
                        open={(data) => showDoc(data)}
                notifyStart={() => updateStatus()}
                        processId={data && data.id}
                        organListId={parseInt(props.match.params.key)}
                      />
                    </div>
                  </Panel>
                  <Panel>
                    <div className="">
                      <ProtocolComponent
                        processId={data && data.id}
                        reload={() => loadProcess()}
                        organListId={parseInt(props.match.params.key)}
                      />
                    </div>
                  </Panel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <SideOverlay style={{ width: "535px" }} title={`Processo #`}>
        <Tabs>
          <div
            className="nav nav-tabs nav-tabs-block nav-justified js-tabs-enabled"
            style={{ padding: 7 }}
          >
            <Tab className="nav-item">
              <button
                type="button"
                className="btn btn-dual mr-1"
                data-toggle="layout"
                alt="Documentos"
              >
                <i className="far fa-fw fa-clipboard"></i> Templates
              </button>
            </Tab>
            <Tab className="nav-item">
              <button
                type="button"
                className="btn btn-dual mr-1"
                data-toggle="layout"
                alt="Exigências"
              >
                <i className="fa fa-link"></i> Links úteis
              </button>
            </Tab>
          </div>
          <Panel>
            <TemplateCard setView={(data) => showDoc(data)} />
          </Panel>
          <Panel>
            <ShortcutsComponent />
          </Panel>
        </Tabs>
      </SideOverlay>
      <ToastContainer className="toastr" />
      <EndPreparationModal
        data={organList}
        preLoad={data}
        organListId={props.match.params.key}
        show={showModal}
        onHide={() => setShowModal(false)}
        submit={() => closure()}
      />
    </div>
  );
}
