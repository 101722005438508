import React from "react";
import Storage from "@/app/core/util/Storage";
import "../assets/footer.css";

export default function Footer() {
  const userData = Storage.getUserData();
  return (
    <footer
      id="page-footer"
      className={`${Storage.getTheme()} text-body-${Storage.getTheme()}`}>
      <div className="content py-0">
        <div className="row font-size-sm">
          <div className="col-sm-4 order-sm-1 text-center text-sm-left">
            &copy; Hasa{" "}
            <span data-toggle="year-copy">{new Date().getFullYear()}</span>
          </div>
          <div className="col-sm-4 order-sm-1 text-center">
          </div>
          <div className="col-sm-4 order-sm-1 text-center text-sm-right">
            versão 1.3.4.2
          </div>
        </div>
      </div>
    </footer>
  );
}