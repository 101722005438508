import { OnlyDateTranslate } from "@/app/core/util/DateTranslater";
import { CepMask, CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { ElaboratorHelper } from "@/app/core/util/helpers/ElaboratorHelper";
import { MoneyMask, NumberMask } from "@/app/core/util/helpers/MoneyHelper";
import ReportHelper from "@/app/core/util/helpers/ReportHelper";
import { CivilStateTranslate, CommunionTranslate, RGMask } from "@/app/core/util/helpers/UserHelper";
import React from "react";

export default function ModeloDistrato({ data, partners }) {
    const date = new Date();
    const baseFont = {fontFamily: "Times New Roman"};

    return (
        <div style={baseFont}>


            <h4 className="text-center" style={baseFont}>DISTRATO SOCIAL DE SOCIEDADE {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? "LIMITADA UNIPESSOAL" : "EMPRESÁRIA LIMITADA"} </h4>
            <h4 className="text-center underline" style={baseFont}>{data && data.name} LTDA</h4>
            
            {partners && partners.map(item => (
                <p className="text-justify"><span class="bold underline font-w700">{item.name}</span>, {item.nationality}, <span>{CivilStateTranslate(item.civilState)}</span> {item.communionPartner != undefined ? `sob o regime de ${CommunionTranslate(item.communionPartner)}` : ""}, {item.jobTitle}, nascido em {OnlyDateTranslate(item.birthDate)}, RG nº. {RGMask(item.doc)} SSP/SP e CPF (MF) nº {CpfMask(item.stateId)}, residente e domiciliado à {item.street}, nº. {item.number === ""? "S/N" : item.number}, Bairro {item.neighborhood}, {item.city} – {item.state}, CEP: {CepMask(item.postalCode)}.</p>
            ))}

            {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? 
                <p className="text-justify">Único sócio componente da sociedade limitada unipessoal com a denominação social de  <span class="bold underline font-w700">{data && data.name} LTDA</span>, estabelecida à {data && data.street}, nº {data && data.number === "" ? "S/N" : data.number}, Bairro {data && data.neighborhood}, {data && data.city} – {data && data.state} - CEP {data && CepMask(data.postalCode)}, que se acha registrada na {data && ElaboratorHelper.GetOrgan(data.state)} sob NIRE nº {data && data.nire} em sessão de {data && OnlyDateTranslate(data.openDate)} e CNPJ sob nº {data && CnpjMask(data.taxId)}, resolve <span class="font-w700">DISSOLVER</span> a sociedade nas seguintes condições e conforme cláusulas deste <span class="font-w700">DISTRATO SOCIAL</span>:</p>
            : 
                <p className="text-justify">Únicos sócios componentes da Sociedade Empresária de Tipo Limitada denominada <span class="bold underline font-w700">{data && data.name} LTDA</span>, com sede social à {data && data.street}, nº {data && data.number === "" ? "S/N" : data.number}, Bairro {data && data.neighborhood}, {data && data.city} – {data && data.state} - CEP {data && CepMask(data.postalCode)}, que se acha registrada na {data && ElaboratorHelper.GetOrgan(data.state)} sob NIRE nº {data && data.nire} em sessão de {data && OnlyDateTranslate(data.openDate)} e CNPJ sob nº {data && CnpjMask(data.taxId)}, resolvem de comum acordo <span class="font-w700">DISSOLVER</span> a sociedade nas seguintes condições e conforme cláusulas deste <span class="font-w700">DISTRATO SOCIAL</span>:</p>
            }

            <h6 className="text-center" style={baseFont}>CLÁUSULA PRIMEIRA</h6>
            {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? 
            <p className="text-justify">Fica dissolvida a sociedade empresária limitada unipessoal que gira sob a denominação social de <span class="bold underline font-w700">{data && data.name} LTDA</span>.</p>
            :
            <p className="text-justify">Fica dissolvida a sociedade empresária limitada que gira sob a denominação social de <span class="bold underline font-w700">{data && data.name} LTDA</span>.</p>
            }
            {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? 
            <p className="text-justify"><span class="bold">Parágrafo Único</span> – O sócio resolve neste ato dissolver a sociedade por não ter mais interesse em continuar executando as atividades.</p>
            : 
            <p className="text-justify"><span class="bold">Parágrafo Único</span> – Os sócios resolvem neste ato dissolver a sociedade por não ter mais interesse em continuar executando as atividades.</p>
            }


            <h6 className="text-center" style={baseFont}>CLÁUSULA SEGUNDA</h6>
            <p className="text-justify">O capital social da sociedade é de {data && MoneyMask(data.capital, 2)} ({data && data.capitalExtended}), dividido em {data && NumberMask(data.capital)} ({data.capitalExtended}) quotas, no valor nominal de R$ 1,00 (um real) 
                cada uma, totalmente subscrito e integralizado anteriormente em moeda corrente do país, e retorna {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? <span className="font-w700">ao sócio {partners && partners[0] && partners[0].name}.</span> : "aos sócios na proporção de suas participações"}</p>
            {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? 
            "":
            <table className="table table-bordered">
                {partners && partners.map(item => (
                    <tr>
                        <td>{item.name}</td>
                        <td>{NumberMask(item.value)} Quotas</td>
                        <td> {MoneyMask(item.value, 2)}</td>
                    </tr>
                ))}

                <tr>
                    <td colspan="1"><span class="font-w700">TOTAL</span></td>
                    <td>{data && NumberMask(data.capital)} Quotas</td>
                    <td> {data && MoneyMask(data.capital, 2)}</td>
                </tr>
            </table>
            }
            {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? 
            <p className="text-justify"><span class="font-w700">Parágrafo Único</span> – A responsabilidade do sócio é restrita ao valor de suas quotas, respondendo solidariamente pela integralização do capital social, nos termos do artigo 1.052 da Lei nº 10.406, de 10 de janeiro de 2002.</p>
            :
            <p className="text-justify"><span class="font-w700">Parágrafo Único</span> – A responsabilidade de cada sócio é restrita ao valor de suas quotas, mas todos respondem solidariamente pela integralização do capital social, nos termos do artigo 1.052 da Lei nº 10.406, de 10 de janeiro de 2002.</p>
            }


            <h6 className="text-center" style={baseFont}>CLÁUSULA TERCEIRA</h6>
            {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? 
            <p className="text-justify">O sócio neste ato dá plena, rasa e irrevogável quitação em relação à sociedade ora extinta, nada mais tendo a reclamar a qualquer título.</p>
            :
            <p className="text-justify">Os sócios neste ato dão-se <span class="underline font-w700">reciprocamente</span> plena, rasa e irrevogável quitação em relação à sociedade ora extinta, nada mais tendo a reclamar a qualquer título.</p>
            }



            <h6 className="text-center" style={baseFont}>CLÁUSULA QUARTA</h6>
            <p className="text-justify">A sociedade ora dissolvida não deixa nem <span class="bold underline font-w700">ATIVO</span> e nem <span class="bold underline font-w700">PASSIVO</span>.</p>

            <h6 className="text-center" style={baseFont}>CLÁUSULA QUINTA</h6>
            <p className="text-justify">O sócio <span class="bold underline font-w700">{partners && partners.map(item => {
                if(item.responsible){
                    return item.name;
                }
            })}</span> fica responsável pelo ativo e passivo porventura supervenientes, e incumbido da regularização dos presentes atos junto às repartições públicas competentes, bem como, manter sob a sua guarda os documentos, livros fiscais da sociedade extinta, conservando-os na forma e no prazo da Lei.</p>

            <p className="text-justify">E assim por estarem justos e contratados, assinam o presente em 3 (três) vias de igual teor e para validade será registrado e arquivado na {data && ElaboratorHelper.GetOrgan(data.state)}.</p>

            <p class="text-right font-w700">{data && data.city}, {date.getDate()} de {ReportHelper().translateMonth(date.getMonth())} de {date.getFullYear()}.</p>

            {partners && partners.map(item => (
                <div class="signatures">
                    <br/>
                    <br/>
                    <hr/>
                    <p className="text-center font-w700">{item.name}</p>
                </div>
            ))}


        </div>
    )
}