import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function WhatsAppController() {
    async function SendCharge(chargeId, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `comunication/financial-whatsapp`,
                data: chargeId,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function SendEmailCharge(chargeId, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `comunication/financial-email?chargeId=${chargeId}`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function SendCustomEmailCharge(chargeId, templateId, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `comunication/send-template-financial-email?chargeId=${chargeId}&templateId=${templateId}`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function ScheduleSend(listIds, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `comunication/schedule-send`,
                data: listIds
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }


    return {
        SendCharge,
        SendEmailCharge,
        SendCustomEmailCharge,
        ScheduleSend,
    };
}
