import React, { useEffect, useState, useRef } from "react";
import { DateTranslate, GetLastDayOfMonth } from "@/app/core/util/DateTranslater";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { CustomerController, CustomerList } from "@/app/core/base/controllers/CustomerController";
import ReportController from "@/app/core/base/controllers/ReportController";
import CustomerListCard from "../../oportunities/main/CustomerListCard";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function RecurrentCustomers() {
    
      const date = new Date();
        const [year, setYear] = useState(date.getFullYear()-1);
        const [month, setMonth] = useState(date.getMonth()-1);
    const [filter, setFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);

    const [startDate, setStartDate] = useState(
        getFirstDayOfMonth(year, month)
          .toISOString()
          .substring(0, 10)
      );
      const [endDate, setEndDate] = useState(
        GetLastDayOfMonth(year, month)
          .toISOString()
          .substring(0, 10));
    
          const [startDateEnd, setStartDateEnd] = useState(
        getFirstDayOfMonth(year, month)
          .toISOString()
          .substring(0, 10)
      );
      const [endDateEnd, setEndDateEnd] = useState(
        GetLastDayOfMonth(year, month)
          .toISOString()
          .substring(0, 10));
    
      function getLastDayOfMonth(year, month) {
          // Definindo o primeiro dia do mês seguinte
        let nextMonth = new Date(year, month + 1, 1);
        
        // Subtraindo um dia para obter o último dia do mês atual
        nextMonth.setDate(nextMonth.getDate() - 1);
        
        return nextMonth;
      }
    
      function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
      }

    const colsDiscount = [
        { header: "Cód.", isSortable: true, name: "customerCode" },
        {
            header: "Nome",
            isSortable: true,
            name: "name",
            style: {
                maxWidth: "220px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
            },
        },
        { header: "Email", isSortable: true, name: "email" },
        { header: "Telefone", isSortable: true, name: "phone" },
        { header: "Data de pagamento", isSortable: true, name: "updatedDate" },
    ];

    useEffect(() => {
        reloadDiscount()
    }, [])

    async function reloadDiscount() {
        setLoading(true);
        await ReportController().Recorrents(startDate, endDate, startDateEnd, endDateEnd, res => {
            
            setFilter(res)
        });
        setLoading(false);
    }

    return (
        <div>
            <div className="block-header mt-5">
                <div className="block-title">{filter && filter.length} Clientes recorrentes entre períodos</div>
                <div className="block-tools d-flex">
                    <div className="form-group">
                        <span>Período A</span>
                        <div className="form-group">
                            <span>Data de início</span>
                            <input
                                type={"date"}
                                className="form-control"
                                value={startDate}
                                onChange={(evt) => setStartDate(evt.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <span>Data fim</span>
                            <input
                                type={"date"}
                                className="form-control"
                                value={endDate}
                                onChange={(evt) => setEndDate(evt.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <span>Período B</span>
                        <div className="form-group">
                            <span>Data de início</span>
                            <input
                                type={"date"}
                                className="form-control"
                                value={startDateEnd}
                                onChange={(evt) => setStartDateEnd(evt.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <span>Data fim</span>
                            <input
                                type={"date"}
                                className="form-control"
                                value={endDateEnd}
                                onChange={(evt) => setEndDateEnd(evt.target.value)}
                            />
                        </div>
                    </div>

                    {/* <DownloadTableExcel
                        filename="users table"
                        sheet={startDate && `Bloqueados - ${DateTranslate(startDate)} até ${DateTranslate(endDate)}`}
                        currentTableRef={tableRef.current}
                    >
                        <button className="btn btn-info"><i className="fa fa-download" /> Exportar Excel </button>
                        </DownloadTableExcel> */}
                        <span>
                            <button className="btn btn-info mt-6" onClick={()=>reloadDiscount()}><i className="fa fa-search" /> Pesquisar</button>
                        </span>
                </div>
            </div>
            {loading === true ? (
                <div className="block-content text-center">
                    <i className={"fa fa-circle-notch text-info fa-2x fa-spin"}></i>
                </div>
            ) : ""
            }
            <CustomerListCard
                title={"Clientes que não pagaram no mês anterior"}
                infos={filter && filter}
                cols={colsDiscount}
                isOpen={true}
                reload={() => reloadDiscount()}
            />
        </div>
    )
}